import React, { PureComponent } from "react";
import CompleteOrderDetails from "./partials/completed-order";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import NoRecords from "../NoRecords/component";
import ReactModal from "react-modal";
import { CloseIcon, DeleteIcon } from "../../components/icons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { message } from "antd";
import _, {capitalize}from "lodash";
import SustainabilityInformationComponent from "../ChargeByYard/partials/SustainabilityInformation";
import DeleteOrderModal from "../PendingOrderDetails/partials/delete-order";
import { PrintPerforatedInvoice } from "../../utils/printInvoiceReceipt";
import Logo from '../../images/logo.png'
import PlacesAutocomplete from "../../components/forms/PlaceSearchBox";
import Edit from "../../images/edit.svg";
import { format_address, sort_by_descending, customStartCase, numberFormat} from "../../constants/common";
import EmailAlert from "../../utils/email_warning_popup";
import { PrintSisterCompanyOrderReceipt } from "../../utils/printInvoiceReceipt"
import CityOfOrigin from "../BusinessAdmin/partials/CityOfOriginModal";
// import SignPadContainer from "../SignaturePad/container";

const timezone = moment().format("Z");
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default class CompletedOrderDetailsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);

    this.state = {
      order: {},
      user: null,
      ismodelopen: true,
      spinning: false,
      notes: "",
      isServiceLocationEditing: false,
      isCityOfOriginEditing : false,
      isCityOfOriginModalOpen : false,
      isCustomerRefrenceEditing: false,
      showEditor: false,
      saveNotes: "",
      isSustainabilityInformationModelOpen: false,
      sustainabilityInfo: {},
      orderLogs: [],
      isDeleteModal: false,
      progressBar: false,
      emailBtnTxt: "Email Receipt",
      emailProgress: false,
      isTicketInvoiceLoader: false,
      isTicketInvoiceLoaderPrint: false,
      orderCreationType: "",
      visible: false,
      loaderPerforated: false,
      is_previous_page_scale: false,
      tz,
      softDelete: false,
      hasPaymentReceipt: true,
      sisterCompanyData: {},
      isSisterCompany: false,
      showModal: false,
      orderDetailsLoader: false,
      signatureUrl: ''
    };
  }
  closeModalAlert = () => {
    this.setState({ showModal: false });
  };


  componentDidMount = async () => {
    window.scrollTo(0, 0)
    this.fetchOrderDetail()
    const { state } = this.props.location;
    this.setState({ is_previous_page_scale: state?.ScalePage })
    // this.setState({ismodelopen:true})
  };

  handleServiceLocation = async () => {
    const { serviceLocation,order , cityOfOrigin , isCityOfOriginEditing} = this.state;
    const { updateOrder } = this.props;
    let payload;
    if(isCityOfOriginEditing){
      payload = { id: order._id, cityOfOrigin };
    }
    else{
    payload = { id: order._id, serviceLocation };
    }
    try {
        const { value: { status } } = await updateOrder(payload);
        if (status) {
            this.setState({ isServiceLocationEditing: false , isCityOfOriginEditing : false});
          }
        } catch (error) {
            console.error('Error updating service location:', error);
        }
    };
handleCustomerRefrence = async () => {
  const { customerRefrence, order } = this.state;
  const { updateOrder } = this.props;
  const payload = { id: order._id, customerRefrence };
  try {
      const { value: { status } } = await updateOrder(payload);
      if (status) {
          this.setState({ isCustomerRefrenceEditing: false });
      }
  } catch (error) {
      console.error('Error updating customer reference:', error);
  }
};

  fetchOrderDetail = async () => {
    this.setState({ spinning: true })
    const {
      match: {
        params: { id }
      },
      fetchOrder,
      fetchMe
    } = this.props;
    const [{ value: { data, orderLogs } }, { value: { user } }] = await Promise.all([fetchOrder(id), fetchMe()])
    let hasPaymentReceipt = true;
    if (!(data.invoiceDetails?.amountPaid > 0)) {
      hasPaymentReceipt = false;
    }
    if (user.company.sisterCompany) {
      const {
        value: { data },
      } = await this.props.fetchSetting(user.company.sisterCompany);
      if (data.status) {
        this.setState({ sisterCompanyData: data, isSisterCompany: true })
      }
    }
   
    this.setState({ order: data, saveNotes: data.notes, notes: data.notes, sustainabilityInfo: data.sustainabilityInfo?.length > 0 ? data.sustainabilityInfo[0] : {}, user, orderLogs, orderCreationType: data.orderCreationType, hasPaymentReceipt });
    this.setState({ spinning: false })
  }
  
  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  }

  onCloseClick = () => {
    this.setState({ isSustainabilityInformationModelOpen: false, ismodelopen: true })
  }

  assignSustainabilityInfo = async (data) => {
    const sustainabilityInfo = data
    const payload = { id: this.state.order._id, sustainabilityInfo: [sustainabilityInfo] };
    const { updateOrder } = this.props;
    const {
      value: { status }
    } = await updateOrder(payload);
    if (status) {
      this.setState({ showEditor: false });
      this.setState({ sustainabilityInfo: data });
    }
  }

  deleteOrder = async (reason) => {
    const {
      order: { _id }
    } = this.state;
    this.setState({ progressBar: true });
    const {
      value: { status }
    } = await this.props.deleteOrder(_id, reason);
    if (status) {
      this.props.history.push("/completed-orders");
    }
  };
  reArrangOrdersData = (orderData) => {
    let order = orderData;
    let reArrangOrders = [];
    if (order.length > 0) {
      order.map((item) => {
        if (_.get(item, "orderCreationType", "") === "createCharge") {
          if (_.get(item, "orderBreakDownItems.isminimumPrice", false)) {
            let obj = {
              fixedItems: [
                {
                  fixedItem: "Dumping services",
                  unitPrice: item.orderBreakDownItems.totalAmountDue,
                  units: 1,
                  totalamout: item.orderBreakDownItems.totalAmountDue,
                },
              ],
              ticketNumber: item.ticketNumber,
              orderCreationType: item.orderCreationType,
              isCapped: item?.orderBreakDownItems?.isCapOutPrice,
              isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
            };
            reArrangOrders.push(obj);
            return null;
          } else {
            if (item.orderBreakDownItems.isCapOutPrice) {
              let obj = {
                fixedItems: [],
                ticketNumber: item.ticketNumber,
                orderCreationType: item.orderCreationType,
                stripeCardFees: item.stripeCardFees,
                paymentType: item.paymentType,
                isCapped: item?.orderBreakDownItems?.isCapOutPrice,
                isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
              };
              if (item.orderBreakDownItems.fixedItem.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = customStartCase(fixedItems.fixedItem);
                  if (fixedItemName.includes("Yard")) {
                    if (
                      Number(fixedItems.unitPrice) > Number(item.orderBreakDownItems.capoutPrice)
                    ) {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: item.orderBreakDownItems.capoutPrice,
                        units: fixedItems.units,
                        totalamout:
                          item.orderBreakDownItems.capoutPrice * fixedItems.units,
                      };
                      obj.fixedItems.push(fixedItemObject);
                    }
                    else {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: fixedItems.unitPrice,
                        units: fixedItems.units,
                        totalamout:
                          fixedItems.unitPrice *
                          fixedItems.units,
                      };
                      obj.fixedItems.push(fixedItemObject);
                    }
                  } else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems.unitPrice,
                      units: fixedItems.units,
                      totalamout: fixedItems.unitPrice * fixedItems.units,
                    };
                    obj.fixedItems.push(fixedItemObject);
                  }
                  return null;
                });
              }
              if (item.orderBreakDownItems.chargeByWeight.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightFixedItems) => {
                    let chargeByWeightItemObject = {
                      fixedItem: chargeByWeightFixedItems.fixedItem,
                      unitPrice: chargeByWeightFixedItems.unitPrice,
                      units: chargeByWeightFixedItems.units,
                      totalamout:
                        chargeByWeightFixedItems.unitPrice *
                        chargeByWeightFixedItems.units,
                    };
  
                    obj.fixedItems.push(chargeByWeightItemObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            } else {
              let obj = {
                fixedItems: [],
                ticketNumber: item.ticketNumber,
                orderCreationType: item.orderCreationType,
                stripeCardFees: item.stripeCardFees,
                paymentType: item.paymentType,
                isCapped: item?.orderBreakDownItems?.isCapOutPrice,
                isMinimumPrice: item?.orderBreakDownItems?.isminimumPrice,
              };
              if (item.orderBreakDownItems.fixedItem.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems.fixedItem,
                    unitPrice: fixedItems.unitPrice,
                    units: fixedItems.units,
                    totalamout: fixedItems.unitPrice * fixedItems.units,
                  };
                  obj.fixedItems.push(fixedItemObject);
                  return null;
                });
              }
              if (item.orderBreakDownItems.chargeByWeight.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightItems) => {
                    let chargeByWeightItemsObject = {
                      fixedItem: chargeByWeightItems.fixedItem,
                      unitPrice: chargeByWeightItems.unitPrice,
                      units: chargeByWeightItems.units,
                      totalamout:
                        chargeByWeightItems.unitPrice * chargeByWeightItems.units,
                    };
                    obj.fixedItems.push(chargeByWeightItemsObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            }
          }
        } else {
          let total = 0
           // eslint-disable-next-line 
           let dumpRate = 0
           let isCapOutPrice = (item.pricingTierCapoutPrice > 0)
           let units = 0
           if (!item.isMinimumPrice) {
             if (item.dumpRateType.indexOf("Yard") !== -1) {
               units = Number(item.yardage)
               if (item.tonnageDifference < 0) {
                 if (isCapOutPrice) {
                   dumpRate = Number(item.pricingTierCapoutPrice)
                   total = Number(item.capoutPrice)
                 } else {
                   dumpRate = Number(item.dumpRate)
                   total = Number(item.yardage) * Number(item.dumpRate)
                 }
               } else {
                dumpRate = Number(item.dumpRate)
                total = Number(item.yardage) * Number(item.dumpRate)
              }
            } else {
              units = Number(item.tonnage)
              if (item.tonnageDifference < 0) {
                if (isCapOutPrice) {
                  dumpRate = Number(item.capoutPrice)
                  total = Number(item.capoutPrice)
                } else {
                  dumpRate = Number(item.dumpRate)
                  total = Number(item.tonnage) * Number(item.dumpRate)
                }
              } else {
                dumpRate = Number(item.dumpRate)
                total = Number(item.tonnage) * Number(item.dumpRate)
              }
              }
              let obj = {
                inboundWeight: item.inboundWeight,
                outboundWeight: item.outboundWeight,
                tonnageDifference: item.tonnageDifference,
                isCapped: item.isCapped,
                additionalItems: item.additionalItems,
                // dumpRateType: item.dumpRateType,
                dumpRate: Number(item.dumpRate),
                // ticketNumber: item.ticketNumber,
                netWeight: item.netWeight,
                // yardage: item.yardage,
                // tonnage: item.tonnage,
                // totalAmount: item.totalAmount,
                paymentType: item.paymentType,
                stripeCardFees: item.stripeCardFees,
                totalAmount: total,
                units: units,
                dumpRateType: item.dumpRateType,
                ticketNumber: item.ticketNumber,
                isEnvironmentalLoadFee: item.isEnvironmentalLoadFee,
                environmentalLoadFeeAmount: item.environmentalLoadFeeAmount,
                isFuelSurchardeFee: item.isFuelSurchardeFee,
                fuelSurchardeFeeAmount: item.fuelSurchardeFeeAmount,
                fuelSurchargeTotal: item.fuelSurchargeTotal,
                createdAt: item.createdAt,
              };
              reArrangOrders.push(obj);
            } else {
              let obj = {
                isMinimumPrice: item.isMinimumPrice,
                inboundWeight: item.inboundWeight,
                outboundWeight: item.outboundWeight,
                tonnageDifference: item.tonnageDifference,
                isCapped: item.isCapped,
                additionalItems: item.additionalItems,
                // dumpRateType: item.dumpRateType,
                dumpRate: Number(item.totalAmount),
                // ticketNumber: item.ticketNumber,
                netWeight: item.netWeight,
                // yardage: item.yardage,
                // tonnage: item.tonnage,
                // totalAmount: item.totalAmount,
                totalAmount: item.totalAmount,
                units: 1,
                dumpRateType: "Dumping services",
                ticketNumber: item.ticketNumber,
                isEnvironmentalLoadFee: item.isEnvironmentalLoadFee,
                environmentalLoadFeeAmount: item.environmentalLoadFeeAmount,
                isFuelSurchardeFee: item.isFuelSurchardeFee,
                fuelSurchardeFeeAmount: item.fuelSurchardeFeeAmount,
                fuelSurchargeTotal: item.fuelSurchargeTotal,
                createdAt: item.createdAt,
              };
              reArrangOrders.push(obj);
            }
        }
        return null;
      });
    }
    // this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
    return reArrangOrders;
  };

  round2decimal = (number) => {
    return Number(numberFormat(Math.round(parseFloat(number) * 1000) / 1000));
  };
  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  openPrintScreen = async () => {
    this.setState({ isTicketInvoiceLoaderPrint: true })
    const {
      match: {
        params: { id }
      },
      fetchOrder,
      fetchMe
    } = this.props;
    const [
      {
        value: { data }
      },
    ] = await Promise.all([fetchOrder(id), fetchMe()]);
    let printWindow = "";
    let printData = [data];
    const printPromises = printData.map((items, i) => {
      return new Promise(async (resolve, reject) => {
        let arrangeOrderData = sort_by_descending(this.reArrangOrdersData([data], "createdAt"))
        const sustainabilityInfos = data && data.sustainabilityInfo.length > 0 ? data.sustainabilityInfo : [];
        let html = ''
        if (this.state.isSisterCompany) {
          const last = i === printData.length - 1;
          let value = { data: data }
          let sustainabilityInfosTemplate = `
          ${items?.truck?.plateImageUrl || items?.loadImageUrl ?
              ` <table class="driver-signature-table" style="width:100%; max-width: 460px;">
               <tbody>
               
                 <tr>
                 ${items?.truck?.plateImageUrl ?
                  ` <th >
                     <h2 class="signature-title" style="margin-bottom: 5px;">License plate	</h2>
                     </th>`: ''
              }
                     ${items?.loadImageUrl ?
                      `<th >
                      <h2 class="signature-title" style="margin-bottom: 5px;"> ${items?.orderCreationType === "createCharge" ? "Picture Of Load" : "Inbound Load"}	</h2>
                   </th>`
                   : ''
                  }
                     
                 </tr>
               
                 <tr>
                 ${items?.truck?.plateImageUrl ?
                  `<td style="padding-right: 10px;">
                     <img style="height:122px;width:215px" src=${items?.truck?.plateImageUrl} alt=''/>
                   </td>`
                   : ''
                  }
                 ${items?.loadImageUrl ?
                  `  <td style="padding-right: 10px;">
                     <img style="height:122px;width:215px" src=${items?.loadImageUrl} alt=''/>
                   </td>`
                   : ''
                  }
                 </tr>
                  </tbody>
                  </table>`: ''
                }
                      <div class="sustainability__information">
                      ${sustainabilityInfos.length > 0 ?
                        sustainabilityInfos.map((sustainabilityInfo, i) => {
                          return (
                            sustainabilityInfo.selectedFields.length > 0 ? `
                        <div class="card-header">
                          <div class="row align-items-center">
                            <div class="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <div class="sustainability__information-inner">
                                <div>
                                  <h2 class="card-title" style="font-size:14px;margin-top: 5px; margin-bottom: 5px;">LEED/Diversion % Information</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table class="driver-signature-table">
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("totalpercentage") !== -1 &&
                              sustainabilityInfo.totalpercentage > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td style="width: 150px" class="signature-title">
                                      Total % (Yards)
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      <center>${Math.round(sustainabilityInfo.totalpercentage)}</center>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td style="width: 150px" class="signature-title">
                                      <span >Total % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      <center>${Math.round(sustainabilityInfo.weight_total)}</center>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td style="width: 200px" class="signature-title">
                                      <span >Total Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      <center>${sustainabilityInfo.weight || _.get(items, "tonnage", 0)}</center>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: ''}
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("waste") !== -1 &&
                              sustainabilityInfo.waste > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Waste % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.waste}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Waste % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[0].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Waste Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[0].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: ''}
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("brick") !== -1 &&
                              sustainabilityInfo.brick > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Brick % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.brick}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Brick % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[1].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Brick Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[1].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("dirt") !== -1 &&
                              sustainabilityInfo.dirt > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Dirt % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.dirt}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Dirt % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[2].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Dirt Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[2].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("concrete") !== -1 &&
                              sustainabilityInfo.concrete > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Concrete % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.concrete}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Concrete % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[3].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Concrete Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[3].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("cleanwood") !== -1 &&
                              sustainabilityInfo.cleanwood > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Cleanwood % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.cleanwood}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Cleanwood % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[4].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Cleanwood Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[4].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("metal") !== -1 &&
                              sustainabilityInfo.metal > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >metal % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.metal}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >metal % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[5].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >metal Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[5].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("paper_cardboard") !== -1 &&
                              sustainabilityInfo.paper_cardboard > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td style="width: 150px" class="signature-title">
                                      <span >Paper/Cardboard % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.paper_cardboard}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Paper/Cardboard % (Tons)</span>
                                    </td>
                                    <td
                                      style="font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[6].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Paper/Cardboard Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[6].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("plastic") !== -1 &&
                              sustainabilityInfo.plastic > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Plastic % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.plastic}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Plastic % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[7].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Plastic Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[7].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("drywall") !== -1 &&
                              sustainabilityInfo.drywall > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Drywall % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.drywall}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Drywall % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[8].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Drywall Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[8].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("glass") !== -1 &&
                              sustainabilityInfo.glass > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Glass % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.glass}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Glass % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[9].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Glass Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[9].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            ${sustainabilityInfo.selectedFields.length > 0 &&
                              sustainabilityInfo.selectedFields.indexOf("asphalt") !== -1 &&
                              sustainabilityInfo.asphalt > 0 ? `
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Asphalt % (Yards)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.asphalt}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Asphalt % (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${Math.round(sustainabilityInfo.tonnagepercentage[10].value)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 200px">
                                      <span >Asphalt Weight (Tons)</span>
                                    </td>
                                    <td
                                      style=" font-weight: normal;padding-left:20px"
                                      class="signature-title"
                                    >
                                      ${sustainabilityInfo.tonnageweight[10].value}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            `: '' }
                          </tr>
                          <tr>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td  class="signature-title" style="width: 150px;">
                                      <span >Recycling %</span>
                                    </td>
                                    <td style="padding-left: 10px;">
                                      <h2 class="signature-title" style="font-weight: normal">
                                        ${Math.round(sustainabilityInfo.recyclingpercentage)}%
                                      </h2>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table class="first-table-child">
                                <tbody>
                                  <tr>
                                    <td class="signature-title" style="width: 150px">
                                      <span >Waste %</span>
                                    </td>
                                    <td style="padding-left: 10px">
                                      <h2 class="signature-title" style="font-weight: normal">
                                        ${Math.round(sustainabilityInfo.residualpercentage)}%
                                      </h2>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                      `: '');
                    }) : ''}
                    </div> 
        </td>
      </tr>
    </tbody>
   </table>`
   html = PrintSisterCompanyOrderReceipt(value, last, sustainabilityInfosTemplate)
  } else {
    html = `<html lang="en">
          <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <title>Print Invoice</title>
          
            <style>
              @media print {
                html {
                  size: A4;
                  page-break-after: avoid;
                }
                @page {                  
                  page-break-after: avoid;
                }
                .invoice__each-transaction {
                  position: relative;
                  page-break-after: auto;
                }
                .invoice__each-transaction {
                  visibility: visible;
                  position: relative;
                  left: 0;
                  top: 0;
                }
                .print-table-block {
                  page-break-after: auto;
                  position: relative;
                }
                .print-block {
                  display: block;
                  page-break-before:always;
              }
            }
            .nvoice-layout{
              width: 100%;
              max-width: 870px;
              margin: 0px auto;
            }
            .nvoice-layout tr th,.nvoice-layout tr td{
              font-family: Arial, Helvetica, sans-serif;
              font-weight: 700;
              font-size: 11px;
              line-height: normal;
              color: #000000;
              text-align: left;
            }
            .nvoice-layout tr td{
              font-weight: 400;
              vertical-align: text-top;
            }
            .last-child{
              text-align: right !important;
            }
            /* Start table */
            .invoice-header-table{
              width: 100%;
            }
            .invoice-header-table tr th{
              font-family: Arial, Helvetica, sans-serif;
              font-weight: 700;
              font-size: 16px;
              line-height: normal;
              color: #000000;
              padding: 10px 0;
              text-align: center;
            }
            .invoice-header-table tr td{
              font-family: Arial, Helvetica, sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: normal;
              color: #000000;
              padding: 2px 0px;
            }
            /* End table */
            /* Start table */
              .vehicle-customer-table{
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
              }
              .vehicle-customer-table table{
                width: auto;
              }
              .vehicle-customer-table table tr td:last-child{
                font-weight: 400;
                padding-left: 15px;
              }
              .first-table-child{
                margin-right: auto;
              }
              .second-table-child{
                margin: auto;
              }
              .last-table-child{
                margin-left: auto;
              }
              .first-table-child th,
              .second-table-child th,
              .last-table-child th{
                white-space: nowrap;
              }
            /* End table*/
            /* Start table*/
            .nots-table{
              width: 100%;
              margin-bottom: 10px;
              text-align: left;
            }
            /* End table*/
            /* Start table*/
            .materials-services-table{
              width: 100%;
              margin-bottom: 10px;
              text-align: left;
              border-collapse: collapse;
            }
            
            .materials-services-table tr th,
            .materials-services-table tr td{
              border-bottom: 1px solid #000;
              padding: 3px 0;
            }
            /* End table*/
            /* Start table*/
            .materials-ticket-table{
              width: auto;
            }
            .materials-ticket-table tr th,
            .materials-ticket-table tr td{
              border-bottom: 0px;
            }
            /* End table*/
            /* Start table*/
            .account-type-nadtodatl-table{
              width: 100%;
              margin-bottom: 10px;
            }
            .account-type-nadtodatl-table th,
            .account-type-nadtodatl-table td{
              border-bottom: 0px;
              vertical-align: top;
            }
            /* .account-type-nadtodatl-table table{} */
            .amount-total-table th{
              padding-right: 31px;
            }
            /* End table*/
            /* Start table*/
            .driver-signature-table {
              width: 100%;
              margin-bottom: 10px;
              }
            .driver-signature-table .signature-title{
              font-family: Arial, Helvetica, sans-serif;
              font-weight: 700;
              font-size: 14px;
              line-height: normal;
              color: #000000;
              display: inline-block;
              margin:  0px 0px 7px;
            }
            .driver-signature{
              min-width: 290px;
              border-bottom: 1px solid #000;
              display: inline-block;
            }
            .powered-by {
              position: fixed;
              bottom: 0;
              left: 0;
              width: 100%;
              text-align: center;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 12px;
              color: #808080;
              padding: 10px 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          
            .powered-by span {
              margin-right: 10px;
            }
            /* End table*/
      
            </style>
          </head>
          <body>
            <table class="nvoice-layout">
              <tbody>
                <tr>
                  <td>
                    <table class="invoice-header-table">
                      <thead>
                          <tr>
                          <th colspan="3" style="text-align: left;">
                            <img style="max-height: 50px; max-width: 250px;" src=${items?.company?.logo?.length > 0 ? items?.company?.logo : Logo}>
                          </th>
                        </tr>
                        <tr>
                        <td style="height: 20px;"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                          ${items?.company?.businessInfo?.address}
                      <br />
                      ${format_address(items?.company?.businessInfo)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                          ${items?.company?.billingEmail}
                      <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="vehicle-customer-table">
                      <tbody>
                        <tr>
                          <td>
                            <table class="first-table-child">
                              <tbody>
                                <tr>
                                  <th>Vehicle:</th>
                                  <td>
                                  ${(items?.driver && items?.driver?.licenseNumber) || (items?.truck && items?.truck?.plateNumber) ?
                                    ` ${_.get(items, "driver.licenseNumber", "")}
                                                         ${items?.driver ? items?.driver?.licenseNumber && items?.truck?.plateNumber ? " | " : "" : ''}
                                                         ${_.get(items, "truck.plateNumber", "")}` : 'N/A'
                                  }
                              </td>
                                </tr>
                                <tr>
                                  <th>Customer:</td>
                                  <td>${items?.customer?.displayName}</td>
                                </tr>
                                <tr>
                                  <th>Driver:</th>
                                  <td>${items?.driver ? items?.driver?.firstName + " " + items?.driver?.lastName : "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Origin City:</th>
                                  <td>${items?.cityOfOrigin ? items?.cityOfOrigin : "N/A"}</td>
                                </tr>
                                ${items?.company?.serviceAddress && items?.serviceLocation && items?.serviceLocation !== "" ? `<tr>
                                  <th>Service Location:</th>
                                  <td>${items?.serviceLocation ? items?.serviceLocation : ""}</td>
                                  </tr>`: ""}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table class="second-table-child">
                              <tbody>
                                <tr>
                                  <th>Ticket Number:</th>
                                  <td>${items.ticketNumber}</td>
                                </tr>
                                <tr>
                                  <th>Date: </th>
                                  <td>${moment(items.completedDate)?.tz(items.company?.timezone || this.state?.tz)?.format("l")}</td>
                                </tr>
                                <tr>
                                  <th>Inbound Time:</th>
                                 <td>${items?.orderCreationType !== "createCharge" ? moment(items.inboundWeightDate)?.tz(items.company?.timezone || this.state?.tz)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Outbound Time:</th>
                                  <td>${items?.orderCreationType !== "createCharge" ? moment(items.outboundWeightDate)?.tz(items.company?.timezone || this.state?.tz)?.format("MM/DD/YY [at] h:mm A") : "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Payment Type:</th>
                                  <td>${items?.paymentType !== "" ? items?.paymentType?.toUpperCase() : "N/A"}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table class="last-table-child">
                              <tbody>
                                <tr>
                                  <th>Gross:</th>
                                  <td>${items.inboundWeight} lbs</td>
                                </tr>
                                <tr>
                                  <th>Tare:</th>
                                  <td>${items.outboundWeight} lbs</td>
                                </tr>
                                <tr>
                                  <th>Net:</th>
                                  <td>${items.netWeight} lbs</td>
                                </tr>
                                <tr>
                                  <th>Tons:</th>
                                  <td>${items.tonnage}</td>
                                </tr>
                                <tr>
                                  <th>Yardage:</th>
                                  <td>${items.yardage ? items.yardage : "0"}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="nots-table">
                      <thead>
                        <tr>
                          <th>Notes/Comments:</th>
                        </tr>
                        <tr>
                          <td>
                          ${items.notes ? items.notes.replace(/\n/g, '<br/>') : ""}
                          </td>
                        </tr>
                      </thead>
                    </table>
                    <table class="materials-services-table">
                      <thead>
                        <tr>
                          <th>Materials & Services</th>
                          <th>Description</th>
                          <th>Unit</th>
                          <th>Rate</th>
                          <th style="text-align:right;">Amount</th>
                        </tr>
                        <tr>
                        ${arrangeOrderData.length > 0 &&
                          arrangeOrderData.map(
                            (item, index) =>
                              `
                             ${item.orderCreationType === "createCharge"
                             ? !item.isMinimumPrice ? `
                                        <tbody>
                              ${item.fixedItems
                                .map(
                                  (orderItem, index) =>
                                    `
                                  <tr>
                                 <td style="white-space: nowrap;">${orderItem.fixedItem}</td>
              
                                 <td>                      
                                    <div class="desc-list-items">
                                     <p>Ticket: <span>#${item.ticketNumber}</span></p>
                                   </div> 
                                 </td>
                                 <td>${orderItem.units}</td>
                                 <td>$${this.numberWithCommas(
                                  Number(orderItem.unitPrice)
                                  )}</td>
                                 <td style="white-space: nowrap;text-align:right;">$${this.numberWithCommas(
                                  Number(orderItem.totalamout)
                                  )}</td>
                                 </tr>
                                 `
                                 )
                                 .join("")}
                                  ${items?.isEnvironmentalLoadFee ?
                                    `${` <tr>
                                    <td>&nbsp;</td>
                                    <td>Environmental Load Fee Amount</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td style="text-align:right;">$${this.numberWithCommas(Number(items?.environmentalLoadFeeAmount))}</td>
                                    </tr>`
                                  }` : ""}
                                  ${items?.isFuelSurchardeFee ?
                                    `${` <tr>
                                    <td>&nbsp;</td>
                                    <td>Fuel Surcharge Fee Amount</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td style="text-align:right;">$${this.numberWithCommas(Number(items?.fuelSurchardeFeeAmount))}</td>
                                    </tr>`
                                  }` :""}
                                  ${items?.company.chargeStripeCardFees ? item?.stripeCardFees > 0 ?
                                  `${` <tr>
                                        <td>&nbsp;</td>
                                        <td>Handling Fee</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td style="text-align:right;">$${this.numberWithCommas(Number(item?.stripeCardFees))}</td>
                                        </tr>`
                                 }` :"" :""}
                                  </tbody>
                                 `:
                                 `
                                        <tbody>
                                        ${item.fixedItems ? item.fixedItems.length > 0 ? item.fixedItems
                                          .map(
                                            (orderItem, index) =>
                                              `
                                  <tr>
                                 <td style="white-space: nowrap;">${orderItem.fixedItem}</td>
              
                                 <td>                      
                                    <div class="desc-list-items">
                                     <p>Ticket: <span>#${item.ticketNumber}</span></p>
                                   </div> 
                                 </td>
                                 <td>${orderItem.units}</td>
                                 <td>$${this.numberWithCommas(
                                  Number(orderItem.unitPrice)
                                  )}</td>
                                 <td style="white-space: nowrap;text-align:right;">$${this.numberWithCommas(
                                  Number(orderItem.totalamout)
                                  )}</td>
                                 </tr>
                                 `
                                 )
                                 .join("") : "" : ""}
                                 ${items?.company.chargeStripeCardFees ? item?.stripeCardFees > 0 ?
                                `${` <tr>
                                      <td>&nbsp;</td>
                                      <td>Handling Fee</td>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td style="text-align:right;">$${this.numberWithCommas(Number(item?.stripeCardFees))}</td>
                                      </tr>`
                               }` :"" :""}
                                  </tbody>
                                 `
                                 : ` ${!item.isMinimumPrice ?
                                  `
                                      <tbody>
                                       <tr>
                                        <td style="white-space: nowrap;">${customStartCase(
                                          item.dumpRateType
                                          )}</td>
                                            <td>                      
                                                <div class="desc-list-items">
                                                  <p>Ticket: <span>#${item.ticketNumber
                                                  }</span></p>
                                                  ${item.customerRefrence &&  item.customerRefrence !== "" ?
                                                  `<p style="margin:2 0;">Cust. Ref.# <span>${item.customerRefrence
                                                  }</span></p>`
                                                  : ""}
                                                  <p>Inbound Weight = <span>${this.numberWithCommas(
                                                    Number(item.inboundWeight)
                                                    )}</span></p>
                                                  <p>Outbound Weight = <span>${this.numberWithCommas(
                                                    Number(item.outboundWeight)
                                                    )}</span></p>
                                                  <p>Net Weight = <span>${this.numberWithCommas(
                                                    Number(item.netWeight)
                                                    )}</span></p>
                                                </div> 
                                            </td> 
                                            <td> ${this.numberWithCommas(Number(item.units))}</td>
                                            <td>$${this.numberWithCommas(
                                              Number(item.dumpRate)
                                              )} </td>
                                            <td style="text-align:right;">$${this.numberWithCommas(item.totalAmount)}
                                          </td>
                                          </tr>
                                     ${item.isEnvironmentalLoadFee ?
                                      `${` <tr>
                                          <td>&nbsp;</td>
                                          <td>Environmental Load Fee Amount</td>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                          <td style="text-align:right;">$${this.numberWithCommas(Number(item.environmentalLoadFeeAmount))}</td>
                                          </tr>`
                                       }` :""}
                                      ${item.isFuelSurchardeFee ?
                                      `${` <tr>
                                            <td>&nbsp;</td>
                                            <td>Fuel Surcharge Fee Amount</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td style="text-align:right;">$${this.numberWithCommas(Number(item.fuelSurchargeTotal))}</td>
                                            </tr>`
                                     }` :""}
                                     ${items?.company.chargeStripeCardFees ? item?.stripeCardFees > 0 ?
                                     `${` <tr>
                                           <td>&nbsp;</td>
                                           <td>Handling Fee</td>
                                           <td>&nbsp;</td>
                                           <td>&nbsp;</td>
                                           <td style="text-align:right;">$${this.numberWithCommas(Number(item?.stripeCardFees))}</td>
                                           </tr>`
                                    }` :"" :""}
                                     </tbody>
                                     ${item.additionalItems.length > 0 ? item.additionalItems.map((additionalItem) => `
                                      <tbody>
                                       <tr>
                                        <td style="white-space: nowrap;">${customStartCase(additionalItem.label)}</td>
                                        <td>                      
                                            <div class="desc-list-items">
                                              <p>Ticket: <span>#${item.ticketNumber}</span></p>
                                            </div> 
                                        </td> 
                                        <td> ${this.numberWithCommas(Number(additionalItem.quantity))}</td>
                                        <td>$${this.numberWithCommas(Number(additionalItem.value))} </td>
                                        <td style="text-align:right;">$${this.numberWithCommas(Number(additionalItem.quantity) * Number(additionalItem.value))}</td>
                                       </tr>
                                     </tbody>
                                     ` ).join("") : ""} `
                                  : `${
                                    `
                                    <tbody>
                                    <tr>
                                     <td style="white-space: nowrap;">${customStartCase(
                                      item.dumpRateType
                                      )}</td>
                                         <td>                      
                                             <div class="desc-list-items">
                                               <p>Ticket: <span>#${item.ticketNumber
                                               }</span></p>
                                               ${item.customerRefrence &&  item.customerRefrence !== "" ?
                                                  `<p style="margin:2 0;">Cust. Ref.# <span>${item.customerRefrence
                                                  }</span></p>`
                                                  : ""}
                                               <p>Inbound Weight = <span>${this.numberWithCommas(
                                                Number(item.inboundWeight)
                                                )}</span></p>
                                               <p>Outbound Weight = <span>${this.numberWithCommas(
                                                Number(item.outboundWeight)
                                                )}</span></p>
                                               <p>Net Weight = <span>${this.numberWithCommas(
                                                Number(item.netWeight)
                                                )}</span></p>
                                             </div> 
                                         </td> 
                                         <td> ${item.units
                                   }</td>
                                         <td>$${this.numberWithCommas(
                                          Number(item.dumpRate)
                                          )} </td>
                                         <td style="text-align:right;">$${this.numberWithCommas(item.totalAmount)}
                                       </td>
                                       </tr>
                                       </tbody>`}`} `
                                      } `
                                  )
                                    .join("")}
                      </thead>
                    </table>
                    <table class="account-type-nadtodatl-table">
                      <tbody>
                        <tr>
                          <td>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <tr>
                                    ${ 
                                      ` <th>Account Type:</th>
                                      <td>${items.paymentTerms.toUpperCase() === "COD" ? items.paymentType==="credit-card" ?  `COD - ${items?.cardNumber.slice(-4)}` : "COD" : "ACCOUNT" }</td>` 
                                     }
                                    </tr>
                                  </td> 
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table class="amount-total-table last-table-child">
                              <tbody>
                                <tr>
                                  <th>Total: </th> 
                                  <td class="last-child">$${this.numberWithCommas(
                                    this.round2decimal(
                                      Number(items.totalAmount)
                                    )
                                  )}</td> 
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="driver-signature-table">
                      <tbody>
                        <tr>
                          <td colspan="2">
                            <h2 class="signature-title">Driver Signature:	</h2>
                            <div class="driver-signature">
                            ${items.driverSignature ?
                              `<img src=${items.driverSignature} alt=''/>`
                              : ''}
                            </div>
                          </td>
                        </tr>
                        <tr>
                        <td style="height: 15px"></td>
                        </tr>
                     </tbody>
                    </table>
                    ${items?.truck?.plateImageUrl || items?.loadImageUrl ?
                      ` <table class="driver-signature-table" style="width:100%; max-width: 460px;">
                         <tbody>
                         
                           <tr>
                           ${items?.truck?.plateImageUrl ?
                            ` <th >
                               <h2 class="signature-title" style="margin-bottom: 5px;">License plate	</h2>
                               </th>`: ''
                              }
                               ${items?.loadImageUrl ?
                             `<th >
                               <h2 class="signature-title" style="margin-bottom: 5px;">Inbound Load	</h2>
                             </th>`
                             : ''
                            }
                           
                           </tr>
                         
                           <tr>
                           ${items?.truck?.plateImageUrl ?
                            `<td style="padding-right: 10px;">
                               <img style="height:122px;width:215px" src=${items?.truck?.plateImageUrl} alt=''/>
                             </td>`
                             :''
                           }
                           ${items?.loadImageUrl ?
                            `  <td style="padding-right: 10px;">
                               <img style="height:122px;width:215px" src=${items?.loadImageUrl} alt=''/>
                             </td>`
                             :''
                           }
                           </tr>
                            </tbody>
                            </table>`: ''
                          }
                                <div class="sustainability__information">
                                ${sustainabilityInfos.length > 0 ?
                                  sustainabilityInfos.map((sustainabilityInfo, i) => {
                                    return (
                                      sustainabilityInfo.selectedFields.length > 0 ? `
                                <div class="card" key="${i}">
                                  <div class="card-header">
                                    <div class="row align-items-center">
                                      <div class="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                                        <div class="sustainability__information-inner">
                                          <div>
                                            <h2 class="card-title">LEED/Diversion % Information</h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <table class="driver-signature-table">
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("totalpercentage") !== -1 &&
                                        sustainabilityInfo.totalpercentage > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td style="width: 150px" class="signature-title">
                                                Total % (Yards)
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                <center>${Math.round(sustainabilityInfo.totalpercentage)}</center>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td style="width: 150px" class="signature-title">
                                                <span >Total % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                <center>${Math.round(sustainabilityInfo.weight_total)}</center>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td style="width: 200px" class="signature-title">
                                                <span >Total Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                <center>${sustainabilityInfo.weight || _.get(items, "tonnage", 0)}</center>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("waste") !== -1 &&
                                        sustainabilityInfo.waste > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Waste % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.waste}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Waste % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[0].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Waste Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[0].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("brick") !== -1 &&
                                        sustainabilityInfo.brick > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Brick % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.brick}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Brick % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[1].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Brick Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[1].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("dirt") !== -1 &&
                                        sustainabilityInfo.dirt > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Dirt % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.dirt}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Dirt % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[2].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Dirt Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[2].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("concrete") !== -1 &&
                                        sustainabilityInfo.concrete > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Concrete % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.concrete}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Concrete % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[3].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Concrete Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[3].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("cleanwood") !== -1 &&
                                        sustainabilityInfo.cleanwood > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Cleanwood % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.cleanwood}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Cleanwood % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[4].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Cleanwood Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[4].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("metal") !== -1 &&
                                        sustainabilityInfo.metal > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >metal % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.metal}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >metal % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[5].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >metal Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[5].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                      sustainabilityInfo.selectedFields.indexOf("paper_cardboard") !== -1 &&
                                      sustainabilityInfo.paper_cardboard > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td style="width: 150px" class="signature-title">
                                                <span >Paper/Cardboard % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.paper_cardboard}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Paper/Cardboard % (Tons)</span>
                                              </td>
                                              <td
                                                style="font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[6].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Paper/Cardboard Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[6].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("plastic") !== -1 &&
                                        sustainabilityInfo.plastic > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Plastic % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.plastic}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Plastic % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[7].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Plastic Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[7].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("drywall") !== -1 &&
                                        sustainabilityInfo.drywall > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Drywall % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.drywall}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Drywall % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[8].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Drywall Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[8].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("glass") !== -1 &&
                                        sustainabilityInfo.glass > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Glass % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.glass}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Glass % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[9].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Glass Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[9].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      ${sustainabilityInfo.selectedFields.length > 0 &&
                                        sustainabilityInfo.selectedFields.indexOf("asphalt") !== -1 &&
                                        sustainabilityInfo.asphalt > 0 ? `
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Asphalt % (Yards)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.asphalt}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Asphalt % (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${Math.round(sustainabilityInfo.tonnagepercentage[10].value)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 200px">
                                                <span >Asphalt Weight (Tons)</span>
                                              </td>
                                              <td
                                                style=" font-weight: normal;padding-left:20px"
                                                class="signature-title"
                                              >
                                                ${sustainabilityInfo.tonnageweight[10].value}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      `: '' }
                                    </tr>
                                    <tr>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td  class="signature-title" style="width: 150px;">
                                                <span >Recycling %</span>
                                              </td>
                                              <td style="padding-left: 10px;">
                                                <h2 class="signature-title" style="font-weight: normal">
                                                  ${Math.round(sustainabilityInfo.recyclingpercentage)}%
                                                </h2>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <table class="first-table-child">
                                          <tbody>
                                            <tr>
                                              <td class="signature-title" style="width: 150px">
                                                <span >Waste %</span>
                                              </td>
                                              <td style="padding-left: 10px">
                                                <h2 class="signature-title" style="font-weight: normal">
                                                  ${Math.round(sustainabilityInfo.residualpercentage)}%
                                                </h2>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                                `: '');
                              }) : ''}
                              </div> 
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="powered-by">
              <span >Powered by</span> <img src=${Logo} alt="WeighWorks" style=" max-width: 120px;max-height:30px;"  />
            </div>
          </body>
         </html>`
         }
        printWindow += html;
        let imgCount = 0;
        let totalImages = 0;
        const imageLoaded = () => {
          imgCount++;
          if (imgCount === totalImages) {
            resolve();
          }
        };
        const loadImage = (imageUrl) => {
          const img = new Image();
          img.onload = imageLoaded;
          img.src = imageUrl;
        };
        if (items?.truck?.plateImageUrl) {
          loadImage(items?.truck?.plateImageUrl);
          totalImages++;
        }
        if (items?.loadImageUrl) {
          loadImage(items.loadImageUrl);
          totalImages++;
        }
        if (items?.company && items?.company?.logo) {
          totalImages++;
          loadImage(items?.company?.logo);
        }
        if (totalImages === 0) {
          resolve();
      }
      });

    })
    Promise.all(printPromises).then(() => {
      setTimeout(() => {
        this.setState({ isTicketInvoiceLoaderPrint: false }, () => {
          let printInvoice = window.open("", "_blank");
          printInvoice.document.write(printWindow);
          printInvoice.document.close();
          printInvoice.onload = () => {
            printInvoice.print();
          };
        });
      }, 5000);
    });
    
  };

  sendEmailReceipt = async () => {
    const { emailBtnTxt, emailProgress } = this.state;
    const ticketNumber = this.state.order.ticketNumber;
    const billingEmailAddress = this.state.order.customer.billingEmailAddress;
    const secondaryContacts = this.state.order.customer.secondaryBillingContacts;
    if (emailBtnTxt !== "Email Receipt" || emailProgress) return;
    if (
      (!billingEmailAddress || billingEmailAddress.trim() === "") &&
      secondaryContacts && secondaryContacts.every((contact) => !contact.billingEmailAddress)
    ) {
      this.setState({ showModal: true });
      return;
    }
    const { sendEmailReceipt } = this.props;
    this.setState({ emailProgress: true });
    const emailAddress = secondaryContacts ? [billingEmailAddress, ...secondaryContacts] : [billingEmailAddress];
    const { value } = await sendEmailReceipt({ ticketNumber, timezone, emailAddress });
    message.success(value.message);
    this.setState({ emailProgress: false, emailBtnTxt: "Email Receipt" });
  };

  saveNotes = async orderId => {
    const { notes } = this.state;
    const payload = { id: orderId, notes};
    const { updateOrder } = this.props;
    const {
      value: { status }
    } = await updateOrder(payload);
    if (status) {
      this.setState({ showEditor: false, saveNotes: this.state.notes});
    }
  };
  onCloseDrawer = () => {
    this.setState({ visible: false })
  }
  closeModal = async () => {
    const { order } = this.state;
    this.setState({ ismodelopen: false });
    if (this.props.location?.param === 'notification') {
      if (this.props.location?.currentPath === '/create-charge' && order?.orderCreationType === 'createCharge' && !order?.completed) {
        this.props.history.push({ pathname: `/create-charge/${order.ticketNumber}` })
      } else {
        this.props.history.goBack()
      }
    } else if (this.props.location?.state?.routeName === "sustainability-information") {
      this.props.history.push({ pathname: `/completed-orders` })
      await sessionStorage.setItem("current-page-complete-orders", sessionStorage.getItem("current-page-complete-orders"))
    } else {
      await sessionStorage.setItem("current-page-complete-orders", sessionStorage.getItem("current-page-complete-orders"))
      this.state.is_previous_page_scale ?
        this.props.history.push({ pathname: "/scale" }) :
        this.props.history.push('/completed-orders')
    }
  }
  
  getOrderInvoicePdf = async (ticketnumber, loaderType) => {
    if (loaderType === "ticket_invoice") {
      this.setState({ isTicketInvoiceLoader: true })
    }
    else {
      this.setState({ isTicketInvoiceLoaderPrint: true })
    }
    const { value } = await this.props.getOrderInvoicePdf(ticketnumber)
    if (value) {
      this.setState({ isTicketInvoiceLoader: false, isTicketInvoiceLoaderPrint: false })
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');

      if (loaderType !== "ticket_invoice") {
        printWindow.onload = function () {
          printWindow.print();
        };
      }
    }
  }


  openPrintPerforatedScreen = async () => {
    this.setState({ loaderPerforated: true })
    const {
      match: {
        params: { id }
      },
      fetchOrder,
      fetchMe
    } = this.props;
    const [
      {
        value: { data }
      },
    ] = await Promise.all([fetchOrder(id), fetchMe()]);
    let printWindow = "";
    let printData = [data];
    printData.map((items, i) => {
      return new Promise(async (resolve, reject) => {
        let body1 = PrintPerforatedInvoice(items,this.state.sisterCompanyData);
        let imgCount = 0;
        let totalImages = 0;
        const imageLoaded = () => {
          imgCount++;
          if (imgCount === totalImages) {
            resolve();
          }
        };
  
        const loadImage = (imageUrl) => {
          const img = new Image();
          img.onload = imageLoaded;
          img.src = imageUrl;
        };
  
        if (items?.truck?.plateImageUrl) {
          totalImages++;
          loadImage(items?.truck?.plateImageUrl);
        }
        if (items?.loadImageUrl) {
          totalImages++;
          loadImage(items?.loadImageUrl);
        }
        if (items?.company && items?.company?.logo) {
          totalImages++;
          loadImage(items?.company?.logo);
        }
  
        if (totalImages === 0) {
          resolve();
        }
        printWindow += body1;
      });
    });
    const { value } = await this.props.printPerforatedOrder({
      html: printWindow,
      ticketNumber: printData[0].ticketNumber
    })
    if (value) {
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');
      printWindow.onload = function () {
        printWindow.print();
      };
      this.setState({ loaderPerforated: false })
    }
    /* Promise.all(printPromises).then(() => {
      setTimeout(() => {
        this.setState({ loaderPerforated: false }, () => {
          let printInvoice = window.open("", "_blank");
          printInvoice.document.write(printWindow);
          printInvoice.document.close();
          printInvoice.onload = () => {
            printInvoice.print();
            printInvoice.close();
          };
        });
      }, 5000);
    }); */
  };
  PrintOrderDetails = async () => {
    this.setState({
      orderDetailsLoader: true,
    })
    const {
      match: {
        params: { id },
      },
      fetchOrder,
      fetchMe,
    } = this.props;
    const [
      {
        value: { data, orderLogs, status },
      },
      {
        value: { user },
      },
    ] = await Promise.all([fetchOrder(id), fetchMe()]);
    if (status) {
      data.timezone = this.state.tz
      const { value } = await this.props.printOrderDetails({
        data: data,
        orderLog: orderLogs,
        users: user,
      })
      if (value) {
        this.setState({
          orderDetailsLoader: false,
        })
      }
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');
      printWindow.onload = function () {
        printWindow.print();
      };
    }
  }

  handleSignCallback(data) {
    this.setState({ signatureUrl: data?.signatureUrl })
  }

  render() {
    const { order, user, orderLogs, progressBar, loaderPerforated, orderDetailsLoader, serviceLocation, customerRefrence, isServiceLocationEditing, isCustomerRefrenceEditing , isCityOfOriginEditing , cityOfOrigin} = this.state; 

    return (
      <>
          <div className="layout-has-sidebar">
          <TopNavigation
            {...this.props}
            onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
          />
          <SidebarNavigation
            {...this.props}
            toggleMenu={this.state.toggleMenu}
            onClickToggleMenu={() => this.setState({ toggleMenu: false })}
          />
          <main className="dashboard-layout-content complete__details-wrapper">
            {this.state.orderCreationType !== "createCharge" && this.state.orderCreationType !== "createCharge" ?
              <ReactModal
                isOpen={this.state.ismodelopen}
                onRequestClose={this.closeModal}
                className="order__details-modal"
              >
                <div className="react-modal-dialog react-modal-dialog-centered">
                  <div className="react-modal-header pt-2">
                    <div className="btn-back-header">
                      <h5 className="react-modal-title">Order detail</h5>        
                    {
                    !this.state.spinning &&
                    <div className="page-header-btn-grp d-flex align-items-center">
                    {order.orderType === "manual" && (
                      <button onClick={() => this.setState({ visible: true })} className="btn btn-dark btn-sm email__receipt-btn">
                        Upload Paper Receipt
                      </button>
                    )}
                    <button
                      //  onClick={()=>{
                      //   this.props.history.push(`/print-ticket-invoice/${order?.ticketNumber }`)
                      //  }}
                      onClick={() =>this.state.isTicketInvoiceLoader ? undefined : this.getOrderInvoicePdf(order.ticketNumber, "ticket_invoice")}
                          className="btn btn-dark btn-sm email__receipt-btn">
                             {/* <EmailIcon />      */}
                             {this.state.isTicketInvoiceLoader ?
                            "Please wait…" : "Ticket Invoice"}
                        </button>
                        <div>
                          <button onClick={() => this.sendEmailReceipt()} className="btn btn-dark btn-sm email__receipt-btn mt-4">
                            {/* <EmailIcon />      */}
                            Email Receipt           
                          </button>
                          <div className="underline text-right mt-2" onClick={() => orderDetailsLoader === false ? this.PrintOrderDetails() : undefined}>{orderDetailsLoader === true ? "Loading..." : "Print Order Details"}</div>
                        </div>
                        <div>
                          <button onClick={() => this.state.isTicketInvoiceLoaderPrint ? undefined : this.getOrderInvoicePdf(order.ticketNumber, "print_invoice")} className="btn btn-dark btn-sm print__receipt-btn mt-4">
                            {/* <PrintReceiptIcon /> */}
                            {this.state.isTicketInvoiceLoaderPrint ?
                              "Please wait…" : "Print Receipt"}
                          </button>
                          <div className="underline text-right mt-2" onClick={() => loaderPerforated === false ? this.openPrintPerforatedScreen() : undefined}>{loaderPerforated === true ? "Loading..." : "Print Perforated Invoice"}</div>
                      </div>
                     </div>      
                    }   
                    </div>
                    <button type="button" className="btn react-modal-close mt-2" onClick={async () => {
                      this.setState({ ismodelopen: false });
                      if (this.props.location?.param === 'notification') {
                        if (this.props.location?.currentPath === '/create-charge' && order?.orderCreationType === 'createCharge' && !order?.completed) {
                          this.props.history.push({ pathname: `/create-charge/${order.ticketNumber}` })
                        } else {
                          this.props.history.goBack()
                        }
                      } else if (this.props.location?.state?.routeName === "sustainability-information") {
                        this.props.history.push({ pathname: `/completed-orders` })
                        await sessionStorage.setItem("current-page-complete-orders", sessionStorage.getItem("current-page-complete-orders"))
                      } else {
                        await sessionStorage.setItem("current-page-complete-orders", sessionStorage.getItem("current-page-complete-orders"))
                        this.state.is_previous_page_scale ?
                          this.props.history.push({ pathname: "/scale" }) :
                          this.props.history.push('/completed-orders')
                      }
                    }}>
                      <CloseIcon />
                    </button>
                  </div>
                  <Spin indicator={antIcon} spinning={this.state.spinning}>
                    <div className="react-modal-body">
                      <div className="custome__container">
                        {order._id ?
                          <CompleteOrderDetails
                            {...this.props}
                            order={order}
                            user={user}
                            orderLogs={orderLogs}
                            onSaveNotesSuccess={this.fetchOrderDetail.bind(this)}
                            visible={this.state.visible}
                            onClose={this.onCloseDrawer}
                            getPricingTierById={this.props.getPricingTierById}
                            hasPaymentReceipt={this.state.hasPaymentReceipt}
                            {...this.props}
                          />
                          :
                          <NoRecords title="Order Unavailable" />}
                      </div>
                    </div>
                  </Spin>
                </div>
              </ReactModal>
              :
              <ReactModal
                isOpen={this.state.ismodelopen}
                onRequestClose={this.closeModal}
              >
                <div className="react-modal-dialog react-modal-dialog-centered ticket__modal order__details-modal--">
                  <div className="react-modal-header flex-lg-column align-items-start">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div>
                        <h5 className="react-modal-title">Ticket: {order?.ticketNumber}</h5>
                        <h6 className="sub-title">{order?.customer?.displayName}</h6>
                      </div>
                      <div className="d-flex header_btn">
                        <div>
                          <button
                            onClick={() => this.state.isTicketInvoiceLoader ? undefined :this.getOrderInvoicePdf(order.ticketNumber, "ticket_invoice")}
                            className="btn btn-dark btn-sm">
                            {this.state.isTicketInvoiceLoader ?
                              "Please wait…" : "Ticket Invoice"}
                          </button>
                        </div>
                        {/* <Link to={'/invoice-each-transaction'} className="btn btn-dark btn-sm">Ticket Invoice</Link> */}
                        <div>

                          <button onClick={() =>this.state.emailProgress ? undefined : this.sendEmailReceipt()} className="btn btn-dark btn-sm"> {this.state.emailProgress ? "Please wait..." : this.state.emailBtnTxt}</button>
                          <div className="underline text-right mt-2" onClick={() => orderDetailsLoader === false ? this.PrintOrderDetails() : undefined}>{orderDetailsLoader === true ? "Loading..." : "Print Order Details"}</div>

                        </div>
                        <div>
                          <button onClick={() =>this.state.isTicketInvoiceLoaderPrint ? undefined : this.getOrderInvoicePdf(order.ticketNumber, "print_invoice")} className="btn btn-dark btn-sm">
                            {this.state.isTicketInvoiceLoaderPrint ?
                              "Please wait…" : "Print Receipt"}
                          </button>
                          <div className="underline text-right mt-2" onClick={() => loaderPerforated === false ? this.openPrintPerforatedScreen() : undefined}>{loaderPerforated === true ? "Loading..." : "Print Perforated Invoice"}</div>
                        </div>
                        <button type="button" onClick={async () => {
                          this.setState({ ismodelopen: false });
                          this.props.history.goBack()
                          await sessionStorage.setItem("current-page-complete-orders", sessionStorage.getItem("current-page-complete-orders"))
                        }} className="btn react-modal-close">
                          <CloseIcon />
                        </button>
                      </div>    
                    </div>                  
                    {order?.isDeleted && (
                      <p className="ticket-void-order-text">Void: {capitalize(order?.deleteReason)}</p>
                    )}
                  </div>


                  <div className="react-modal-body">
                    <section className="top__list-section">
                      <ul className="list">
                        <li className="items">
                          <h3 className="title">Company Name</h3>
                          <p className="sub-title">{order?.customer?.displayName}</p>
                        </li>
                        <li className="items">
                          <h3 className="title">Pricing Tier</h3>
                          <p className="sub-title">{order?.pricingTier}</p>
                        </li>
                        <li className="items">
                          <h3 className="title">License Plate Number</h3>
                          <p className="sub-title">{order?.truck?.plateNumber ? order?.truck?.plateNumber : "N/A"}</p>
                        </li>
                        <li className="items">
                          <h3 className="title">Scale Master</h3>
                          <p className="sub-title">{order?.user?.firstName + ""} {order?.user?.lastName} </p>
                        </li>
                        <li className="items">
                          <h3 className="title">Company ID </h3>
                          <p className="sub-title">{order?.customer?.customerId}</p>
                        </li>
                        <li className="items">
                          <h3 className="title">Payment Terms</h3>
                          <p className="sub-title text-uppercase">{order.paymentTerms === "" ? "COD" : order.paymentTerms} </p>
                        </li>
                        <li className="items">
                          <h3 className="title">Driver</h3>
                          <p className="sub-title">{order?.driver ? order?.driver?.firstName + " " + order?.driver?.lastName : "N/A"}</p>
                        </li>
                        <li className="items">
                        {isCityOfOriginEditing ? (
                          <div className="form-group material-textfield custom-select-50-">
                            <select
                              className="form-control custom-input w-150 material-textfield-input mb-3 select-text-truncate-240- text-truncate"
                              name="cityOfOrigin"
                              onChange={(e) => {
                                if (e.target.value === "Add New") {
                                  this.setState({
                                    isCityOfOriginModalOpen: true,
                                    citiesOfOrigin: _.get(user, "company.cities", []), 
                                  });
                                } else {
                                  this.setState({
                                    cityOfOrigin: e.target.value, 
                                  });
                                }
                              }}
                              value={this.state.cityOfOrigin} 
                              required
                            >
                              <option value="">Select</option>
                              {_.get(user, "company.cities", []).map((city) => (
                                <option key={city} value={city}>
                                  {city}
                                </option>
                              ))}
                              <option value="Add New">Add New</option>
                            </select>
                            <label className="material-textfield-label">City of Origin</label>
                            <button
                              onClick={this.handleServiceLocation} 
                              className="btn btn-dark btn-xs btn-save"
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div className="company__driver-title">
                            <h3 className="title">City of Origin</h3>
                            <p className="sub__title">
                              {cityOfOrigin || order?.cityOfOrigin || "N/A"} 
                              <img
                                style={{
                                  cursor: "pointer",
                                  maxWidth: "18px",
                                  marginLeft: "15px",
                                }}
                                onClick={() => {
                                  this.setState({ isCityOfOriginEditing: true , cityOfOrigin : cityOfOrigin ? cityOfOrigin : order?.cityOfOrigin});
                                }}
                                className="img-fluid edit__icon"
                                src={Edit} 
                                alt="Edit" 
                              />
                            </p>
                          </div>
                        )}
                      </li>    
                        <li className="items">
                          <h3 className="title">Company Address</h3>
                          {order.customer && order.customer.address ? (
                            <p className="sub-title">
                              {order && order.customer && order.customer.address}
                              <br />
                              {order && order.customer && order.customer.city},{" "}
                              {order && order.customer && order.customer.state}{" "}
                              {order && order.customer && order.customer.zip}
                            </p>
                          ) : (
                            <p className="sub-title">N/A</p>
                          )}
                        </li>
                        <li className="items">
                          <h3 className="title">Payment Methods</h3>
                          <p className="sub-title">{_.get(order, "paymentType", "") ==="" ? (_.get(order, "splitPayment", []).length > 0 ? order.splitPayment.map((payment,index)=>_.startCase(payment?.paymentType) + ( index < order.splitPayment.length - 1 ?", " :" ")): "N/A"): `${_.startCase(_.get(order, "paymentType", "N/A"))}`}</p>
                          {order.signatureUrl !== "" &&
                            <img src={`${order.signatureUrl}`} alt="signature"></img>
                          }
                        </li>
                        <li className="items">
                          <h3 className="title">Project</h3>
                          <p className="sub-title">{order?.projects?.projectName ? order?.projects?.projectName : "N/A"}</p>
                        </li>                                              
                      </ul>
                      <ul className="list pt-0">                                           
                        <li className="items">
                          {isServiceLocationEditing ? (
                            <div className="form-group material-textfield custom-select-50-">
                              <PlacesAutocomplete
                                className="form-control custom-input w-150 material-textfield-input mb-3"
                                type="text"
                                name="serviceLocation"
                                value={serviceLocation}
                                onChange={this.handleChange}
                                handleAddressChange={({ address }) => this.setState({ serviceLocation: address })}
                                setValue={address => this.setState({ serviceLocation: address })}
                                address={serviceLocation}
                                isSetToState={true}
                                label="Service Location"
                              />
                              <button
                                onClick={this.handleServiceLocation}
                                className="btn btn-dark btn-xs btn-save"
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            <div className="company__driver-title mb-3">
                              <h3 className="title">Service Location</h3>
                              <p className="sub-title">{serviceLocation || order?.serviceLocation || 'N/A'}
                                <img
                                  style={{ cursor: 'pointer', maxWidth: '18px', marginLeft: '15px' }}
                                  onClick={() => {
                                    if (isServiceLocationEditing) {
                                      this.handleServiceLocation();
                                    } else {
                                      this.setState({ isServiceLocationEditing: true,serviceLocation: order?.serviceLocation });
                                    }
                                  }}
                                  className="img-fluid edit__icon"
                                  src={Edit}
                                  alt="Edit"
                                />
                              </p>
                            </div>
                          )}
                        </li>
                        <li className="items">
                          {isCustomerRefrenceEditing ? (
                            <div className="form-group material-textfield custom-select-50-">
                              <input
                                name="customerRefrence"
                                onChange={(e) => this.setState({ customerRefrence: e.target.value })}
                                value={customerRefrence}
                                className="form-control custom-input w-150 material-textfield-input mb-3"
                                style={{ width: '200px' }}
                                required
                              />
                              <button
                                onClick={this.handleCustomerRefrence}
                                className="btn btn-dark btn-xs btn-save"
                              >
                                Save
                              </button>
                              <label className="material-textfield-label">Customer Reference #</label>
                            </div>
                          ) : (
                            <div className="company__driver-title mb-3">
                              <h3 className="title">Customer Reference</h3>
                                <p className="sub-title text-truncate">{customerRefrence || order?.customerRefrence || 'N/A'} 
                                <img
                                  style={{ cursor: 'pointer', maxWidth: '18px', marginLeft: '15px' }}
                                  onClick={() => {
                                    if (isCustomerRefrenceEditing) {
                                      this.handleCustomerRefrence();
                                    } else {
                                      this.setState({ isCustomerRefrenceEditing: true ,customerRefrence: order?.customerRefrence});
                                    }
                                  }}
                                  className="img-fluid edit__icon"
                                  src={Edit}
                                  alt="Edit"
                                />
                              </p>
                            </div>
                          )}
                        </li>
                        {(order?.loadImageUrl !== "" || order?.truck?.plateImageUrl !== "") && <><li className="items">
                          <div className="d-flex">
                            {order?.truck?.plateImageUrl !== "" &&
                              <div className="mr-3">
                                <><h3 className="title">License plate</h3> 
                                {  _.get(order,"truck.plateImageUrl",false) ? <img src={ _.get(order,"truck.plateImageUrl",false) ? order?.truck?.plateImageUrl : ""} alt="" />:"N/A" }</>
                              </div>
                            }
                            {order?.loadImageUrl !== "" &&
                              <div>
                                <><h3 className="title">Picture Of Load</h3><img src={order?.loadImageUrl} alt="" /></>
                              </div>
                            }
                          </div>
                        </li></>}
                        <li className="items">
                          {order?.driver && order?.driver?.licenseImageUrl !== "" ? (<>
                            <h3 className="title">Drivers License</h3>
                            <img src={order?.driver?.licenseImageUrl} alt="" /> </>) : null}
                          {order?.driverSignature !== "" && <>
                            <div className="signechar__box">
                              <h3 className="signechar__title">Drivers Signature</h3>
                              {/* <img src={this.state.signatureUrl!== '' ? this.state.signatureUrl : order?.signatureUrl} alt="" /> */}
                              <img src={this.state.signatureUrl!== '' ? this.state.signatureUrl : order?.driverSignature} alt="" />
                            </div> </>}
                        </li> 
                      </ul>
                    </section>
                    <section className="order__breakdown-section">
                      <h1 className="title">Order Breakdown </h1>
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <tbody>
                            <tr>&nbsp;</tr>
                            {order?.orderBreakDownItems && order.orderBreakDownItems.fixedItem.length > 0 ? <tr>
                              <td className="bold-title">Fixed Item(s) </td>
                              <td className="bold-title">Unit Price</td>
                              <td className="bold-title">Yards</td>
                              <td className="bold-title">&nbsp;</td>
                            </tr> : ""}
                            {order?.orderBreakDownItems && order.orderBreakDownItems.fixedItem.length > 0 &&
                              order.orderBreakDownItems.fixedItem.map((item, index) =>
                                <tr className="row-bg-none last__border-0">
                                  <td className="border-bottom">{item.fixedItem}</td>
                                  <td className="border-bottom">${item.unitPrice}</td>
                                  <td className="border-bottom">{item.units}</td>
                                  <td className="text-right border-bottom">${this.numberWithCommas(this.round2decimal(Number(item.unitPrice) * Number(item.units)))}</td>
                                </tr>
                              )}
                            {order?.orderBreakDownItems && order.orderBreakDownItems.chargeByWeight.length > 0 ? <tr>
                              <td className="bold-title">Charge by Weight</td>
                              <td className="bold-title">Unit Price</td>
                              <td className="bold-title">Tons</td>
                              <td className="bold-title">&nbsp;</td>
                            </tr> : ""}
                            {order?.orderBreakDownItems && order.orderBreakDownItems.chargeByWeight.length > 0 &&
                              order.orderBreakDownItems.chargeByWeight.map((item, index) =>
                                <tr>
                                  <td className="border-bottom">{item.fixedItem}</td>
                                  <td className="border-bottom">${item.unitPrice}</td>
                                  <td className="border-bottom">{item.units}</td>
                                  <td className="text-right border-bottom">${this.numberWithCommas(this.round2decimal(Number(item.unitPrice) * Number(item.units)))}</td>
                                </tr>
                              )}
                            <tr className="row-bg-none">
                              <td>Capped Pricing</td>
                              <td>${Number(order?.orderBreakDownItems?.capoutPrice) > 0 ? this.numberWithCommas(Number(order.orderBreakDownItems &&
                                order?.orderBreakDownItems?.capoutPrice)) : 0}</td>
                              <td>&nbsp;</td>
                              <td className="text-right">{order.orderBreakDownItems && order?.orderBreakDownItems?.isCapOutPrice ? "Yes" : "No"}</td>
                            </tr>
                            <tr className="row-bg-none">
                              <td>Miniumum Threshold</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td className="text-right">{order.orderBreakDownItems && order?.orderBreakDownItems?.isminimumPrice ? "Yes" : "No"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      </section>
                    <section className="total__order-amount">
                      <div className="total__order-amount-inner">
                        <div className="left-section">
                          <h1 className="title">Total Order Amount</h1>
                        </div>
                        <div className="right-section">
                          <ul className="list">
                            {order?.orderBreakDownItems?.isCapOutPrice &&
                              <li className="items">
                                <span>Cap Out Total</span>
                                <span className={order?.orderBreakDownItems?.isminimumPrice ? 'bold-title line-through' : "bold-title"} >${this.numberWithCommas(Number(order.orderBreakDownItems && order?.orderBreakDownItems?.totalCapoutPrice))}</span>
                              </li>
                            }
                            {order?.isEnvironmentalLoadFee &&
                              <li className='items'>
                                <span>Environmental Load Fee</span>
                                <span className={"bold-title"}>
                                  ${this.numberWithCommas(Number(order?.environmentalLoadFeeAmount))}
                                </span>
                              </li>
                            }
                            {order?.isFuelSurchardeFee &&
                              <li className='items'>
                                <span>Fuel Surcharge Fee</span>
                                <span className={"bold-title"}>
                                  ${this.numberWithCommas(Number(order?.fuelSurchardeFeeAmount))}
                                </span>
                              </li>
                            }
                            {this.state.user?.company?.chargeStripeCardFees && Number((order?.stripeCardFees)) > 0 &&
                              <li className='items'>
                                <span>Handling Fee</span>
                                <span className={"bold-title"}>
                                  ${this.numberWithCommas(Number(order?.stripeCardFees))}
                                </span>
                              </li>
                            }
                             {order?.isSalesTax && order?.salesTaxFeeAmount > 0 &&
                              <li className='items'>
                                <span>Sales Tax</span>
                                <span className={"bold-title"}>
                                  ${this.numberWithCommas(Number(order?.salesTaxFeeAmount))}
                                </span>
                              </li>
                            }
                            <li className="items">
                            <span>Original Amount</span>
                              <span className={order?.orderBreakDownItems?.isCapOutPrice ? "bold-title line-through" : order?.orderBreakDownItems?.isminimumPrice ? "bold-title line-through" : "bold-title"}>${Number(order?.orderBreakDownItems?.totalOrderAmout) > 0 ?
                                this.numberWithCommas(Number(order.orderBreakDownItems && order?.orderBreakDownItems?.totalOrderAmout)) : "0"}</span>
                            </li>
                            <li className="items">
                            <span>Amount Due </span>
                            <span className="bold-title">${this.numberWithCommas(Number(order.orderBreakDownItems && order?.orderBreakDownItems?.totalAmountDue))}</span>
                            </li>
                            </ul>
                        </div>
                      </div>
                    </section>
                    <section className="not__section">
                      <div className="not__header">
                        <h1 className="title">Notes</h1>
                        <button onClick={() => {
                          this.setState({ showEditor: true, notes: this.state.saveNotes })
                        }} className="btn bg-yellow">{this.state.saveNotes === "" ? "Add Note" : "Edit Note"}</button>
                      </div>
                      <div className="not__body">
                        {this.state.showEditor &&
                          <div className="notes__default col-md-12">
                            <div className="notes__default-inner">
                              <textarea
                                name="notes"
                                className="notes__default-input"
                                onChange={(e) => { this.setState({ notes: e.target.value }) }}
                                value={this.state.notes}
                              ></textarea>
                            </div>
                            <div className="btn-grp">
                              <button
                                type="button"
                                disabled={this.state.notes === "" ? true : false}
                                className="btn btn-dark btn-sm"
                                onClick={this.saveNotes.bind(this, order._id)}
                              >
                                Save Note
                              </button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-gray btn-sm"
                                onClick={() => { this.setState({ showEditor: false }) }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        }
                        {!this.state.showEditor && this.state.saveNotes && <div className="note__body"> <p>{this.state.saveNotes}</p></div>}
                      </div>
                    </section>
                    <section className="diversion__section">
                      <div className="diversion__section-header">
                        <div className="diversion__section-header-inner d-block">
                        <div className="row">
                          <div className="col-md-7 col-sm-7 col-xs-7 col-xl-7">
                            <h1 className="title">LEED/Diversion %</h1>
                            <p>{this.state.sustainabilityInfo?.address}</p>
                          </div>
                          <div className="col-md-5 col-sm-5 col-xs-5 col-xl-5">
                            <button onClick={() => { this.setState({ ismodelopen: false, isSustainabilityInformationModelOpen: true }) }} className="btn bg-yellow edit-information float-right "> {this.state.sustainabilityInfo?.selectedFields?.length > 0 || this.state.sustainabilityInfo?.address !== "" || this.state.sustainabilityInfo?.displayName !== "" || this.state.sustainabilityInfo?.containersize !== "" ? "Update Sustainability Information" : "Add Sustainability Information"}</button>
                          </div>
                          </div>
                        </div>
                        </div>
                      <div className="diversion__section-body">
                        {this.state.sustainabilityInfo?.selectedFields?.length > 0 && this.state.sustainabilityInfo?.tonnagepercentage.map((item, index) =>
                          <ul className="list">
                            {item.value > 0 && <>
                              <li className="items">
                                <h3 className="title">{item.key} % (yards)</h3>
                                <h4 className="sub-title">{this.state.sustainabilityInfo[`${item.key}`]}</h4>
                              </li>
                              <li className="items">
                                <h3 className="title">{item.key} % (tons)</h3>
                                <h4 className="sub-title">{Math.trunc(item.value)}</h4>
                              </li>
                              <li className="items">
                                <h3 className="title">{item.key} Weight (tons)</h3>
                                <h4 className="sub-title">{this.state.sustainabilityInfo?.tonnageweight[index].value}</h4>
                              </li>
                            </>}
                          </ul>
                        )}
                      </div>
                    </section>
                    {this.state.sustainabilityInfo?.selectedFields?.length > 0 &&
                      <section className="recycling__section">
                        <div className="recycling__section-inner">
                          <ul className="list">
                            <li className="items">
                              <span className="title">Recycling %</span>
                              <span className="text">{Math.round(this.state.sustainabilityInfo?.recyclingpercentage) ? Math.round(this.state.sustainabilityInfo?.recyclingpercentage) : 0}%</span>
                            </li>
                            <li className="items">
                              <span className="title">Waste %</span>
                              <span className="text">{Math.round(this.state.sustainabilityInfo?.residualpercentage) ? Math.round(this.state.sustainabilityInfo?.residualpercentage) : 0}%</span>
                            </li>
                          </ul>
                        </div>
                      </section>
                       }
                       {this.state.orderLogs.length > 0 && this.state.orderLogs.map((item, index) => {
                         return (
                           <section key={index} className="order__log-section">
                             <div className="order__log-inner">
                               <div className="left__section">
                                 <h3 className="title">Order Log</h3>
                                 <p>This order was created by {order?.user?.firstName + ""} {order?.user?.lastName} </p>
                               </div>
                               <div className="title-date">
                                 {`${moment(item.createdAt)?.tz(this.state?.tz)?.format("MM/DD/YY")} at  ${moment(item.createdAt)?.tz(this.state?.tz)?.format(
                                   "hh:mm a"
                                 )}`}
                               </div>
                             </div>
                           </section>
                         )
                       })
                      }
                      {/* <section className="delete__order-section">
                    <div className="delete__order-inner d-flex justify-content-center">
                      <button onClick={() => this.setState({ isDeleteModal: true,softDelete:true })} className="delete-btn">Void</button>
                    </div>
                  </section> */}
                    {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.voidOrders === true)? 
             <div className="page-footer-btn-grp mb-4 d-flex align-items-center justify-content-center">
                      {!this?.state?.order?.isDeleted &&(
                      <button onClick={() => this.setState({ isDeleteModal: true, softDelete: true })}
                        className="btn btn-sm btn-red btn-delete mx-2- d-flex align-items-center justify-content-center"
                      >
                       <DeleteIcon />&nbsp;Void
                      </button>
                      )}
                    </div>:null}
                  </div>
                </div>
                {this.state.isDeleteModal &&
                  <DeleteOrderModal
                    isDeleteModal={this.state.isDeleteModal}
                    closeModal={() => this.setState({ isDeleteModal: false, softDelete: false })}
                    deleteOrder={this.deleteOrder}
                    softDelete={this.state.softDelete}
                    progressBar={progressBar}
                    hasPaymentReceipt={this.state?.hasPaymentReceipt}
                    is_paid_order_message={order?.completed}
                  />}
              </ReactModal>}
            <div>
              {this.state.isSustainabilityInformationModelOpen &&
                <SustainabilityInformationComponent
                  sustainabilityInformationComponent={this.state.sustainabilityInfo ? this.state.sustainabilityInfo : {}}
                  isSustainabilityInformationModelOpen={this.state.isSustainabilityInformationModelOpen}
                  onCloseClick={this.onCloseClick}
                  assignSustainabilityInfo={this.assignSustainabilityInfo}
                />
              }
            </div>

            </main>
        </div>
        {this.state.showModal && <EmailAlert
          isClose={this.state?.showModal}
          showEmailAlert={this.state.showModal}
          onCloseAlert={() => {
            this.closeModalAlert()
          }}
         />}
         { this.state.isCityOfOriginModalOpen &&
          <CityOfOrigin
            companyId={order?.companyId}
            cities={this.state.citiesOfOrigin}
            isOpen={this.state.isCityOfOriginModalOpen}
            updateCompany={this.props.updateCompany}
            assignCityOfOrigin={(cityOfOrigin) => this.setState({cityOfOrigin})}
            closeModal={(cityOfOrigin) => this.setState({
              isCityOfOriginModalOpen: !this.state.isCityOfOriginModalOpen
            })}
            {...this.props}
          />
        }
      </>
    );
  }
}
