import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { 
  BackArrowIcon,
} from '../../components/icons'
import moment from "moment";
import _ from "lodash";
import './styles.scss'
import { numberFormat } from "../../constants/common";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class CreditCardItemizedComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      transaction: null,
      inFlowDetails: null,
      closingBalance: 0,
      date: this.props?.match?.params?.date ? moment(this.props?.match?.params?.date).format("MMM DD, YYYY") : moment().format("MMM DD, YYYY"),
      spinning: true
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    const {
      match: {
        params: { date }
      }
    } = this.props;
    const {
      location: {
        search
      }
    } = this.props;
    const userId = search.split("=")[1];
    const startDate = moment(date).startOf("day").toISOString();
    const endDate = moment(date).endOf("day").toISOString();
    const {
      value: {
        data: { inFlowDetails, closingBalance }
      }
    } = await this.props.fetchTransaction({ startDate, endDate, type: "card", timezone: moment().format("Z"),userId });
    let infDetails =_.get(inFlowDetails, "[0]", [])
    infDetails=infDetails?.data
    if(infDetails?.length > 0){
      for(let i=0;i<infDetails.length;i++){ 
        if(infDetails[i]?.paymentReceiptId){
          const payload={id:infDetails[i]?.paymentReceiptId }
          const {
            value: {
              data
            }
          } = await this.props.getInvoiceDetailsByPrId(payload);
          if(data.invoiceDetail[0]?.invoiceIds?.length >0){
            let orderIds=""
            data.invoiceDetail[0].invoiceIds.map((item,index)=>{
              orderIds += index=== 0 ? item : ","+item
              return null
          })
          infDetails[i].order.ticketNumber=orderIds
        }
        }else if(infDetails[i]?.invoiceId){
          const payload={id:infDetails[i]?.invoiceId }
          const {
            value: {
              data: { invoiceDetail
              }
            }
          } = await this.props.getInvoiceDetailsById(payload);
          if(invoiceDetail[0]?.invoiceId){
          infDetails[i].order.ticketNumber=invoiceDetail[0]?.invoiceId
          }
        }
      }
    }
    this.setState({
      inFlowDetails: _.get(inFlowDetails, "[0]", null),
      closingBalance,
      date: moment(date).format("MMM DD, YYYY"),
      spinning: false
    });
  };

  render() {
    const { inFlowDetails, date } = this.state;
    return (
      <div className="creditcard__itemized-wrapper invoce__table">
        <div className="custome__container">
          <div className="btn-back-header">
            <button className="btn btn-link btn-back">
              <Link to={"/billing/credit-card-transactions/tranasactions"}>
                <BackArrowIcon />
                Back
              </Link>
              <span>{date} Credit Card Transactions </span>
            </button>
          </div>
          <Spin indicator={antIcon} spinning={this.state.spinning}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="cashlog__details-invoice">
                    <div className="invoice__heading">Cash Debit (inflow)</div>
                    <ul className="transactions__list">
                      {_.get(inFlowDetails, "data", []).map(transaction => {
                        console.log("transaction", transaction);
                        return (
                          <li key={transaction._id}>
                            <div>
                              <h5 className="transactions__title">
                                {_.get(transaction, "order.customer.displayName", "N/A")}
                              </h5>
                              <p className="transactions__sub-title">
                                {_.get(transaction, "order.ticketNumber", "N/A")} | Processed by{" "} 
                                {_.startCase(`${transaction?.user?.firstName} ${transaction?.user?.lastName}`)}{" "}(Transaction ID: {transaction?.paymentData?.SaleResponse?.transactionID || transaction?.paymentData?.sale_response?.transactionID})
                              </p>
                            </div>
                            <div className="invoice__right">
                              <div className="text-amount text-black">+${numberFormat(transaction.amount)}</div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="total__credit">
                      Total Transactions
                      <div className="text-amount text-green">
                        +${numberFormat(_.get(inFlowDetails, "totalAmount", 0))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Spin>
        </div>
      </div>
    );
  }
}
