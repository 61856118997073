import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import moment from "moment";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import Dashboard from "../views/Dashboard/container";
import Scale from "../views/Scale/container";
import CreateOrder from "../views/CreateOrder/container";
import PendingOrderDetails from "../views/PendingOrderDetails/container";
import Orders from "../views/Orders/container";
import CompletedOrderDetails from "../views/CompletedOrderDetails/container";
import CustomerDetail from "../views/CustomerDetail/container";
import Customers from "../views/Customers/container";
import CreateCustomer from "../views/CreateCustomer/container";
import EditCustomer from "../views/EditCustomer/container";
import DumpRates from "../views/DumpRates/container";
import UserManagment from "../views/UserManagment/container";
import UserDetails from "../views/UserDetails/container";
import Resources from "../views/Resources/container";
import UpdateProfile from "../views/UpdateProfile/container";
import Settings from "../views/Settings/container";
import CashLogBalance from "../views/CashLogBalance/container";
import CreditCardTransactions from "../views/CreditCardTransactions/container";
import OutboundActivity from "../views/OutboundActivity/container";
import OutboundActivityMaterialsContainer from "../views/OutboundMaterials/container";
import Reports from "../views/ReportsDashboard/container";
import CashLogDetails from "../views/CashLogDetails/container";
import CreditCardItemized from "../views/CreditCardItemized/container";
import CompanyListing from "../views/CompanyListing/container";
import CompanyUserList from "../views/CompanyUserList/container";
import SustainabilityInformation from "../views/SustainabilityInformation/container";
import Terminal from "../views/Terminal/container";
import InactivityLogout from "../views/InactivityLogout/container";
import Login from "../views/Login/container";
import Signup from "../views/Signup/container";
import NoRecords from "../views/NoRecords/container";
import Receipt from "../views/Receipt/container";
import QuickBooks from "../views/QuickBooks/container";
import CustomerMapping from "../views/CustomerMapping/container";
import StripeSettings from "../views/StripeSettings/container";
import QuickBooksConnect from "../views/QuickbooksConnect/component";
import WorkReport from "../views/WorkReport/container";
import DisableQuickbooks from "../views/DisableQuickbooks/container";
import LicenseAgreement from "../views/LicenseAgreement/container";
import PrivacyPolicy from "../views/PrivacyPolicy/container";
import Integrations from "../views/Integrations/container";
import CardknoxInfo from "../views/CardknoxInfo/container";
import BusinessAdmin from "../views/BusinessAdmin/container";
import ChargeByYard from "../views/ChargeByYard/container";
import OrderReport from "../views/Reports/container";
import ProjectOrderReport from "../views/ProjectReports/container";
import Billing from "../views/Billing/container";
import ReceivePayment from "../views/ReceivePayment/container";

// import invoiceEachTransaction from "../views/CompletedOrderDetails/partials/invoice-each-transaction";

import ReviewPayInvoice from "../views/ReviewPayInvoice/container";


// import ScaleSetup from "../views/ScaleSetup/container";


import ReactModal from "react-modal";
import { Formik, ErrorMessage } from "formik";
import { depositSchema } from "../utils/validations";
import IdleTimer from "react-idle-timer";
import NumberFormat from "react-number-format";

import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";

import LogRocket from "logrocket";
import CreditCardPayoutDetailsContainer from "../views/CreditCardPayoutDetails/container";
// import CreateEditCompanyInfoCustomer from "../views/PricingTierCreateEdit/container";
// import PrintTicketInvoiceComponent from '../views/CompletedOrderDetails/partials/invoice-each-transaction'
import PrintTicketInvoiceComponent from '../views/InvoiceEachTransaction/container'
import PrintBillingReceipt from '../views/PrintBillingReceipt/container'
import CreateStatementContainer from "../views/CreateStatement/container";
import PrintOrderDetailsContainer from "../views/PrintOrderDetails/container";

const PrivateRoute = ({ component, ...rest }) => {
  const user = window.localStorage.getItem("Authorization");
  const isAuthed = user ? true : false;
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        isAuthed ? (
          <div>{React.createElement(component, props)}</div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      isLoading: true,
      user: null,
      modalOpen: false,
      registerInitialValues: {
        amount: "",
      },
      iAuthorization: localStorage.getItem("i_Authorization"),
    };
  }

  componentDidMount() {
    if(this.props.location && this.props.location.pathname !== "/review-pay-invoice"){
      this.fetchMe();
    }

  }

  fetchMe = async () => {
    const { fetchMe } = this.props;
    const {
      value: { user },
    } = await fetchMe();
    this.setState({ user, modalOpen: !user?.isRegisterBalance });
  };

  async handleSubmit({ amount }, type, reason, { setSubmitting }) {
    const { saveAmount, updateCompany } = this.props;
    const { user } = this.state;
    const {
      value: { status },
    } = await saveAmount({
      amount: parseFloat(String(amount).replace(/,/g, "")),
      type,
    });
    
    if (status) {
      if (reason === "register") {
        await updateCompany({
          isRegisterBalance: true,
          companyId: user.companyId,
        });
      }
      setSubmitting(false);
      this.fetchMe();
    }
  }

  componentDidUpdate({ location: { pathname } }) {
    if (
      (pathname === "/login" &&
        this.props.location.pathname === "/dashboard") ||
      (pathname === "/business-settings" &&
        this.props.location.pathname === "/dashboard")
    ) {
      this.fetchMe();
    }
    const path = this.props.location.pathname.split("/")
    if(path[1] !== "posted" && path[2] !== "customer-detail" && path[1] !== "billing" && path[2] !== "posted-transactions" && path[2] !== "finalized-transactions"){
      sessionStorage.removeItem("posted-pagination")
      sessionStorage.removeItem("finalized-pagination")
    }
    else if(path[1] !== "posted" && path[2] !== "customer-detail" && path[1] === "billing" && path[2] === "finalized-transactions"){
      sessionStorage.removeItem("posted-pagination")
    }
    else if(path[1] !== "posted" && path[2] !== "customer-detail" && path[1] === "billing" && path[2] === "posted-transactions"){
      sessionStorage.removeItem("finalized-pagination")
    }
    else if(path[1] !== "posted" && path[2] !== "customer-detail" && path[1] === "billing" && path[2] !== "finalized-transactions" && path[2] !== "posted-transactions" ){
      sessionStorage.removeItem("posted-pagination")
      sessionStorage.removeItem("finalized-pagination")
    }
    if(path[1] !== "order-details"){
      sessionStorage.removeItem("current-page-complete-orders")
    }
  }

  renderPopup() {
    return ReactDOM.createPortal(
      <ReactModal
        isOpen={this.state.modalOpen}
        onRequestClose={this.closeModal}
        contentLabel="Please Confirm"
        ariaHideApp={false}
      >
        <div className="react-modal-dialog react-modal-dialog-centered common__confirmtion-popu">
          <div className="react-modal-body">
            <h5 className="react-modal-title">Enter Cash Register Balance</h5>
            <p>
              Enter the amount you have in the cash register so the system can
              start keeping a record of cash at all times.
            </p>
            {/* <h3 className="amout__title">$2,000</h3> */}
            <Formik
              enableReinitialize
              initialValues={this.state.registerInitialValues}
              validationSchema={depositSchema}
              onSubmit={(values, formikProps) => {
                this.handleSubmit(
                  values,
                  "openBalance",
                  "register",
                  formikProps
                );
              }}
            >
              {({ values, handleSubmit, isSubmitting, setValues }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <NumberFormat
                          name="amount"
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"input"}
                          type="text"
                          className="form-control material-textfield-input"
                          onValueChange={(_values) => {
                            const { formattedValue } = _values;
                            const amount = formattedValue;
                            setValues({
                              ...values,
                              amount,
                            });
                          }}
                          value={values.amount}
                          required
                        />
                        <ErrorMessage
                          component="span"
                          name="amount"
                          className="invalid-feedback d-block"
                        />
                        <label className="material-textfield-label">
                          Enter Amount<span>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-dark btn-lg w-100"
                  >
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>,
      document.getElementById("modalPopup")
    );
  }

  onIdle = () => {
    localStorage.clear();
    this.props.history.push("/inactivitylogout");
  };

  render() {
    const { user } = this.state;
    if (
      user &&
      process.env.REACT_APP_LOGROCKET &&
      process.env.REACT_APP_HOSTNAME
    ) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET, {
        rootHostname: process.env.REACT_APP_HOSTNAME,
      });
      LogRocket.identify(
        _.get(user, "_id", moment().format("YYYYMMDDHHMMSS")),
        {
          name: `${_.get(user, "firstName", "")}${_.get(user, "lastName", "")}`,
          email: `${_.get(user, "email", "")}`,
          phone: `${_.get(user, "phone", "")}`,
          country: `${_.get(user, "country", "")}`,
          state: `${_.get(user, "state", "")}`,
          city: `${_.get(user, "city", "")}`,
        }
      );
    }
    if (user && user.role === "supervisor" && !user.isRegisterBalance) {
      return this.renderPopup();
    } else {
     const siderbarOpenClose = localStorage.getItem("sideBarOpenClose")
      return (
        <>
          {/* <Loader isLoading={isLoading} /> */}
          <div className={this.state.iAuthorization ? siderbarOpenClose ? `application-container impersonate__one ${siderbarOpenClose}` : "application-container impersonate__one" : siderbarOpenClose ? `application-container ${siderbarOpenClose}` : "application-container" }>
            {user && (
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                onIdle={this.onIdle}
                debounce={250}
                timeout={60000 * user.autoLogoutDuration}
              />
            )}
            <Switch>
            
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/scale"
                component={Scale}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/assign-inbound-weight"
                component={CreateOrder}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/pending-order-details/:id"
                component={PendingOrderDetails}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/failed-payments"
                component={Orders}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/queued-for-drivers-signature"
                component={Orders}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/completed-orders"
                component={Orders}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/pending-payments"
                component={Orders}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/order-details/:id"
                component={CompletedOrderDetails}
                {...this.props}
              />
              {/* <PrivateRoute
                exact
                path="/invoice-each-transaction"
                component={invoiceEachTransaction}
                {...this.props}
              /> */}
              <PrivateRoute
                exact
                path="/customers"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id"
                component={CustomerDetail}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id"
                component={CustomerDetail}
                {...this.props}
              />
   {/* ==============CustomerDetail Detail popup Routes============= */}

              <PrivateRoute
                exact
                path="/customer-detail/:id/general"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id/past-weigh-ins"
                component={Customers}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/customer-detail/:id/billing/invoice"
                component={Customers}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/customer-detail/:id/billing/payment-receipt"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id/billing/credit-memo"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id/rates"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id/drivers"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id/projects"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id/trucks"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id/payment-methods"
                component={Customers}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/customer-detail/:id/history"
                component={Customers}
                {...this.props}
              />

{/* ============== Billing  customer CustomerDetail Detail popup Routes============= */}

                <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/general"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/billing/invoice"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/billing/payment-receipt"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/billing/credit-memo"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/past-weigh-ins"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/projects"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/rates"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/drivers"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/trucks"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/payment-methods"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/billing/customer/customer-detail/:id/history"
                component={Billing}
                {...this.props}
              />

    {/* ============== Billing need Review CustomerDetail Detail popup Routes============= */}
    
             <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/general"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/billing/invoice"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/billing/payment-receipt"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/billing/credit-memo"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/past-weigh-ins"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/projects"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/rates"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/drivers"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/trucks"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/payment-methods"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/needReview/customer-detail/:id/history"
                component={Billing}
                {...this.props}
              />

{/* ============== Billing Posted CustomerDetail Detail popup Routes============= */}

             <PrivateRoute
                exact
                path="/posted/customer-detail/:id/general"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/posted/customer-detail/:id/billing/invoice"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/posted/customer-detail/:id/billing/payment-receipt"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/posted/customer-detail/:id/billing/credit-memo"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/posted/customer-detail/:id/past-weigh-ins"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/posted/customer-detail/:id/rates"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/posted/customer-detail/:id/drivers"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/posted/customer-detail/:id/trucks"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/posted/customer-detail/:id/projects"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/posted/customer-detail/:id/history"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/posted/customer-detail/:id/payment-methods"
                component={Billing}
                {...this.props}
              />

{/* ============== Dashboard CustomerDetail Detail popup Routes============= */}

             <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/general"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/billing/credit-memo"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/billing/payment-receipt"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/billing/invoice"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/past-weigh-ins"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/projects"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/rates"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/drivers"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/trucks"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/payment-methods"
                component={Dashboard}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/dashboard/customer-detail/:id/history"
                component={Dashboard}
                {...this.props}
              />

{/* ============== Billing finalized CustomerDetail Detail popup Routes============= */}

             <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/general"
                component={Billing}
                {...this.props}
              />
            <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/billing/invoice"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/billing/payment-receipt"
                component={Billing}
                {...this.props}
              />
               <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/billing/credit-memo"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/past-weigh-ins"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/projects"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/rates"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/drivers"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/trucks"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/payment-methods"
                component={Billing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/finalized/customer-detail/:id/history"
                component={Billing}
                {...this.props}
              />


              <PrivateRoute
                exact
                path="/customer"
                component={CreateCustomer}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/edit-customer"
                component={EditCustomer}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/dump-rates"
                component={DumpRates}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/user-management"
                component={UserManagment}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/user-detail"
                component={UserDetails}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/facilities"
                component={Resources}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/third-party-haulers"
                component={Resources}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/in-house-haulers"
                component={Resources}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/profile"
                component={UpdateProfile}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/business-settings"
                component={Settings}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/cash-transactions"
                component={CashLogBalance}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/credit-card-transactions"
                component={CreditCardTransactions}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/outbound-activity-active"
                component={OutboundActivity}
                {...this.props}
              />
                <PrivateRoute
                exact
                path="/outbound-materials"
                component={OutboundActivityMaterialsContainer}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/reports"
                component={Reports}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/itemized-cash-transactions/:date"
                component={CashLogDetails}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/stripe-settings"
                component={StripeSettings}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/client-report"
                component={WorkReport}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/material-report"
                component={WorkReport}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/cash-descrepancy-report"
                component={WorkReport}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/deleted-tickets-report"
                component={WorkReport}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/itemized-credit-card-transactions/:date"
                component={CreditCardItemized}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/companylisting"
                component={CompanyListing}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/user-list"
                component={CompanyUserList}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/outbound-activity-completed"
                component={OutboundActivity}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/sustainability-information"
                component={SustainabilityInformation}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/terminal"
                component={Terminal}
                {...this.props}
              />
              <Route
                exact
                path="/license-agreement"
                component={LicenseAgreement}
                {...this.props}
              />
              <Route
                exact
                path="/privacy-policy"
                component={PrivacyPolicy}
                {...this.props}
              />
              <Route
                exact
                path="/inactivitylogout"
                component={InactivityLogout}
                {...this.props}
              />
              <Route exact path="/login" component={Login} {...this.props} />
              <Route exact path="/signup" component={Signup} {...this.props} />
              <Route
                exact
                path="/no-records"
                component={NoRecords}
                {...this.props}
              />
              <Route
                exact
                path="/receipt/:id"
                component={Receipt}
                {...this.props}
              />
              <Route
                exact
                path="/quickbooks"
                component={QuickBooks}
                {...this.props}
              />
              <Route
                exact
                path="/customer-mapping"
                component={CustomerMapping}
                {...this.props}
              />
              <Route
                exact
                path="/disable-quickbooks"
                component={DisableQuickbooks}
                {...this.props}
              />
              <Route
                exact
                path="/quickbooks-connect"
                component={QuickBooksConnect}
                {...this.props}
              />
              <Route
                exact
                path="/integrations"
                component={Integrations}
                {...this.props}
              />
              <Route
                exact
                path="/cardknox_info"
                component={CardknoxInfo}
                {...this.props}
              />

              <Route
                exact
                path="/business-admin/scales"
                component={BusinessAdmin}
                {...this.props}
              />
              <Route
                exact
                path="/business-admin/business-settings"
                component={BusinessAdmin}
                {...this.props}
              />
              <Route
                exact
                path="/business-admin/edit-business-settings"
                component={BusinessAdmin}
                {...this.props}
              />
              <Route
                exact
                path="/add-scale-setting"
                component={BusinessAdmin}
                {...this.props}
              />
              <Route
                exact
                path="/edit-scale-setting/:id"
                component={BusinessAdmin}
                {...this.props}
              />
              <Route
                exact
                path="/business-admin/pricing-tier"
                component={BusinessAdmin}
                {...this.props}
              />
              <Route
                exact
                path="/add-pricing-tier"
                component={BusinessAdmin}
                {...this.props}
              />
              <Route
                exact
                path="/edit-pricing-tier/:id"
                component={BusinessAdmin}
                {...this.props}
              />

              <Route
                exact
                path="/customer-pricing-tier/:id"
                component={BusinessAdmin}
                {...this.props}
              />

              <Route
                exact
                path="/create-charge"
                component={ChargeByYard}
                {...this.props}
              />

              <Route
                exact
                path="/create-charge/:orderId"
                component={ChargeByYard}
                {...this.props}
              />

              <Route
                exact
                path="/reports/orders"
                component={OrderReport}
                {...this.props}
              />
              <Route
                exact
                path="/reports/orders/projects"
                component={ProjectOrderReport}
                {...this.props}
              />
               <Route
                exact
                path="/business-admin/integrations"
                component={BusinessAdmin}
                {...this.props}
                />
              {/* <Route
                exact
                path="/credit-crd-payout-details"
                component={CreditCardPayoutDetails}
                {...this.props}
              />
              {/*=================== Billing Routes ================= */}

              <Route
                exact
                path="/billing"
                component={Billing}
                {...this.props}
              />
             
              <Route
                exact
                path="/billing/needs-review"
                component={Billing}
                {...this.props}
              />
              <Route
                exact
                path="/billing/posted-transactions"
                component={Billing}
                {...this.props}
              />
              <Route
                exact
                path="/billing/finalized-transactions"
                component={Billing}
                {...this.props}
              />
              <Route
                exact
                path="/billing/cash-transactions"
                component={Billing}
                {...this.props}
              />
              <Route
                exact
                path="/billing/credit-card-transactions"
                component={Billing}
                {...this.props}
              />
               <Route
                exact
                path="/billing/credit-card-transactions/tranasactions"
                component={Billing}
                {...this.props}
              />
               <Route
                exact
                path="/billing/credit-card-transactions/payouts"
                component={Billing}
                {...this.props}
              />
              <Route
                exact
                path="/billing/credit-card-transactions/payouts/details"
                component={CreditCardPayoutDetailsContainer}
                {...this.props}
              />
              <Route
                exact
                path="/billing/customers"
                component={Billing}
                {...this.props}
              />
          
              <Route
                exact
                path="/:name/receive-payment/:id"
                component={ReceivePayment}
                {...this.props}
              />
              <Route
                exact
                path="/create-statement/:id"
                component={CreateStatementContainer}
                {...this.props}
              />

              {/* <Route
                exact
                path="/print-ticket-invoice"
                component={PrintTicketInvoiceComponent}
                {...this.props}
              /> */}
              <Route
                exact
                path="/review-pay-invoice"
                component={ReviewPayInvoice}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/print-ticket-invoice/:id"
                component={PrintTicketInvoiceComponent}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/print-order-details/:id"
                component={PrintOrderDetailsContainer}
                {...this.props}
              />
              <PrivateRoute
                exact
                path="/billing-receipt/:id"
                component={PrintBillingReceipt}
                {...this.props}
              />
              
            </Switch>
          </div>
        </>
      );
    }
  }
}

export default withRouter(withCookies(AppComponent));
