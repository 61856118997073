import React, { PureComponent } from "react";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import moment from "moment-timezone";
import _ from "lodash";
import { isEmpty } from 'lodash'
import NumberFormat from "react-number-format";
import { BackArrowIcon, EmailIcon, DeleteIcon, CloseIcon, TakePictureIcon } from "../../components/icons";
// import ErrorFocus from "../../utils/error-focus";
import { Drawer, Spin, message } from "antd";
import deleteIcon from "../../images/delete-icon.svg";
import "./styles.scss";
import { Formik, ErrorMessage } from "formik";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import { driverSchema, pendingOrderSchema1 } from "../../utils/validations";
import {customStartCase, numberFormat, numberFormatLbs,truckTypes } from "../../constants/common";
import LightBox from "../../utils/lightbox";
import DeleteOrderModal from "./partials/delete-order";
import AddCardComponent from "../payabli/addNewCard";
import PayEngineCardModal from "./partials/credit-card-payengine-modal";
import { LoadingOutlined } from '@ant-design/icons';
import { Switch } from "antd";
import CreateProject from "../CreateOrder/partials/create-projects";
import CreateDriver from "../CreateOrder/partials/create-driver";
import NewDriver from "../CreateOrder/partials/new-driver";
import SignaturePad from "../SignaturePad/container";
import PlacesAutocomplete from "../../components/forms/PlaceSearchBox";
import { DebounceInput } from "react-debounce-input";
import CityOfOrigin from "../BusinessAdmin/partials/CityOfOriginModal";
import { responseCode } from "../../utils/responseCode";

const timezone = moment().format("Z");
let interval;

// const fees = {
//   USD: { Percent: 2.9, Fixed: 0.3 },
//   GBP: { Percent: 2.4, Fixed: 0.2 },
//   EUR: { Percent: 2.4, Fixed: 0.24 },
//   CAD: { Percent: 2.9, Fixed: 0.3 },
//   AUD: { Percent: 2.9, Fixed: 0.3 },
//   NOK: { Percent: 2.9, Fixed: 2 },
//   DKK: { Percent: 2.9, Fixed: 1.8 },
//   SEK: { Percent: 2.9, Fixed: 1.8 },
//   JPY: { Percent: 3.6, Fixed: 0 },
//   MXN: { Percent: 3.6, Fixed: 3 },
// };

const limit = 100;
const antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;


export default class PendingOrderDetailsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);
    const local = window.localStorage.getItem("weightData")
      ? JSON.parse(window.localStorage.getItem("weightData"))
      : {};
    this.state = {
      citiesOfOrigin: [],
      isCityOfOriginModalOpen: false,
      signErrorMessage: "",
      driverSignature: "",
      order: {},
      dumpRate: "",
      isChargeCard: false,
      isChargeCardCustomer: false,
      buttonText: "Complete Order",
      selectPaymentMethod: "",
      isCardModalOpen: false,
      outboundWeight: local.weight,
      outboundWeightDate: local.date,
      showYardageValue: false,
      saveAsDefaultOutboundWeight: false,
      projectSpinning: false,
      amount: 0,
      cardId: "",
      rotation: 0,
      brand: "",
      last4: "",
      enableTerminalButton: false,
      dumpRateType: "",
      initialValues: {
        completed: false,
        isPaymentDone: false,
        isPaymentLater: false,
        dumpRate: "",
        dumpRateSalesTax:false,
        yardage: '',
        paymentType: "",
        type: "",
        defaultOutboundWeight: local.weight ?? "",
        containerSize: "",
        containers: {},
        truckType: ""
      },
      connectionStatus: "",
      reader: null,
      discoveredReaders: [],
      licenseImageUrl: "",
      isLEEDRequiredForCustomer: false,
      discoverReaders: false,
      showCart: false,
      workFlowInProgress: null,
      chargeAmount: props.totalAmount,
      itemDescription: "Red t-shirt",
      taxAmount: 0,
      currency: "usd",
      isPaymentSuccessfull: false,
      paymentProgress: false,
      selectedReader: null,
      images: [],
      isLightBoxOpen: false,
      user: null,
      orderLogs: [],
      isError: false,
      errorMessage: "",
      notes: "",
      progressBar: false,
      isDeleteModal: false,
      emailProgress: false,
      emailBtnTxt: "Email Receipt",
      cityOfOrigin: "",
      additionalItems: [],
      showEditor: false,
      selectedAdditionalItem: "",
      devices: [],
      selectedDevice: "",
      fuelSurchargeTotal: 0,
      paybliEnable: false,
      isFailedTransaction: false,
      customerData: {},
      cardAdded: true,
      customerPayabliData: {
        StoredMethods: [],
      },
      selectedCardData: {},
      pricingTierDumpRates: {},
      additionalItemsData: [],
      capoutPrice: 0,
      minimumPrice: 0,
      isCapoutPrice: false,
      pricingTierName: "",
      payMethod: "",
      pricingTierId: "",
      paybliDetails: {},
      signatureUrl: "",
      selectedReadingType:"",
      terminalStatus: false,
      isPaymentLater: false,
      is_scale_page_pre: false,
      collectPaymentLater:true,
      tz,
      submittingForm:false,
      loader:false,
      serviceLocation:"",
      customerRefrence:"",
      customers:[],
      companySearchText:"",
      sortOrder: { companyName: 1 },
      projectsData:[],
      dueBalance: 0,
      customer:{},
      projectSearchText:"",
      errorMessageoutobound:"",
      errorMessageProject:"",
      isSalesTax : false,
      saleTaxFeeAmount : "",
      saleTaxPercentageForOrder : "",
      errisSalesTax : "",
      driverSearchText:"",
      truckTab:1,
      isCameraOn: false,
      isCameraOnLoad: false,
      plateImageUrl:"",
      loadImageUrl:"",
      requiredFields: ["customer"],
      containersFromInput:[],
      truckSearchText:"",
      selectedCustomer:{},
      usingDefault:local.usingDefault ?? false,
      een_data:[],
      disabledFuelSwitch: false,
      serviceAddress: false,
      selectedSplitPaymentMethods: [],
      splitPayments: [
        {
          paymentMethod: "credit-card",
          paymentAmount: null,
          isSelectedPayment: false,
          uniqueId:null,
          handlingFee: null
        },
        {
          paymentMethod: "cash",
          paymentAmount: null,
          isSelectedPayment: false
        },
        {
          paymentMethod: "check",
          paymentAmount: null,
          isSelectedPayment: false
        },
        {
          paymentMethod: "credit-memo",
          paymentAmount: null,
          isSelectedPayment: false,
          creditMemoIds: []
        },
        {
          paymentMethod: "terminal",
          paymentAmount: null,
          uniqueId:null,
          isSelectedPayment: false,
          handlingFee: null
        }
      ],
      driversLoader: false,
      remaingSplitAmount: null,
      availableSplitAmount: null,
      availableOrderAmount: 0,
      totalAmount: null,
      itemsDisabled: false,
      isTerminalLoader: false,
      isRoundOffAmount: false,
      storedOutboundWeight: null,
      isPayabliIntegration: false,
      customerCreditsMemos: [],
      customerCreditCards : [],
      totalcreditmemosAmount: null,
      creditMemoEnabled: true,
      selectedCreditMemo: [],
      totalSelectedAmount: null,
      remainingCreditAmount: null,
      hideWieghtToggle: false,
      isTerminalPaymentDone: false,
      reRender: true,
      inProgressForCompleteOrder:false,
      responseMessageForTerminal:"",
      splitPaymentTotalHandlingFee:0,
      isChargeCardProcessing:false,
      isChargeTerminalProcess:false,
      isActualRoundOff:false,
      checkNumber:"",
      isSplitPaymentOption:false,
      withOutSplitPaymentValidation:true,
    };
    this.formRef = React.createRef()
    this.creditMemoRef = React.createRef();
  }

  componentDidMount = async () => {
    const { state } = this.props.location;
    const outboundWeightValue = this.state?.outboundWeight;
    this.setState({
      storedOutboundWeight: outboundWeightValue,
    });
    await this.fetchMe();
    this.fetchOrder();
    this.fetchCustomers()
    this.setState({is_scale_page_pre: state?.ScalePage})
    window.scrollTo(0, 0);
    this.client = null;
    this.terminal = null;
    this.state?.isPayabliIntegration && this.getPaybliIntegration();
    this.fetchContainers()
    document.addEventListener('click', this.handleOutsideClick);
  };

  getPaybliIntegration = async () => {
      const {
        value: { status, data },
      } = await this.props.getPaybliIntegration(); 
      // get paybli integration
      if (status) {
        this.setState({ paybliEnable: true, paybliDetails: data});
      }
  };
  handleCheckboxChange = () => {
    this.setState((prevState) => {
      const isSaveAsDefaultOutboundWeight = !prevState.isSaveAsDefaultOutboundWeight;
      const saveAsDefaultOutboundWeight = !prevState.saveAsDefaultOutboundWeight;

      const order = {
        ...prevState.order,
        saveAsDefaultOutboundWeight: saveAsDefaultOutboundWeight
      };
 
      return {
        isSaveAsDefaultOutboundWeight,
        saveAsDefaultOutboundWeight,
        order: order
      };
    });
  };
  
  getPayabliDeviceList = async (companyId) => {};
  fetchCustomerDetail = async (id,driver,project,truck,truckInputs,containerSize,isPaymentLater) => {
    const {
      value: {
        data: { customer },
      },
    } = await this.props.fetchCustomer(id);
    let initialValues = {...this.state.initialValues,customer}
    if(truck){
      const truckWeight = truck?.containers?.find(tr => String(tr.size) === String(containerSize))?.defaultWeight 
    ?? truck?.containers?.find(tr => tr.defaultWeight)?.defaultWeight 
    ?? truck?.defaultOutboundWeight;
      initialValues.truck=truck
      initialValues.defaultOutboundWeight=truckWeight
      initialValues.containerSize= String(containerSize ?? "")
      initialValues.containers=truck?.containers?.find(tr => String(tr.size) === String(containerSize)) ?? {}
    }
    if(project)initialValues.project=project
    if(driver)initialValues.driver=driver
    if(truckInputs){
      const {number,truckType,plateNumber, defaultOutboundWeight,containers, containerCheck} = truckInputs
      initialValues.plateNumber=plateNumber ?? ""
      initialValues.truckType=truckType ?? "Box Truck"
      initialValues.number=number ?? ""
      initialValues.defaultOutboundWeight=defaultOutboundWeight ?? ""
      initialValues.containers=containers ?? {}
      initialValues.containerCheck=containerCheck ?? true
      if(defaultOutboundWeight && defaultOutboundWeight?.length > 0 && this.state.usingDefault){
        this.setState({outboundWeight:defaultOutboundWeight ?? ""})
      }
    }
    this.setState({ customerData: {...customer},customer,
      initialValues,
      selectedCustomer:customer,
      selectedTruck:truck ?? this.state.selectedTruck,
      selectedProject:project ?? this.state.selectedProject,
      selectedDriver:driver ?? this.state.selectedDriver
    },()=>{
      this.forceUpdate()
    });
    this.assignDefaultCustomerDataInPaymentTerms(customer)
    this.fetchCustomerCreditMemo(customer._id)
    this.fetchCustomerCreditCards(customer._id)
    this.fetchDrivers()
    this.fetchProject()
    this.isDelinquentAccount()
    this.fetchTrucks()
    this.getCustomerPayment(customer?.payabliCustomer?.customerId); // get customer payment methods
    if (customer?.isLEEDsRequired) {
      this.setState({ isLEEDRequiredForCustomer: true })
    }
  };
  setAsyncState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  fetchOrder = async (serviceLocation  , customerRefrence) => {
    this.setState({loader:true})
    const is_last_route_sustainability_information = this.props?.history?.location?.state?.last_route === "sustainability-information" 
    const history_props = this.props?.history?.location?.state
    let pricingTier = {};
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const {
      history: {
        location: { state },
      },
      fetchOrder,
    } = this.props;
    const {
      value: { data, orderLogs,status },
    } = await fetchOrder(id);
    let truckData = data?.truck
    let selectedReadingType =data?.selectedReadingType
    let driverData = data?.driver
    let projectsData = data?.projects
    let customerData = data?.customer
    let plateImageUrl = ""
    let loadImageUrl = ""
    let truckInputs = null;
    let isFuelSurchardeFee = data?.customer?.isFuelSurchardeFee
    let isEnvironmentalLoadFee = data?.customer?.isEnvironmentalLoadFee
    let isSalesTax = data?.isSalesTax
    let saleTaxPercentageForOrder = data?.salesTaxPercentageForOrder ?? this.state.saleTaxPercentageForOrder;
    let containerSize = data?.containerSize
    if(state?.customer){
      truckData = state?.truck
      driverData = state?.driver
      projectsData = state?.project
      customerData = state?.customer
      plateImageUrl = state?.plateImageUrl
      loadImageUrl = state?.loadImageUrl
      truckInputs = state?.truckInputs
      isFuelSurchardeFee = state?.isFuelSurchardeFee
      isEnvironmentalLoadFee = state?.isEnvironmentalLoadFee
      isSalesTax = state?.isSalesTax
      saleTaxPercentageForOrder = state?.saleTaxPercentageForOrder
      containerSize = state?.containerSize
    }
    this.fetchCustomerDetail(customerData?.customerId,driverData,projectsData,truckData,truckInputs,containerSize,data?.isPaymentLater);
    if (data && data.completed) {
      this.setState({ notes: data.notes });
    }
    const pricingTierId = customerData?.pricingTier;
    this.setState({ pricingTierId: pricingTierId, loadImageUrl, plateImageUrl, truckTab:state?.truckTab ?? 1 ,isSplitPaymentOption:data?.isSplitPayment ,selectedReadingType});
    let initialValues=this.state.initialValues
    // let initialValues={...this.state.initialValues,driver:data?.driver,project:data?.projects,truck:data?.truck}
    if(data?.additionalItems?.length > 0){
      data.additionalItems.forEach((item)=>{
        initialValues[`${item.name}`] = item.quantity
      })
    this.setState({initialValues:initialValues})
  }

    if (data?.customer?.pricingTier) {
     pricingTier = await this.assignPricingTier(customerData?.pricingTier);
    }
    if (data.isPaymentDone) {
      await this.setAsyncState({
        order: data,
        orderLogs,
        initialValues: {
          ...this.state.initialValues,
          dumpRate: is_last_route_sustainability_information ? history_props?.dumpRate : _.get(data, "dumpRateType", ""),
          yardage: is_last_route_sustainability_information ? history_props?.last_page_yardage : _.get(data, "yardage", ""),
          paymentType: _.get(data, "paymentType", ""),
        },
        selectPaymentMethod: _.get(data, "paymentType", ""),
        additionalItems: _.get(data, "additionalItems", []),
        cityOfOrigin: _.get(data, "cityOfOrigin", ""),
        serviceLocation: history_props?.serviceLocation?.length > 0 ? history_props?.serviceLocation : _.get(data, "serviceLocation", ""),
        customerRefrence: history_props?.customerRefrence?.length > 0 ? history_props?.customerRefrence : _.get(data, "customerRefrence", ""),
        isFuelSurchardeFee: isFuelSurchardeFee,
        isEnvironmentalLoadFee: isEnvironmentalLoadFee,
        isSalesTax : isSalesTax,
        saleTaxPercentageForOrder : saleTaxPercentageForOrder,
        selectedReadingType,
      });
      this.setDumpRate(_.get(data, "dumpRateType", ""));
      
      if (data?.paymentType === "credit-card") {
        const form = this.formRef.current
        form.setSubmitting({ completeOrder: false, paymentLater: true })
      }
    }

    if(this.state.isSplitPaymentOption){
    let selectedSplitPaymentMethods = []
      let availableAmount = null
      let splitPaymentTotalHandlingFee = 0
      let splitPayment = this.state.splitPayments.map(payment => ({ ...payment }));
      if (data?.splitPayment && data?.splitPayment.length > 0) {
        this.setState({itemsDisabled:true})
        data.splitPayment.map((payment) => {
          splitPayment.map((loaclPayment, index) => {
            if (loaclPayment.paymentMethod === payment.paymentType) {
              if (payment.paymentType === "credit-card" || payment.paymentType === "terminal") {
                splitPayment[index] = { paymentMethod: payment.paymentType, paymentAmount: Number((Number(payment.amount)).toFixed(2)), isSelectedPayment: true }
                selectedSplitPaymentMethods.push(payment.paymentType)
              } else {
                splitPayment[index] = { paymentMethod: payment.paymentType, paymentAmount: payment.amount, isSelectedPayment: true }
                selectedSplitPaymentMethods.push(payment.paymentType)
              }
            }
            return null
          })
          if(payment.paymentType === "credit-card" || payment.paymentType === "terminal"){
            splitPaymentTotalHandlingFee += payment.handlingFee
          }
          availableAmount = availableAmount + payment.amount
          return null
        })
        this.setState({
          remaingSplitAmount:isNaN( Number(Number(data.totalAmount - availableAmount).toFixed(2))) ? 0 : Number(Number(data.totalAmount - availableAmount).toFixed(2)) ,
          availableOrderAmount:isNaN(availableAmount) ? 0 : Number(availableAmount),
          availableSplitAmount:isNaN(availableAmount) ? 0 : Number(availableAmount),
          splitPaymentTotalHandlingFee : splitPaymentTotalHandlingFee
        })
      }
    }else{
      let SplitPayment = _.clone(this.state.splitPayments)
      let selectedSplitPaymentMethods = _.clone(this.state.selectedSplitPaymentMethods)
      SplitPayment.map((payment,index)=>{
          if(payment.paymentMethod === data?.customer?.paymentType){
            SplitPayment[index].isSelectedPayment = true
            SplitPayment[index].paymentAmount = _.get(data,"order.totalAmount",0)
            selectedSplitPaymentMethods=[data?.customer?.paymentType]
          }
          return null
      }) 
      this.setState({splitPayment:SplitPayment,selectedSplitPaymentMethods:selectedSplitPaymentMethods})
    }

    if (data.isPaymentLater) {
      isFuelSurchardeFee = data?.isFuelSurchardeFee;
      isEnvironmentalLoadFee = data?.isEnvironmentalLoadFee;
      isSalesTax = data?.isSalesTax
     
      if(_.get(data, "yardage", "") > 0){
        this.setState({
          showYardageValue: true
        })
      }
      await this.updateOutboundWeightOnLocalStorage(data)
      await this.setAsyncState({
        // splitPayments:splitPayment,
        // selectedSplitPaymentMethods:selectedSplitPaymentMethods,
        // availableSplitAmount:availableAmount,
        outboundWeight: data.outboundWeight,
        outboundWeightDate: data.outboundWeightDate,
        order: data,
        orderLogs,
        initialValues: {
          ...this.state.initialValues,
          dumpRate: is_last_route_sustainability_information ? history_props?.dumpRate : _.get(data, "dumpRateType", ""),
          yardage: is_last_route_sustainability_information ? history_props?.last_page_yardage : _.get(data, "yardage", ""),
          paymentType: _.get(data, "paymentType", ""),
        },
        selectPaymentMethod: _.get(data, "paymentType", ""),
        additionalItems: _.get(data, "additionalItems", []),
        cityOfOrigin: _.get(data, "cityOfOrigin", ""),
        serviceLocation: _.get(data, "serviceLocation", ""),
        truckId: _.get(data, "truckId", ""),
        customerRefrence: _.get(data, "customerRefrence", ""),
        isFuelSurchardeFee: isFuelSurchardeFee,
        isEnvironmentalLoadFee: isEnvironmentalLoadFee,
        isSalesTax : isSalesTax,
        saleTaxPercentageForOrder : saleTaxPercentageForOrder,
        selectedReadingType,
      });
      this.setDumpRate(_.get(data, "dumpRateType", ""));
    }else {
      await this.setAsyncState({
        order: data,
        orderLogs,
        initialValues: {
          ...this.state.initialValues,
          dumpRate:  is_last_route_sustainability_information ? history_props?.dumpRate : _.get(data, "dumpRateType", ""),
          yardage: is_last_route_sustainability_information ? history_props?.last_page_yardage : data.yardage === 0 ? "" : _.get(data, "yardage", ""),
          paymentType: _.get(data, "customer.paymentType", ""),
        },
        additionalItems: _.get(data, "additionalItems", []),
        notes: _.get(state, "notes", _.get(data, "notes", "")),
        cityOfOrigin: _.get(data, "cityOfOrigin", ""),
        serviceLocation: _.get(data, "serviceLocation", ""),
        customerRefrence: _.get(data, "customerRefrence", ""),
        isFuelSurchardeFee: isFuelSurchardeFee,
        isEnvironmentalLoadFee: isEnvironmentalLoadFee,
        isSalesTax : isSalesTax,
        saleTaxPercentageForOrder : saleTaxPercentageForOrder,
        selectedReadingType,
      });
      if (data.dumpRateType) {
        this.setDumpRate(_.get(data, "dumpRateType", ""));
    } else {
        this.setDumpRate(_.get(pricingTier, "pricing.dumpRates.defaultOption", ""));
      }

    }
      if(customerData?.paymentTerms === "account" ){
        this.setState({withOutSplitPaymentValidation:false})
      }

    customerData?.paymentTerms === "cod" && await this.handleSelectCard({ target: { value: !data?.completed && !data.isPaymentLater ? customerData?.paymentType === "" ? "cash" : customerData?.paymentType : data.paymentType } })

    if (customerData?.paymentType === "credit-card" && !data.isPaymentDone) {
      const form = this.formRef.current
      form.setSubmitting({ completeOrder: true, paymentLater: true })
    }
    const dumpRate = _.get(state, "dumpRate", "");
    if (dumpRate && dumpRate !== "" && !data.isPaymentDone) {
      this.setState(
        {
          initialValues: {
            ...this.state.initialValues,
            dumpRate,
          },
        },
        () => {
          this.setDumpRate(dumpRate);
        }
      );
    }
    if (data && data.completed) {
      this.props.history.push({
        pathname: `/order-details/${data.ticketNumber}`,
      });
    }
    if (status) {
      this.setState({hideWieghtToggle: data.hideWeight,loader:false,isFuelSurchardeFee: isFuelSurchardeFee,serviceLocation: history_props?.serviceLocation?.length > 0 ? history_props?.serviceLocation : serviceLocation , customerRefrence: history_props?.customerRefrence?.length > 0 ? history_props?.customerRefrence : customerRefrence,isEnvironmentalLoadFee: isEnvironmentalLoadFee , isSalesTax : isSalesTax , saleTaxPercentageForOrder : saleTaxPercentageForOrder});
    }
  };

  fetchMe = async () => {
    this.setState({loader:true})
    const {
      value: { user },
    } = await this.props.fetchMe();
    let buttonText = "Complete Order";
    this.setState({
      user,
      buttonText,
      serviceAddress: user?.company?.serviceAddress ?? false,
      isPayabliIntegration: user?.company?.isPayabliIntegration ?? false,
      payMethod : user?.company?.isPayabliIntegration ? "payabli" : "payengine",
      isRoundOffAmount: user?.company?.isRoundOffAmount,
      saleTaxPercentageForOrder : user?.company?.salesTaxFeeAmount,
    });
  };

  updateOutboundWeightOnLocalStorage = async (data) =>{
    window.localStorage.setItem(
      'weightData', 
      JSON.stringify({
        weightType: 'Outbound',
        // weights: this.state.weights,
         weight: data?.outboundWeight,
        companyId: data?.companyId,
        date: data?.outboundWeightDate,
        manuallyEnterWeight: 0,
        // usingDefault:usingDefault
      })
    );
    this.setState({storedOutboundWeight:data.outboundWeight, initialValues: {
      ...this.state.initialValues,
     defaultOutboundWeight:  this.state.initialValues?.truck?._id ? this.state.initialValues.truck?.defaultOutboundWeight : data.outboundWeight ?? data.usingDefault,
    },})
  }

  sendEmailReceipt = async () => {
    const {
      emailBtnTxt,
      emailProgress,
      order: { ticketNumber },
    } = this.state;
    const { sendEmailReceipt } = this.props;
    if (emailBtnTxt !== "Email Receipt" || emailProgress) return;
    this.setState({ emailProgress: true });
    const { value } = await sendEmailReceipt({ ticketNumber, timezone });
    message.success(value.message);
    this.setState({ emailProgress: false, emailBtnTxt: "Email Receipt" });
  };

  handleSelectCard = async (e, values, setValues, setSubmitting) => {
    this.setState({ isError: true, errorMessage: '' })
    const form = this.formRef.current
    const selectedSplitPaymentMethods = [...this.state.selectedSplitPaymentMethods]
    const { user } = this.state;
    if (this.state.additionalItems.length > 0) {
      form.errors = {}
    }
    let buttonTextForCash = "Complete Order";
    let buttonTextForCards = "Complete Order";
    if (!user?.company?.signatureRequired) {
      buttonTextForCash = "Complete Order";
      buttonTextForCards = "Complete Order";
    }
    if (selectedSplitPaymentMethods.includes("cash") || selectedSplitPaymentMethods.includes("check")) {
      this.setState({
        buttonText: buttonTextForCash,
        // selectPaymentMethod: this.state.splitPayments,
      });
      this.setAsyncState({
        initialValues: {
          ...this.state.initialValues,
          isPaymentLater: false, isPaymentDone: true, completed: true
        },
      })
      setValues ? setValues({ ...values, paymentType: e.target.value }) : form.setValues({ ...form.values, paymentType: e.target.value })
      setSubmitting ? setSubmitting({ completeOrder: true, paymentLater: false }) : form.setSubmitting({ completeOrder: true, paymentLater: false })
      // this.client = null;
      // this.terminal = null;
    }
    if (selectedSplitPaymentMethods.includes("credit-card")) {
      this.setState({
        buttonText: buttonTextForCards,
        // selectPaymentMethod: this.state.splitPayments,
      });
      this.setAsyncState({
        initialValues: {
          ...this.state.initialValues,
          isPaymentLater: false, isPaymentDone: true, completed: true,
          paymentType: "credit-card"
        },
      })
      setValues ? setValues({ ...values, paymentType: "credit-card" }) : form.setValues({ ...form.values, paymentType: e.target.value })
      setSubmitting ? setSubmitting({ completeOrder: true, paymentLater: false }) : form.setSubmitting({ completeOrder: true, paymentLater: false })
      // this.client = null;
      // this.terminal = null;
    }

      this.setAsyncState({
        initialValues: {
          ...this.state.initialValues,
          isPaymentLater: false, isPaymentDone: true, completed: true,
          paymentType:"terminal"
        },
      })
      setValues ? setValues({ ...values, paymentType: "terminal" }) :form.setValues({...form.values, paymentType: e.target.value})
      setSubmitting ? setSubmitting({completeOrder:true,paymentLater:false}): form.setSubmitting({completeOrder:true,paymentLater:false})
      if (this.state.paybliEnable) {
        try{
        this.setState({isTerminalLoader:true})
        const {
          value: { status, data },
        } = await this.props.getPaybliTerminalDevices({
          entryPoint: this.state.paybliDetails.entry_point,
        }); // get paybli devices
        if (status) {
          // this.setState({ paybliEnable: true });
          this.setState({
            buttonText: buttonTextForCards,
            isTerminalLoader:false,
            selectPaymentMethod: "terminal",
            enableTerminalButton: true,
            devices: data?.responseList,
            selectedDevice:
            data?.responseList.length === 1
            ? data?.responseList[0]["deviceId"]
            : "",
            connectionStatus: "Connected",
          });
        }
        else{
          this.setState({
            // selectPaymentMethod: this.state.splitPayments,
            isTerminalLoader: false
          });
        }
      }
      catch (error) {
        this.setState({ isTerminalLoader: false ,loader:false});
      }
      } else {
        try {
       this.setState({isTerminalLoader:true})
  
        const { value } = await this.props.fetchDevices();
        if (value.status) {
          this.setState({
            buttonText: buttonTextForCards,
            // selectPaymentMethod: "terminal",
            isTerminalLoader: false,
            enableTerminalButton: true,
            devices: value.data,
            selectedDevice:
              value.data.length === 1 ? value.data[0]["deviceId"] : "",
            connectionStatus: "Connected",
          });
          setSubmitting ? setSubmitting({completeOrder:true,paymentLater:false}) : form.setSubmitting({completeOrder:true,paymentLater:false});
        } else {
          this.setState({
            buttonText: buttonTextForCards,
            isTerminalLoader: false,
            // selectPaymentMethod: "terminal",
            enableTerminalButton: false,
            connectionStatus: value.message,
          });
        }
        } catch (error) {
          this.setState({ isTerminalLoader: false ,loader:false});
        }
      }
      // const divElement = document.querySelector('.ant-spin-container');
      // if (divElement) {
      //   divElement.style.opacity = '1';
      // }
}

isDelinquentAccount = async () => {
    const payload = {
      customerId:this.state.customer._id,
      billingStatus: '',
    }
    const { value: { data: { allData } } } = await this.props.customerInvoicesByCustomerId(payload);
    this.setState({dueBalance : allData?.delinquentBalance})
  };

  async chargeOnDevice({ setSubmitting, values }) {
    setSubmitting({ completeOrder: false, paymentLater: true});
    try {
      const { chargeOnDevice, updateOrder } = this.props;
      let { selectedDevice, order, devices } = this.state;
      if (selectedDevice === "") {
        selectedDevice = devices[0]["deviceId"];
      }
      // this.setState({ paymentProgress: true, isError: false, errorMessage: "" });
      // await this.setAsyncState({ isChargeCard: true });
      await this.setAsyncState({
        paymentProgress: true,
        isError: false,
        errorMessage: "",
        connectionStatus: "Waiting for payment...",
      });
      let payAmount = 0
      const { user } = this.state
      const getPayload = this.getPayload({ ...values, completed: false });
            const {stripeFeeOnTotal  } = this.calculateTotalAmount({ values });
      let creditCardSplitpaymentObj={}
      const SplitPayment = this.state.splitPayments.map(payment => ({ ...payment }));
      this.state.selectedSplitPaymentMethods.includes("terminal") && SplitPayment.length > 0 &&
        SplitPayment.map((payment) => {
          if (payment.paymentMethod === "terminal") {
            if (_.get(user, "company.chargeStripeCardFees", false)) {
              const striptFeeOnCreditCardAmount = this.calcFee(this.state.isSplitPaymentOption ? payment.paymentAmount :stripeFeeOnTotal.amount , "USD");
              payAmount = this.round2decimal(striptFeeOnCreditCardAmount.total)
              creditCardSplitpaymentObj={
                paymentType: "terminal",
                amount: this.state.isSplitPaymentOption ? payAmount : getPayload.totalAmount,
                uniqueId:"",
                handlingFee: this.round2decimal(striptFeeOnCreditCardAmount.fee)
              }
            } else {
              payAmount =this.state.isSplitPaymentOption ? payment.paymentAmount : getPayload.totalAmount
              creditCardSplitpaymentObj={
                paymentType: "terminal",
                amount:this.state.isSplitPaymentOption ? payAmount : getPayload.totalAmount,
                uniqueId:"",
                handlingFee: 0
              }
            }
          }
          return null
        })
      // const { total } = this.calculateTotalAmount({ values });
      if (payAmount && parseFloat(payAmount) > 0) {
        if (this.state.paybliEnable) {
          if(this.state.isSplitPaymentOption){
            this.setState({isChargeTerminalProcess:true })
           }
          // const { total } = this.calculateTotalAmount({ values });
          const pendingPayloadForPayabli = this.getPayload({ ...values, completed: false });
          pendingPayloadForPayabli["isPaymentDone"] = false
          pendingPayloadForPayabli['isPaymentLater'] = true
          delete pendingPayloadForPayabli.splitPayment
          await updateOrder(pendingPayloadForPayabli)
          this.setState({ isTerminalPaymentDone: false,withOutSplitPaymentValidation:true })
          setSubmitting({ completeOrder: true, paymentLater: true });
          const {
            value: { status, signatureUrl, data , paymentHistoryData ,message },
          } = await this.props.makeTerminalTransaction({
            // make payabli transaction
            cardData: {
              method: "cloud",
              device: selectedDevice,
            },
            paymentDetails: { totalAmount:this.state.isSplitPaymentOption ? payAmount :pendingPayloadForPayabli.totalAmount },
            customerData: {
              customerId: this.state.customerPayabliData?.customerId,
            },
            customerMainId: order.customerId,
            entryPoint: this.state.paybliDetails.entry_point,
            ticketNumber: _.get(order, "ticketNumber", false),
            orderId: order._id,
          });
          // const payload = this.getPayload({ ...values, completed: false });
          this.setState({ payabliInfo: data, terminalStatus: true,  })
          await Promise.all([
            // updateOrder(payload),
            this.setAsyncState({ isChargeCard: false, connectionStatus: "Connected", paymentProgress: false, signatureUrl}),
          ]);
          if (status) {
            // let splitPayment = this.state.splitPayments
            // splitPayment.map((payment,index)=>{
            //   if (payment.paymentMethod === "credit-card") {
            //     splitPayment[index].uniqueId=paymentHistoryData._id
            //   }
            // })
            await this.updateCardpaymnet(values,paymentHistoryData,creditCardSplitpaymentObj)
            setSubmitting({ completeOrder: false, paymentLater: true });
            await this.setAsyncState({
              isTerminalPaymentDone: true,
              isChargeCardCustomer: true,
              paymentProgress: false,
              connectionStatus: "Connected",
              withOutSplitPaymentValidation :false
              // last4: data.SaleResponse.maskedCardNumber,
              // brand: data.SaleResponse.cardType,
            });
          } else {
            setSubmitting({completeOrder:true,paymentLater:true});
            this.setState({
              isTerminalPaymentDone: false,
              isError: true,
              paymentProgress: false,
              connectionStatus: "Connected",
              isChargeTerminalProcess:false,
              isFailedTransaction : true,
              withOutSplitPaymentValidation:true
            });
            const match = message?.match(/\b\d+\b/);
          if (match) {
            const extractedNumber = match[0];
            const matchingCode = responseCode(extractedNumber)
            if (matchingCode) {
              this.setState({errorMessage : matchingCode.message})
              return matchingCode.message;
            }
          }
          else if(match === null){
            this.setState({errorMessage : message}) 
          }
          else{
            this.setState({errorMessage: "Card could not be charged, please try again.",})
          }
          }
        } else {
          if(this.state.isSplitPaymentOption){
           this.setState({isChargeTerminalProcess:true })
          }
          const pendingPayload = this.getPayload({ ...values, completed: false });
          pendingPayload['isPaymentDone'] = false
          pendingPayload['isPaymentLater'] = true
          if(this.state.isSplitPaymentOption){
           delete pendingPayload.splitPayment
          }else{
            pendingPayload["splitPayment"] = [creditCardSplitpaymentObj]
          }
          await updateOrder(pendingPayload)
          this.setState({ isTerminalPaymentDone: false,withOutSplitPaymentValidation:false })
          const {
            value: {status,data,paymentHistoryData, Code , message}
          } = await chargeOnDevice({
            amount: this.state.isSplitPaymentOption ? payAmount : pendingPayload.totalAmount,
            deviceId: selectedDevice,
            customerId: order.customerId,
            orderId: order._id,
          });
          
          // const payload = this.getPayload({ ...values, completed: false });
          await Promise.all([
            // updateOrder(payload),
            this.setAsyncState({ isChargeCard: false, paymentProgress: false }),
          ]);
          if (status) {
            // let splitPayment = this.state.splitPayments
            // splitPayment.map((payment,index)=>{
            //   if (payment.paymentMethod === "credit-card") {
            //     splitPayment[index].uniqueId=paymentHistoryData._id
            //   }
            // })
          if(this.state.isSplitPaymentOption){
             await this.updateCardpaymnet(values,paymentHistoryData,creditCardSplitpaymentObj)
          }
            setSubmitting({ completeOrder:false, paymentLater: true });
            await this.setAsyncState({
              isChargeCardCustomer: true,
              responseMessageForTerminal : "Customer charged successfully",
              isTerminalPaymentDone: true,
              last4: data.SaleResponse.maskedCardNumber,
              brand: data.SaleResponse.cardType,
              paymentProgress: false,
              collectPaymentLater: false,
              disabledFuelSwitch: true,
              connectionStatus: "Connected",
              withOutSplitPaymentValidation :false
            });
          }
          else {
            setSubmitting({ completeOrder: true, paymentLater: false });
            if(Code === "" && message === "cancelledAtTerminal"){
              this.setState({errorMessage : "Payment cancelled at terminal. Please try again or use another payment method."})
            }
            else if(Code !== ""){
              const Matchingmessage = responseCode(Code);
              if(Matchingmessage){
                this.setState({errorMessage: Matchingmessage.message ? Matchingmessage.message : "Card could not be charged, please try again."})
              }
            }
            else{
              this.setState({errorMessage:  message})
            }
            this.setState({
              isError: true,
              isTerminalPaymentDone: false,
              // responseMessageForTerminal : errMessage,
              // errorMessage: errMessage,
              paymentProgress: false,
              isChargeTerminalProcess:false,
              isFailedTransaction : true,
              connectionStatus: "Connected",
              withOutSplitPaymentValidation:true
            });
          }
        }
      }
    } catch (err) {
      setSubmitting({ completeOrder: false, paymentLater: false });
      this.setAsyncState({
        enableTerminalButton: true,
        paymentProgress: false,
        isFailedTransaction : true,
        isError: true,
        errorMessage: err.message,
        connectionStatus: "Connected",
      });
    }
  }

  setDumpRate = (value) => {
    // const {
    //   order: {
    //     customer: { dumpRates }
    //   }
    // } = this.state;
    const dumpRates = this.state.pricingTierDumpRates;
    const {dumpRatesSaleTax} = this.state;
    Object.keys(dumpRatesSaleTax).forEach(function(key) {
      if (value === key) {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            dumpRateSalesTax: dumpRatesSaleTax[key]
          },
        });
      }
    }.bind(this));
    if (value === "defaultOption") {
      this.setState(
        { dumpRateType: value, dumpRate: dumpRates[dumpRates[value]], initialValues: { ...this.state.initialValues, dumpRate: dumpRates[value] } },
        () => {
          if (dumpRates[value].indexOf("pricePerYard") !== -1) {
            this.setState({ showYardageValue: true });
          }
          if (dumpRates[value].indexOf("pricePerYard") === -1) {
            this.setState({ showYardageValue: false });
          }
          //setValues({ ...values, dumpRate: dumpRates[value] });
        }
      );
    } else {
      this.setState({ dumpRateType: value, dumpRate: dumpRates[value], initialValues: { ...this.state.initialValues, dumpRate: value } }, () => {
        if (value.indexOf("pricePerYard") !== -1) {
          this.setState({ showYardageValue: true });
        }
        if (value.indexOf("pricePerYard") === -1) {
          this.setState({ showYardageValue: false });
        }
        //setValues({ ...values, dumpRate: value });
      });
    }
  };

  onChangeDumpRate = (e, values, setValues) => {
    // const {
    //   order: {
    //     customer: { dumpRates }
    //   }
    // } = this.state;
    const dumpRates = this.state.pricingTierDumpRates;
    const {dumpRatesSaleTax} = this.state;
    let splitPayment = this.state.splitPayments.map(payment => ({ ...payment }));
    this.state.splitPayments.map((payment,index)=>{
     if(payment.paymentMethod ==='check' || payment.paymentMethod==='cash'){
       splitPayment[index].paymentAmount = null
     }
     return null
    })
    this.setState({splitPayments:splitPayment,reRender:!this.state.reRender})
    const value = e.target.value;
    Object.keys(dumpRatesSaleTax).forEach(function(key) {
      if (value === key) {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            dumpRateSalesTax: dumpRatesSaleTax[key]
          },
        });
      }
    }.bind(this));
    if (value === "defaultOption") {
      this.setState(
        { dumpRateType: value, dumpRate: dumpRates[dumpRates[value]] },
        () => {
          if (dumpRates[value].indexOf("pricePerYard") !== -1) {
            this.setState({ showYardageValue: true });
          }
          if (dumpRates[value].indexOf("pricePerYard") === -1) {
            this.setState({ showYardageValue: false });
          }
          this.setAsyncState({
            initialValues: {
              ...this.state.initialValues,
              isPaymentLater: false,
              isPaymentDone: true,
              completed: true,
              dumpRate: value
            },
          })
          setValues({ ...values, dumpRate: dumpRates[value] });
        }
      );
    } else {
      this.setState({ dumpRateType: value, dumpRate: dumpRates[value] }, () => {
        if (value.indexOf("pricePerYard") !== -1) {
          this.setState({ showYardageValue: true });
        }
        if (value.indexOf("pricePerYard") === -1) {
          this.setState({ showYardageValue: false });
        }
        this.setAsyncState({
          initialValues: {
            ...this.state.initialValues,
            paymentType: this.state.selectPaymentMethod,
            isPaymentLater: false, isPaymentDone: true, completed: true,
            dumpRate: value
          },
        })
        setValues({ ...values, dumpRate: value });
      });
    }
  };

  saveNotes = async (orderId) => {
    const form = this.formRef.current
    if (this.state.additionalItems.length > 0) {
      form.errors = {}  
    }
    const { notes, order } = this.state;
    const payload = { id: orderId, notes};
    const { updateOrder } = this.props;
    const {
      value: { status },
    } = await updateOrder(payload);
    if (status) {
      this.setState({ showEditor: false, order: { ...order, notes } });
    }
  };
  saveChanges=async(orderId ) => {
    const { updateOrderData } = this.props;
    const {
      initialValues: {
        containers,
        yardage
      },
    } = this.state;
   const container = containers
    const containerWithoutId = {size:container?.size,defaultWeight:container?.defaultWeight}
        const payload = {
      id:orderId,
      dumpRate: this.state?.dumpRate,
      dumpRateType : this.state?.dumpRateType,
      paymentType: this.state.customer?.paymentTerms,
      projectId:this.state.selectedProject?._id ,
      driverId:this.state.selectedDriver?._id ,
      loadImageUrl: this.state?.loadImageUrl ,
      outboundWeight:this.state.storedOutboundWeight,
      defaultOutboundWeight:  this.state.initialValues.defaultOutboundWeight,
      truckId: this.state?.initialValues.truck?._id,
      containers:containerWithoutId? [containerWithoutId]: [],
      containerSize:this.state.order.containerSize,
      saveAsDefaultOutboundWeight:this.state.saveAsDefaultOutboundWeight,
      plateNumber: this.state?.truck?.plateNumber ,
      paymentTerms: this.state.customer.paymentTerms,
      cityOfOrigin: this.state.cityOfOrigin,
      serviceLocation: this.state.serviceLocation,
      yardage: yardage ? Number(yardage) :"",
      additionalItems:this.state.additionalItems,
      customerRefrence: this.state.customerRefrence ? this.state.customerRefrence?.trim() : "",
    };
    await updateOrderData(payload);
    this.props.history.push({ pathname: "/scale" });
  }
  handleChange(event) {
    const form = this.formRef.current
    if (this.state.additionalItems.length > 0) {
      form.errors = {}  
    }
    this.setState({ [event.target.name]: event.target.value });
  }

  onChangeAdditionalValue(e) {
    const value = e.target.value;
    // const { order } = this.state;
    const { additionalItemsData } = this.state;
    const additionalItems = [...this.state.additionalItems];
    if (
      additionalItems.map(({ label }) => label).indexOf(value) === -1 &&
      value
    ) {
      const index = additionalItemsData
        .map(({ label }) => label)
        .indexOf(value);
      additionalItems.push({ ...additionalItemsData[index], quantity: "" });
      this.setState({ additionalItems, selectedAdditionalItem: "" });
    }
  }

  onChangeAdditionalQuantity(val, i, values, setValues, item) {
    const additionalItems = [...this.state.additionalItems];
    const value = val ? val.toFixed(2) : ""
    additionalItems[i].quantity = value?.length > 0 ? value : null;
    this.setAsyncState({
      initialValues: {
        ...this.state.initialValues,
        isPaymentLater: false, isPaymentDone: true, completed: true
      },
    })
    setValues({ ...values, [item.name]: value });
    this.setState({ additionalItems });
  }

  removeItem(item) {
    const additionalItems = [...this.state.additionalItems];
    const index = additionalItems.indexOf(item);
    if (index !== -1) {
      additionalItems.splice(index, 1);
      this.setState({ additionalItems });
    }
  }

  deleteOrder = async (reason) => {
    const {
      order: { _id },
    } = this.state;
    this.setState({ progressBar: true });
    const {
      value: { status },
    } = await this.props.deleteOrder(_id,reason);
    if (status) {
      this.props.history.push("/scale");
    }
  };

  SplitPaymentValidation(type, values) {
    const splitCreditCardPayment = this.state.splitPayments;
   const {stripeFeeOnTotalActualAmount} =this.calculateTotalAmount({values})
     if( type === "" && this.state.availableOrderAmount === stripeFeeOnTotalActualAmount.total){
      this.setState({
        isError: false,
        errorMessage: ""
      });
      return true;
     }else{
    if (type === "credit-card") {
      let amount = 0;

      splitCreditCardPayment.map((payment, index) => {
        if (payment.paymentMethod === "credit-card") {
          amount = splitCreditCardPayment[index].paymentAmount;
        }
        return null
      });
      if (amount <= 0 && this.state.cardId === "") {
        this.setState({
          isError: true,
          errorMessage: "Please choose a credit card, ensuring that the credit card amount is greater than zero."
        });
        return false;
      } else {
        if (amount <= 0) {
          this.setState({
            isError: true,
            errorMessage: "Enter an amount that is more than zero."
          });
          return false;
        } else {
          if (this.state.cardId === "") {
            this.setState({
              isError: true,
              errorMessage: "Please Select Credit Card"
            });
            return false;
          } else {
            this.setState({
              isError: false,
              errorMessage: ""
            });
            return true;
          }
        }
      }

      // if (this.state.remaingSplitAmount === 0) {
      //   return true;
      // } else {
      //   this.setState({
      //     isError: true,
      //     errorMessage: "Please enter full Order Amount"
      //   });
      //   return false;
      // }
    }

    if (type === "terminal") {
      let amount = 0;

      splitCreditCardPayment.map((payment, index) => {
        if (payment.paymentMethod === "terminal") {
          amount = splitCreditCardPayment[index].paymentAmount;
        }
        return null
      });

      if (amount <= 0) {
        this.setState({
          isError: true,
          errorMessage: "Enter an amount that is more than zero"
        });
        return false;
      }
      return true;
      // if (this.state.remaingSplitAmount === 0) {
      //   return true;
      // } else {
      //   this.setState({
      //     isError: true,
      //     errorMessage: "Please enter full Order Amount"
      //   });
      //   return false;
      // }
    }

    if (type === "credit-memo") {
      let amount = 0;
      splitCreditCardPayment.map((payment, index) => {
        if (payment.paymentMethod === "credit-memo") {
          amount = splitCreditCardPayment[index].paymentAmount;
        }
        return null
      });
      if (this.state.selectedCreditMemo && this.state.selectedCreditMemo.length === 0) {
        this.setState({ isError: true, errorMessage: "Please choose a credit memo, ensuring that the credit memo amount is greater than zero." });
        return false;
      }
      if (amount <= 0) {
        this.setState({ isError: true, errorMessage: "Enter an amount that is more than zero." });
        return false;
      }
      // if (this.state.remaingSplitAmount === 0) {
      //   return true;
      // } else {
      //   this.setState({
      //     isError: true,
      //     errorMessage: "Please enter full Order Amount"
      //   });
        
      // }
      return true;
    }

    if ((type === "cash-check")) {
      const selectedPaymentTypes = ["cash", "check"];
      const selectType = [];
      for (const payment of splitCreditCardPayment) {
        if (selectedPaymentTypes.includes(payment.paymentMethod) && payment.isSelectedPayment) {
          selectType.push(payment.paymentMethod);
        }
      }
      for (const paymentType of selectType) {
        const payment = splitCreditCardPayment.find(payment => payment.paymentMethod === paymentType);
        if (payment && payment.paymentAmount <= 0) {
          this.setState({
            isError: true,
            errorMessage: `Enter an amount that is more than zero`
          });
          return false;
        }
        if(paymentType === "check" && this.state.checkNumber ===""){
          this.setState({
            isError: true,
            errorMessage: `Pleae Enter check Number`
          });
          return false;
        }
      }
      // if (this.state.remaingSplitAmount !== 0) {
      //   this.setState({
      //     isError: true,
      //     errorMessage: "Please enter full Order Amount"
      //   });
      //   return false;
      // }
    }

    if (type === "cash-check" || type === "" || type === "credit-memo") {
      let availableAmount = this.state.availableSplitAmount;
      if (!(this.state.selectedSplitPaymentMethods.length > 0)) {
        this.setState({
          isError: true,
          errorMessage: "This order can't be completed due to an outstanding amount. Please choose Collect Payment Later or select a payment method from the options above."
        });
        return false;
      }
      if (this.state.selectedSplitPaymentMethods.includes("credit-card") && !this.state.isChargeCardCustomer) {
        this.setState({
          isError: true,
          errorMessage: "Please Charge Card"
        });
        return false;
      }
      if(this.state.selectedSplitPaymentMethods.includes("terminal") && !this.state.isTerminalPaymentDone){
        this.setState({
          isError: true,
          errorMessage: "Please Charge Terminal"
        });
        return false;
      }
      const {  stripeFeeOnTotalActualAmount } = this.calculateTotalAmount({ values: { ...values } });
      if (availableAmount <  stripeFeeOnTotalActualAmount.total) {
        this.setState({
          isError: true,
          errorMessage: "Please enter Valid Amount."
        });
        return false;
      } else {
        return true;
      }
    }
  }
  }

  async updateCardpaymnet(values,paymentHistoryData,creditCardSplitpaymentObj){
    const {  updateOrder } = this.props;
    let payload = this.getPayload({ ...values, completed: false });
    let splitPayment=[]
    payload["isPaymentDone"] = false
    payload['isPaymentLater'] = true
    creditCardSplitpaymentObj.uniqueId = paymentHistoryData._id
    payload.splitPayment=[]
    payload.splitPayment = [creditCardSplitpaymentObj]
    this.state.splitPayments.map((payment)=>{
      if(payment.paymentMethod === creditCardSplitpaymentObj.paymentType){
      let obj={
        paymentMethod:payment.paymentMethod,
        paymentAmount: null,
        isSelectedPayment: false,
        uniqueId:null,
        handlingFee: null
      }
      splitPayment.push(obj)
    }else{
      splitPayment.push(payment)
    }
    return null
    })
    await updateOrder(payload)
    let selectedSplitPaymentMethods= this.state.selectedSplitPaymentMethods.filter((paymentType)=> paymentType !== creditCardSplitpaymentObj.paymentType)
    let splitPaymentTotalHandlingFee = Number(Number(this.state.splitPaymentTotalHandlingFee) + Number(creditCardSplitpaymentObj.handlingFee))
    this.setState({availableOrderAmount:Number((this.state.availableOrderAmount + creditCardSplitpaymentObj.amount).toFixed(2)),splitPayments:splitPayment,selectedSplitPaymentMethods:selectedSplitPaymentMethods,isChargeTerminalProcess:false,
      splitPaymentTotalHandlingFee:splitPaymentTotalHandlingFee,isChargeCardProcessing:false})
      if(this.state.isSplitPaymentOption){
        setTimeout(()=>{
          this.setState({isChargeCardCustomer:false,responseMessageForTerminal:""})
        }, 5000);
      }
  }

  async chargeCustomer({ setSubmitting, values }) {
    setSubmitting({ completeOrder: true, paymentLater: true });
    this.setState({ itemsDisabled: true,isChargeCardCustomer:false,isChargeCardProcessing:true })
    let { user } = this.state
    let payload = this.getPayload({ ...values, completed: false });
    const { stripeFeeOnTotal} = this.calculateTotalAmount({ values });
    let payAmount = 0
    let creditCardSplitpaymentObj={}
    let SplitPayment = this.state.splitPayments.map(payment => ({ ...payment }));
    this.state.selectedSplitPaymentMethods.includes("credit-card") && SplitPayment.length > 0 &&
      SplitPayment.map((payment) => {
        if (payment.paymentMethod === "credit-card") {
          if (_.get(user, "company.chargeStripeCardFees", false)) {
            const striptFeeOnCreditCardAmount = this.calcFee( this.state.isSplitPaymentOption ? payment.paymentAmount : stripeFeeOnTotal.amount, "USD");
            payAmount = striptFeeOnCreditCardAmount.total
            creditCardSplitpaymentObj={
              paymentType: "credit-card",
              amount: this.state.isSplitPaymentOption ? payAmount : payload.totalAmount,
              uniqueId:"",
              handlingFee: striptFeeOnCreditCardAmount.fee
            }
          } else {
            payAmount = payment.paymentAmount
            creditCardSplitpaymentObj={
              paymentType: "credit-card",
              amount:this.state.isSplitPaymentOption ? payAmount : payload.totalAmount,
              uniqueId:"",
              handlingFee: 0
            }
          }
        }
        return null
      })
    if (this.state.payMethod === "payengine") {
      try {
        const { chargeCustomer,updateOrder } = this.props;
        let { cardId, order } = this.state;
        if (cardId === "") {
          cardId = order.customer.paymentmethods[0]._id;
        }
        await this.setAsyncState({ isChargeCard: true });
        // const { total } = this.calculateTotalAmount({ values });
        payload["isPaymentDone"] = false
        payload['isPaymentLater'] = true
        delete payload.splitPayment
        await updateOrder(payload)
        const {
          value: { status, data, errMessage, paymentHistoryData , Code},
        } = await chargeCustomer({
          amount:this.state.isSplitPaymentOption ?  payAmount : payload.totalAmount ,
          cardId,
          customerId: order.customerId,
          orderId: order._id,
        });
        await Promise.all([this.setAsyncState({ isChargeCard: false })]);
        if (status) {
          // let splitPayment = this.state.splitPayments
          // splitPayment.map((payment,index)=>{
          //   if (payment.paymentMethod === "credit-card") {
          //      splitPayment[index].uniqueId=paymentHistoryData._id
          //   }
          // })
          setSubmitting({ completeOrder: false, paymentLater: true });
          await this.setAsyncState({
            isChargeCardCustomer: true,
            itemsDisabled: true,
            last4: data.SaleResponse.maskedCardNumber,
            brand: data.SaleResponse.cardType,
            disabledFuelSwitch: true,
            withOutSplitPaymentValidation :false
          });
          await this.updateCardpaymnet(values,paymentHistoryData,creditCardSplitpaymentObj)
        } else {
          setSubmitting({ completeOrder: false, paymentLater: false });
          if(Code){
            const matchingCode = responseCode(Code)
            if(matchingCode){
            this.setState({errorMessage : matchingCode.message ? matchingCode.message : "Card could not be charged, please try again." })
            }
            }
            else{
              this.setState({ errorMessage: errMessage ? errMessage : ""})
            }
          this.setState({
            itemsDisabled: false,
            isChargeCardCustomer:false,
            isError: true,
            isFailedTransaction : true,
            isChargeCardProcessing:false
          })
        }
      } catch (err) {
        this.setAsyncState({ isChargeCard: false, itemsDisabled: false });
        // if (err.message.indexOf("No such source") !== -1) {
        //   // alert("Payment Unsuccessfull! \nInvalid Card. Please try again.");
        // } else {
        //   // alert(err.message);
        // }
      }
    } else {
      try {
        // this.setState({submittingForm: false})
        // setSubmitting({completeOrder:false});
        const { updateOrder } = this.props;
        const { order } = this.state;
        await this.setAsyncState({ isChargeCard: true });
        // const { total } = this.calculateTotalAmount({ values });
        let payload = this.getPayload({ ...values, completed: false });
        payload["isPaymentDone"] = false
        payload['isPaymentLater'] = true
        delete payload.splitPayment
        await updateOrder(payload)
        const {
          value: { status ,paymentHistoryData ,message},
        } = await this.props.makeTransaction({
          // make payabli transaction
          cardData: { storedMethodId: this.state.selectedCardData },
          paymentDetails: { totalAmount: this.state.isSplitPaymentOption? payAmount :payload.totalAmount },
          customerData: {
            customerId: this.state.customerPayabliData?.customerId,
            payabliInfo: this.state.terminalStatus === true ? this.state.payabliInfo : !isEmpty(this.state.payabliInfo) ? this.state.customerPayabliData?.StoredMethods?.find((cust) => {return cust.IdPmethod === this.state.payabliInfo?.referenceId})  : this.state.customerPayabliData && this.state.customerPayabliData?.StoredMethods?.find((cust) => {return cust.IdPmethod === this.state.selectedCardData}) 
          },
          entryPoint: this.state.paybliDetails.entry_point,
          orderId: order.ticketNumber,
          orderunderscoreID:order._id
        });
        await Promise.all([
          this.setAsyncState({ isChargeCard: false }),
        ]);
        if (status) {
          // let splitPayment = this.state.splitPayments
          // splitPayment.map((payment,index)=>{
          //   if (payment.paymentMethod === "credit-card") {
          //      splitPayment[index].uniqueId=paymentHistoryData._id
          //   }
          // })
          this.setState({isChargeCardCustomer:true,itemsDisabled: true,withOutSplitPaymentValidation :false})
          setSubmitting({ completeOrder: false, paymentLater: true, isChargeCardCustomer:true, });
          await this.updateCardpaymnet(values,paymentHistoryData,creditCardSplitpaymentObj)
        } else {
            const match = message.match(/\b\d+\b/);
            if (match) {
              const extractedNumber = match[0];
              const matchingCode = responseCode(extractedNumber)
              if (matchingCode) {
                this.setState({errorMessage : matchingCode.message})
              }
            }
            else if(match === null){
              this.setState({errorMessage : message}) 
            }
            else{
            this.setState({errorMessage: "Card could not be charged, please try again."})
            }
            this.setState({ itemsDisabled: false, isChargeCardCustomer:false ,isChargeCardProcessing:false , isError: true, isFailedTransaction : true ,
            })
        }
      } catch (err) {
        this.setAsyncState({ isChargeCard: false,itemsDisabled: false });
        if (err.message.indexOf("No such source") !== -1) {
           // alert("Payment Unsuccessfull! \nInvalid Card. Please try again.");
        } else {
            // alert(err.message);
        }
      }
    }
  }

  handleNotesChange = (event) => {
    this.setState({ notes: event.target.value });
  };

  round2decimal = (number) => {
    return Number(numberFormat(Math.round(parseFloat(number) * 1000) / 1000))
  };

  calcFee(amount, currency) {
    const {user} = this.state
    const _fee = user?.company?.chargeStripeCardFeesAmount ? user?.company?.chargeStripeCardFeesAmount : "3"
    amount = this.round2decimal(amount);
    let fee =
      amount * (this.round2decimal(_fee) / 100);
    fee = this.round2decimal(fee);
    const total = this.round2decimal(amount + fee);
    return {
      amount: this.round2decimal(amount),
      fee: this.round2decimal(fee),
      total: this.round2decimal(total),
    };
  }

  renderTotalAmount({total,totalActualAmount,stripeFeeOnTotal,stripeFeeOnTotalActualAmount,}) {
    const { user } = this.state;
    return (
      <>
        {_.get(user, "company.chargeStripeCardFees", false) &&
          (this.state.selectedSplitPaymentMethods.includes("credit-card") || this.state.selectedSplitPaymentMethods.includes("terminal"))
          && (
            <>
              <hr />
              <li className="__order-amount">
                <span>Handling Fee</span>
                <span>${numberFormat(stripeFeeOnTotal.fee)}</span>
              </li>
              <li className="__order-amount">
                <span>Order Amount </span>
                <span>${numberFormat(stripeFeeOnTotal.amount)}</span>
              </li>
            </>
          )}
        <hr />
        
        <li className="total__order-amount align-items-start">
          <span>Total Order Amount </span>
          {totalActualAmount < total && _.get(this.state.selectedCustomer, "paymentTerms", "") !== "account" ? (
            <>
              <span>
                $
                {numberFormat(
                  // isRoundOff(Number(stripeFeeOnTotal.total))(
                  //  this.state.selectedSplitPaymentMethods.includes("cash") &&
                  //     this.state?.isRoundOffAmount
                  // )
                  stripeFeeOnTotal.total
                )}
                 {this.state.selectedSplitPaymentMethods.includes("cash") &&
                     this.state?.isActualRoundOff && <small><pre class="m-0">(Rounded)</pre></small>}
              </span>
              
            </>
          ) : (
            <span>
              $
              {numberFormat(
                // isRoundOff(Number(stripeFeeOnTotal.total))(
                //   this.state.selectedSplitPaymentMethods.includes("cash")  && this.state?.isRoundOffAmount
                // )
                stripeFeeOnTotal.total
              )}
               {this.state.selectedSplitPaymentMethods.includes("cash") &&
                     this.state?.isActualRoundOff && <small><pre class="m-0">(Rounded)</pre></small>}
            </span>
          )}
        </li>
      </>
    );
  }

 calculateSalesTax =(value) => {
  const user = this.state.user
  let saleTaxFeeAmount = 0
  if ((user?.company?.isSalesTax ?? false) && (this.state.isSalesTax ?? false)) {
    saleTaxFeeAmount = this.round2decimal( (this.state.saleTaxPercentageForOrder ?? 0) / 100 * this.round2decimal(value));
  }
  return this.round2decimal(saleTaxFeeAmount);
 }

  calculateTotalAmount({ values }) {
    let netWeight
    const {
      order,
      showYardageValue,
      user,
      selectedReadingType,
      additionalItems,
    } = this.state;
    const isDumpRateSalesTax = this.state.initialValues.dumpRateSalesTax
    const is_last_page_show_yardage_value = this.props?.history?.location?.state?.dumpRate?.toLowerCase()?.includes('priceperyard')
    const yardage = ( showYardageValue || is_last_page_show_yardage_value ) ? values.yardage === "" ? 0 : values.yardage : ""
    if(selectedReadingType=== "Outbound material sale"){
    netWeight =
    _.get(this.state, "outboundWeight", "") -
    _.get(order, "inboundWeight", "") ;
    }
    else{
       netWeight =
      _.get(order, "inboundWeight", "") -
      _.get(this.state, "outboundWeight", "");
    }
    const tonnage = this.round2decimal(netWeight / 2000);
    const dumpRate = this.state.dumpRate
      ? this.round2decimal(this.state.dumpRate)
      : 0;
    //New Calculation
    let _total = showYardageValue
      ? this.round2decimal(
          this.round2decimal(dumpRate) * this.round2decimal(yardage)
        )
      : this.round2decimal(
          this.round2decimal(dumpRate) * this.round2decimal(tonnage)
        );
    let environmentalLoadFeeAmount = 0;
    let isEnvironmentalLoadFee = false;
    let isFuelSurchardeFee = false;
    let fuelSurchardeFeeAmount = 0;
    let fuelSurchargeTotal = 0;
    let saleTaxFeeAmount = 0;
    const capoutPrice = this.round2decimal(
      this.round2decimal(yardage) *
        this.round2decimal(this.state.capoutPrice)
    );
    const tonnageCap = dumpRate <= 0 ? 0 : this.round2decimal(
      this.round2decimal(capoutPrice) / this.round2decimal(dumpRate)
    );
    const tonnageDifference = dumpRate <= 0 ? 0 : this.round2decimal(
      this.round2decimal(tonnageCap) - this.round2decimal(tonnage)
      );
    const isCapped = tonnageDifference < 0 ? "Yes" : "No";
    _total =
      isCapped === "Yes" &&
      // _.get(order, "customer.isCapoutPrice", false)
      this.state.isCapoutPrice
        ? this.round2decimal(capoutPrice)
        : _total;
    // When minimum amount is greater than the actual
      let isMinimumPrice = Number(_total) > 0 ? (Number(this.state.minimumPrice) > Number(_total)) : false
      const minimumPrice = isNaN(Number(this.state.minimumPrice)) ? 0 : this.state.minimumPrice
      _total = minimumPrice !== 0 && Number(_total) > 0 ? minimumPrice < _total ? this.round2decimal(_total) : this.round2decimal(minimumPrice) : this.round2decimal(_total);
   saleTaxFeeAmount += isDumpRateSalesTax || isMinimumPrice? this.calculateSalesTax(_total) : 0 ;
    for (let i = 0; i < additionalItems.length; i++) {
      if (additionalItems[i].quantity) {
        let additionalItemsamount = this.round2decimal(
          this.round2decimal(additionalItems[i].value) *
          this.round2decimal(additionalItems[i].quantity)
        );
        _total += additionalItemsamount
        saleTaxFeeAmount += additionalItems[i].saleTax ? this.calculateSalesTax(additionalItemsamount) : 0
      }
    }
    _total = isNaN(_total) ? 0 : this.round2decimal(_total);
    if (_.get(user, "company.isEnvironmentalLoadFee", false) && this.state.isEnvironmentalLoadFee) {
      isEnvironmentalLoadFee = _.get(
        user,
        "company.isEnvironmentalLoadFee",
        false
      );
      environmentalLoadFeeAmount = _.get(
        user,
        "company.environmentalLoadFeeAmount",
        0
      );
      _total = this.round2decimal(
        this.round2decimal(_total) +
          this.round2decimal(environmentalLoadFeeAmount)
      );
      saleTaxFeeAmount += this.calculateSalesTax(environmentalLoadFeeAmount) 
    }
    if (_.get(user, "company.isFuelSurchardeFee", false) && _.get(this.state,"isFuelSurchardeFee", false)) {
      isFuelSurchardeFee = _.get(user, "company.isFuelSurchardeFee", false);
      fuelSurchardeFeeAmount = 
        _.get(user, "company.fuelSurchardeFeeAmount", 0)
      fuelSurchargeTotal = this.round2decimal(
        fuelSurchardeFeeAmount / 100 *
          this.round2decimal(_total)
      );
      this.setState({
        fuelSurchargeTotal: this.round2decimal(fuelSurchargeTotal),
      });
      _total =
        this.round2decimal(_total) + this.round2decimal(fuelSurchargeTotal);
        saleTaxFeeAmount += this.calculateSalesTax(this.round2decimal(fuelSurchargeTotal)) 
    }
    _total+=saleTaxFeeAmount
    const _totalActualAmount = isNaN(_total) ? 0 : this.round2decimal(_total);
    _total = isNaN(_total) ? 0 : this.round2decimal(_total);
    let totalWithFee = { amount: _total, fee: 0, total: _total };
    let totalActualAmountWithFee = {
      amount: 0,
      fee: 0,
      total: this.round2decimal(_totalActualAmount),
    };
    let creditCard_Fee = {}
    let terminal_fee = {}
    let withoutStripeFee = _total
        
    if ((this.state.selectedSplitPaymentMethods.includes("credit-card") || this.state.selectedSplitPaymentMethods.includes("terminal"))) {
      let fee = 0
      const splitPayment = this.state.splitPayments.map(payment => ({ ...payment }));
      this.state.splitPayments.map((payment, index) => {
        if (payment.paymentMethod === "credit-card" && payment.isSelectedPayment && (payment.paymentAmount > 0 || (!this.state.isSplitPaymentOption && _total > 0))) {
          let Payment_Amount=this.state.isSplitPaymentOption ? payment.paymentAmount : _total
          if(_.get(user, "company.chargeStripeCardFees", false)){
            totalWithFee = this.calcFee(Payment_Amount, "USD");
            totalActualAmountWithFee = this.calcFee(Payment_Amount, "USD");
            const obj = { ...payment, paymentAmount: totalWithFee.total }
            splitPayment[index] = obj
            creditCard_Fee = this.calcFee(Payment_Amount, "USD");
            fee += totalWithFee.fee
          }else{
            const withoutFee={
              amount: this.round2decimal(Payment_Amount),
              fee: this.round2decimal(0),
              total: this.round2decimal(Payment_Amount),
            };
            const obj = { ...payment, paymentAmount: totalWithFee.total }
            splitPayment[index] = obj
            creditCard_Fee = withoutFee
            fee += totalWithFee.fee
          }
        }
        if (payment.paymentMethod === "terminal" && payment.isSelectedPayment && (payment.paymentAmount > 0 || (!this.state.isSplitPaymentOption && _total > 0))) {
          let Payment_Amount=this.state.isSplitPaymentOption ? payment.paymentAmount : _total
          if(_.get(user, "company.chargeStripeCardFees", false)){
          totalWithFee = this.calcFee(Payment_Amount, "USD");
          totalActualAmountWithFee = this.calcFee(Payment_Amount, "USD");
          const obj = { ...payment, paymentAmount: totalWithFee.total }
          splitPayment[index] = obj
          terminal_fee = this.calcFee(Payment_Amount, "USD");
          fee += totalWithFee.fee
          }else{
            const withoutFee={
              amount: this.round2decimal(Payment_Amount),
              fee: this.round2decimal(0),
              total: this.round2decimal(Payment_Amount),
            };
            const obj = { ...payment, paymentAmount: totalWithFee.total }
            splitPayment[index] = obj
            terminal_fee = withoutFee
            fee += totalWithFee.fee
          }
        }
        return null
      })
      totalWithFee.amount = _total
      totalWithFee.fee = fee
      totalWithFee.total = _total + fee
      totalActualAmountWithFee.amount = _total
      totalActualAmountWithFee.fee = fee
      totalActualAmountWithFee.total = _total 
      // this.setState({splitPayments:splitPayment})
    }
    _total=totalWithFee.total
    // let isMinimumPrice=(Number(this.state.minimumPrice) > Number(_total))
    // const minimumPrice = isNaN(Number(this.state.minimumPrice)) ? 0 : this.state.minimumPrice
    // _total =
    // minimumPrice !== 0
    // ? minimumPrice < _total
    // ? this.round2decimal(_total)
    // : this.round2decimal(minimumPrice)
    // : this.round2decimal(_total);

    // _total += saleTaxFeeAmount
    // totalWithFee.total = _total
    // totalWithFee.amount += saleTaxFeeAmount
    
    totalWithFee.fee = this.round2decimal(totalWithFee.fee + this.state.splitPaymentTotalHandlingFee)
    totalWithFee.total = this.round2decimal(totalWithFee.total + this.state.splitPaymentTotalHandlingFee)
    totalWithFee.amount= this.round2decimal(totalWithFee.amount + this.state.splitPaymentTotalHandlingFee) 
    totalActualAmountWithFee.fee = this.round2decimal(totalActualAmountWithFee.fee+ this.state.splitPaymentTotalHandlingFee)
    totalActualAmountWithFee.total = this.round2decimal( totalActualAmountWithFee.total+ this.state.splitPaymentTotalHandlingFee)
    totalActualAmountWithFee.amount = this.round2decimal(totalActualAmountWithFee.amount + this.state.splitPaymentTotalHandlingFee)
    if (this.state.isRoundOffAmount && this.state.availableOrderAmount === 0 && this.state.selectedSplitPaymentMethods.includes("cash") && this.state.selectedSplitPaymentMethods.length === 1) {
      let remaningAmount=this.state.remaingSplitAmount
      if( totalWithFee.total !== Math.ceil(totalWithFee.total) || totalActualAmountWithFee.total !== Math.ceil(totalActualAmountWithFee.total) || totalActualAmountWithFee.amount !== Math.ceil(totalActualAmountWithFee.amount) ||  totalWithFee.amount !== Math.ceil(totalWithFee.amount) || withoutStripeFee !== Math.ceil(withoutStripeFee) ){
        this.setState({isActualRoundOff:true})
      }else{
        this.setState({isActualRoundOff:false})
      }
      saleTaxFeeAmount +=  this.calculateSalesTax( Math.ceil(totalWithFee.total) - totalWithFee.total ) 
      totalWithFee.total = Math.ceil(totalWithFee.total)
      totalActualAmountWithFee.total = Math.ceil(totalActualAmountWithFee.total)
      totalActualAmountWithFee.amount = Math.ceil(totalActualAmountWithFee.amount)
      totalWithFee.amount = Math.ceil(totalWithFee.amount)
      withoutStripeFee = Math.ceil(withoutStripeFee)
      remaningAmount = this.round2decimal(Number(totalActualAmountWithFee.total - this.state.availableSplitAmount))
      this.setState({remaingSplitAmount:remaningAmount})
    }else{
      if(this.state.isRoundOffAmount && (this.state.availableOrderAmount > 0 || this.state.selectedSplitPaymentMethods.length > 0) && this.state.selectedSplitPaymentMethods.includes("cash")){
        this.state.splitPayments.map((payment)=>{
          if(payment.paymentMethod ==='cash' && payment.paymentAmount > 0 && payment.isSelectedPayment === true){
            if(payment.paymentAmount !== Math.ceil(payment.paymentAmount)){
              this.setState({isActualRoundOff:true})
            }else{
              this.setState({isActualRoundOff:false})
            }
            saleTaxFeeAmount +=  this.calculateSalesTax( Math.ceil(payment.paymentAmount) - payment.paymentAmount ) 
            totalWithFee.total = Number((totalWithFee.total - payment.paymentAmount).toFixed(2)) + Math.ceil(payment.paymentAmount)
            totalWithFee.amount =Number((totalWithFee.amount - payment.paymentAmount).toFixed(2)) + Math.ceil(payment.paymentAmount)
            withoutStripeFee = Number((withoutStripeFee - payment.paymentAmount).toFixed(2)) + Math.ceil(payment.paymentAmount)
            let remaningAmount= this.round2decimal(Number(totalWithFee.total - this.state.availableSplitAmount))
          this.setState({remaingSplitAmount:remaningAmount})
          }
          return null
        })
      }
        let remaningAmount= this.round2decimal(Number(totalActualAmountWithFee.total - this.state.availableSplitAmount))
          this.setState({remaingSplitAmount:remaningAmount})
    }
    if(!this.state.isSplitPaymentOption && totalWithFee.total > 0 && (this.state.selectedSplitPaymentMethods.includes("cash") || this.state.selectedSplitPaymentMethods.includes("check")) ){
      this.setState({ withOutSplitPaymentValidation:false})
    }
     this.setState({saleTaxFeeAmount:this.round2decimal(saleTaxFeeAmount)})
    return {
      netWeight,
      tonnage,
      dumpRate,
      creditCard_Fee,
      terminal_fee,
      isMinimumPrice: isMinimumPrice,
      totalActualAmount: isNaN(totalActualAmountWithFee.total)
        ? 0
        : totalActualAmountWithFee.total,
      total: isNaN(totalWithFee.total) ? 0 : totalWithFee.total,
      capoutPrice: isNaN(capoutPrice) ? 0 : capoutPrice,
      tonnageCap: isNaN(tonnageCap) ? 0 : tonnageCap,
      tonnageDifference: isNaN(tonnageDifference) ? 0 : tonnageDifference,
      isCapped,
      totalWithOutFee: withoutStripeFee,
      stripeFeeOnTotal: totalWithFee,
      stripeFeeOnTotalActualAmount: totalActualAmountWithFee,
      isEnvironmentalLoadFee,
      environmentalLoadFeeAmount,
      isFuelSurchardeFee,
      fuelSurchardeFeeAmount,
      fuelSurchargeTotal,
      saleTaxFeeAmount
    };
  }

  getPayload = ({ completed, yardage,saveAsDefaultOutboundWeight, ...values }) => {
    const {
      outboundWeight,
      outboundWeightDate,
      order,
      dumpRateType,
      notes,
      cityOfOrigin,
      serviceLocation,
      customerRefrence,
      additionalItems,
      pricingTierId,
      initialValues,
      customerData,
      truckTab,
      isSalesTax,
      isSaveAsDefaultOutboundWeight,
    } = this.state;
    const {
      netWeight,
      tonnage,
      dumpRate,
      total,
      capoutPrice,
      tonnageCap,
      tonnageDifference,
      stripeFeeOnTotal: { fee },
      isEnvironmentalLoadFee,
      environmentalLoadFeeAmount,
      isFuelSurchardeFee,
      fuelSurchardeFeeAmount,
      fuelSurchargeTotal,
      saleTaxFeeAmount,
      isMinimumPrice,
      totalActualAmount
    } = this.calculateTotalAmount({ values: { yardage, ...values } });
    const { plateNumber, number, truckType } = values;
    let isRoundOffAmount = false
    const containers = values?.container?.size ? [{ size: values?.container?.size }] : []
    let SplitPayment = []
    const selectedSplitPaymentMethods = this.state.selectedSplitPaymentMethods
    this.state.splitPayments.map((payment) => {
      if(this.state.isSplitPaymentOption){
        if (payment.paymentMethod !=="credit-card" && payment.paymentMethod !=="terminal" && selectedSplitPaymentMethods.includes(payment.paymentMethod) && payment.paymentAmount > 0) {
          let obj = {}
          if (!this.state.isPaymentLater) {
            if (payment.paymentMethod === "credit-memo") {
              obj = this.handleCreditAmount(payment)
              SplitPayment.push(obj)
            } else {
              if( this.state.isRoundOffAmount && payment.paymentMethod === "cash"){
                isRoundOffAmount=this.state.isActualRoundOff ? true : false
                obj = {
                  paymentType: payment.paymentMethod,
                  amount: Math.ceil(payment.paymentAmount)
                }
              }else{
                obj = {
                  paymentType: payment.paymentMethod,
                  amount: payment.paymentAmount
                }
              }
              SplitPayment.push(obj)
            }
          }
        }
    }else{
      if (payment.paymentMethod !=="credit-card" && payment.paymentMethod !=="terminal" && selectedSplitPaymentMethods.includes(payment.paymentMethod)) {
        let obj = {}
        if (!this.state.isPaymentLater) {
          if( this.state.isRoundOffAmount && payment.paymentMethod === "cash"){
            isRoundOffAmount=this.state.isActualRoundOff ? true : false
            obj = {
              paymentType: payment.paymentMethod,
              amount: Math.ceil(total)
            }
          }else{
            obj = {
              paymentType: payment.paymentMethod,
              amount: total
            }
          }
          SplitPayment.push(obj)
        }
      }
    }
      return null
    })
    const payload = {
      id: order._id,
      // completed: initialValues.completed,
      completed: completed,
      isPaymentDone: initialValues.isPaymentDone,
      isPaymentLater: initialValues.isPaymentLater,
      notes,
      yardage:yardage?.length > 0 ? parseInt(yardage) : yardage,
      outboundWeight:outboundWeight && outboundWeight !== "" ? outboundWeight : 0,
      outboundWeightDate,
      netWeight,
      tonnageCap,
      tonnageDifference,
      capoutPrice,
      tonnage,
      isMinimumPrice,
      splitPayment: SplitPayment,
      totalAmount: total,
      dumpRate,
      selectedReadingType: this.state.selectedReadingType,
      dumpRateType,
      paymentType: values.customer.paymentTerms === 'account' ? 'account' : '' ,
      paymentTerms: values.customer.paymentTerms,
      stripeCardFees: fee,
      cityOfOrigin,
      serviceLocation,
      customerRefrence: customerRefrence ? customerRefrence?.trim() : "",
      isEnvironmentalLoadFee,
      environmentalLoadFeeAmount,
      additionalItems,
      isFuelSurchardeFee,
      fuelSurchardeFeeAmount,
      fuelSurchargeTotal,
      isSalesTax : isSalesTax,
      salesTaxFeeAmount : saleTaxFeeAmount,
      salesTaxPercentageForOrder : this.state.saleTaxPercentageForOrder === "" ? 0 : this.state.saleTaxPercentageForOrder,
      saveAsDefaultOutboundWeight:this.state.order.saveAsDefaultOutboundWeight,
      pricingTierId,
      isRoundOffAmount:isRoundOffAmount,
      totalActualAmount: totalActualAmount,
      checkNumber:this.state.checkNumber,
      // signatureUrl: this.state.signatureUrl,
      pricingTierCapoutPrice:this.state.capoutPrice,
      nonRoundedTotalAmount:total,
      payabliInfo: !isEmpty(this.state.payabliInfo) ? this.state.customerPayabliData?.StoredMethods?.find((cust) => {return cust.IdPmethod === this.state.payabliInfo?.referenceId})  : this.state.customerPayabliData ? this.state.customerPayabliData?.StoredMethods?.find((cust) => {return cust.IdPmethod === this.state.selectedCardData}):{Method:'credit-card',MaskedAccount:this.state.last4},
      customerId:customerData?._id,
      truckId:truckTab === 2 ? null : values?.truck?._id ?? null,
      driverId:values?.driver?._id ?? null,
      projectId:values?.project?._id ?? null,
      plateNumber,
      number,
      type:truckType,
      containers,
      containerSize: String(values?.containerSize ?? ""),
      isSaveAsDefaultOutboundWeight,
      needSignature : false,
      isFailedPayment : false,
      isSplitPayment:this.state.isSplitPaymentOption
    };
    if(values?.defaultOutboundWeight?.length > 0){
      payload['defaultOutboundWeight'] = values?.defaultOutboundWeight?.replace(",", "")
    }
    if (isSaveAsDefaultOutboundWeight) {
      payload['containers'] = Array.isArray(containers) 
      ? containers?.map(container => {
        container['defaultWeight'] = Number(payload['outboundWeight'])
        return container
      })
        : { ...payload.containers, defaultWeight: Number(payload['outboundWeight']) }
        payload['defaultOutboundWeight'] = String(payload?.outboundWeight)
    }
    return payload;
  };

  getOrderInvoicePdf = async(ticketnumber) => {
    const { value } = await this.props.getOrderInvoicePdf(ticketnumber)
    if(value.status){
      window.open(value.url,'__blank')
    }
  }


  handleSubmit = async (values,formikProps, setSubmitting) => {
   if (!this.checkProjectValidation()) {     
     return;
    }
if (!this.checkOutboundWeight()) {
      return;
    }
    const { updateOrder, uploadImage } = this.props;
    const { order, user, loadImageUrl, plateImageUrl , isLEEDRequiredForCustomer , selectedProject,hideWieghtToggle } = this.state;
    if (
      !this.state.isPaymentLater && (
          (order.sustainabilityInfo.length < 1 && isLEEDRequiredForCustomer) ||
          (selectedProject?.isLEEDsRequired && order.sustainabilityInfo.length < 1)
      )
  ) {
      this.setState({
          isError: true,
          errorMessage: 'LEEDs/Sustainability information required to complete this order.',
          // submittingForm: false
      });
      setSubmitting({completeOrder: true})
  }
  else{
    setSubmitting({ completeOrder: true })
    this.setState({ submittingForm: true})
    const payload = this.getPayload({ ...values, completed: true, yardage: values?.yardage?.length > 0 ? parseFloat(values.yardage) : values?.yardage });
    if(!this.state.isSplitPaymentOption && this.state.splitPaymentTotalHandlingFee > 0){
          payload.stripeCardFees = Number(Number(this.state.splitPaymentTotalHandlingFee).toFixed(2));
    }else{
      if(this.state.isSplitPaymentOption){
       payload.stripeCardFees = Number(Number(this.state.splitPaymentTotalHandlingFee).toFixed(2));
      }
    }
    if (user?.company?.sisterCompany === undefined) {
        payload.hideWeight = hideWieghtToggle ? true : false
    }
    if(this.state.isPaymentLater){
      payload.isPaymentDone = false
      payload.completed = false
      payload.isPaymentLater = true
      // payload.saveAsDefaultOutboundWeight= saveAsDefaultOutboundWeight
    }
    if(!payload.isPaymentLater){
     this.setState({inProgressForCompleteOrder:true}) 
    }
    if (loadImageUrl) {
      const img1 = await uploadImage(this.submitImages(loadImageUrl));
      payload["loadImageUrl"] = img1.value.url;
    }
    if (plateImageUrl ) {
      if(!/^(https?:\/\/)/i.test(plateImageUrl)){
        const img1 = await uploadImage(this.submitImages(plateImageUrl));
        payload["plateImageUrl"] = img1.value.url;
      }else{
        payload["plateImageUrl"] = this.state?.plateImageUrl;
      }
    }
    // if(user.company.signatureRequired){
    //   payload.needSignature = true;
    // }
   
      const { value } = await updateOrder(payload);
      if (value?.status) {
        if (payload.isPaymentLater) {
          this.props.history.push({
            pathname: `/scale`,
          });
        } else {
          if (user?.company?.is_open_pdf_when_an_order_complete) {
            this.getOrderInvoicePdf(value?.ticketNumber).then(() => {
              this.props.location.pathname?.split('/')?.[1] === "pending-order-details" && this.state.is_scale_page_pre ?
                this.props.history.push({
                  pathname: `/order-details/${order.ticketNumber}`,
                  state: { ScalePage: true },
                })
                : this.props.history.push({
                  pathname: `/order-details/${order.ticketNumber}`,
                })
            })
          }
          else {
            this.props.location.pathname?.split('/')?.[1] === "pending-order-details" && this.state.is_scale_page_pre ?
              this.props.history.push({
                pathname: `/order-details/${order.ticketNumber}`,
                state: { ScalePage: true },
              })
              : this.props.history.push({
                pathname: `/order-details/${order.ticketNumber}`,
              })
          }
        }
    }
  }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
    this.client = null;
    this.terminal = null;
    clearInterval(interval);
  }

  //add card for payabli
  assignCardData = async (cardData) => {
    this.setState({ payabliInfo: cardData, isCardModalOpen: false });
    const payload = {
      _id: this.state.customerData._id,
      payabliCustomer: cardData,
    };
    await this.props.updateCustomer(payload);
    this.setState({ cardAdded: false });
    this.getCustomerPayment(cardData?.customerId);
  };
  isSalesTax(saleTaxPercentageForOrder){
    const {isSalesTax}=this.state
    if (isSalesTax) {
      if(saleTaxPercentageForOrder > 100){
        this.setState({ errisSalesTax: "Sales Tax cannot be more than 100%." });
      }
      if(saleTaxPercentageForOrder <= 100 ){
        this.setState({ errisSalesTax: "" });
      }
      }
    }
  //get payabli data
  getCustomerPayment = async (customerId) => {
    const payload = {
      customerId: customerId,
    };
    const {
      value: { data },
    } = await this.props.getPayabliCustomerData(payload);
    this.setState({ customerPayabliData: data });
  };

  //adding / for exp date
  insertSlash = (val) => {
    return val.replace(/^(\d{2})(\d{2})/, "$1/$2");
  };

  showDrawer = async (type) => {
    if(!this.state.itemsDisabled){
      this.setState({ visible: true });
    }
    switch (type) {
      case 'company':
        this.fetchCustomers();
        break;

      case 'project':
        await this.fetchProject();
        break;
    
      case 'driver':
        await this.fetchDrivers();
        break;
    
      case 'truck':
        await this.fetchTrucks();
        break;
    
      default:
        break;
    }
  };

  handleOutsideClick = (event) => {
    const div = this.creditMemoRef.current;
    if (div && !div.contains(event.target)) {
      this.setState({ creditMemoEnabled: true })
    }
  };

  fetchCustomerCreditMemo = async (customerId) => {
    let totalcreditmemosAmount = 0
    const { getCustomercredits } = this.props
    const {
      value: {
        data: { customercredits }
      }
    } = await getCustomercredits({ customerId: customerId, skip: 0, limit: 0 });
    for (let creditMemo of customercredits) {
      totalcreditmemosAmount = totalcreditmemosAmount + Number(creditMemo?.receiveCreditAmount)
    }
    this.setState({ customerCreditsMemos: customercredits, totalcreditmemosAmount: totalcreditmemosAmount })
  }

  fetchCustomerCreditCards = async (customerId) => {
    const { fetchPaymentMethodCards } = this.props
    const {
      value: {
        data
      }
    }  = await fetchPaymentMethodCards(customerId);
    this.setState({customerCreditCards : data})
  }

  fetchCustomers = async () => {
    const { fetchCustomers } = this.props;
    const { companySearchText, sortOrder } = this.state;
    const {
      value: {
        data: { customers }
      }
    } = await fetchCustomers({ searchText: companySearchText ?? "", status: 1, sortOrder, limit, isDisableCount: true });
    this.setState({ customers });
  };

  selectCustomer = customer => {
    this.assignDefaultCustomerDataInPaymentTerms(customer)
    this.setState({ selectedCustomer: customer, selectedTruckSearch: null , errorMessage : ""});
  };

  assignDefaultCustomerDataInPaymentTerms = (selectedCustomer) => {
    let isSplitPaymentOption = this.state.isSplitPaymentOption
    let selectedSplitPaymentMethods = _.clone(this.state.selectedSplitPaymentMethods)
    let splitPayment = _.clone(this.state.splitPayments)
    if (selectedCustomer && selectedCustomer.paymentTerms === "cod" && selectedCustomer.paymentType) {
      if (selectedSplitPaymentMethods && !(selectedSplitPaymentMethods.length > 0 && !selectedSplitPaymentMethods.includes(selectedCustomer.paymentType))) {
        selectedSplitPaymentMethods=[selectedCustomer.paymentType]
      }
    }else{
      selectedSplitPaymentMethods=[]
      splitPayment=[]
      isSplitPaymentOption=false
    }
    if(selectedSplitPaymentMethods.length > 0){
    splitPayment.map((payment,index) => {
      if (payment.paymentMethod === selectedCustomer.paymentType) {
        splitPayment[index].isSelectedPayment = true
      }
      return null;
    })
  }
    this.setState({ selectedSplitPaymentMethods: selectedSplitPaymentMethods, splitPayment: splitPayment,isSplitPaymentOption })
  }

  assignCustomer = () => {
    const { initialValues, selectedCustomer } = this.state;
    this.setState(
      {
        customer: selectedCustomer,
        selectedTruck:{},
        isCreateDrawerOpen: false,
        isCompany: false,
        selectedProject:{},
        isDriver: false,
        isTruck: false,
        visible: false,
        initialValues: {
          ...initialValues,
          customer: selectedCustomer,
          driver: null,
          defaultOutboundWeight:"",
          project :null,
          containers:{},
          truck:null
        },
        containers:{},
        outboundWeight:this?.state?.storedOutboundWeight,
        driver: null,
        project:null,
        additionalItems:[],
        customerData: selectedCustomer,
        isFuelSurchardeFee:selectedCustomer?.isFuelSurchardeFee,
        isEnvironmentalLoadFee:selectedCustomer?.isEnvironmentalLoadFee,
        isLEEDRequiredForCustomer: selectedCustomer?.isLEEDsRequired,
        selectedDriver:{},
         },
      async() => {
        if (selectedCustomer) {
          this.fetchCustomerCreditMemo(selectedCustomer._id)
          this.fetchCustomerCreditCards(selectedCustomer._id)
          this.assignDefaultCustomerDataInPaymentTerms(selectedCustomer)
          this.fetchDrivers();
          this.fetchTrucks();
          this.fetchProject();
          this.isDelinquentAccount();
          this.handleCompanySearch({ target: { value: "" } })
          const pricingTier= await this.assignPricingTier(selectedCustomer?.pricingTier);
          this.setDumpRate(
            _.get(pricingTier, "pricing.dumpRates.defaultOption", "")
          );
          this.handleSelectCard({target:{value: selectedCustomer.paymentType === "" ? "cash" : selectedCustomer.paymentType }})
        }
        this.setAsyncState({outboundWeight:this.state.usingDefault ? this.state.order.defaultOutboundWeight : this.state.outboundWeight})
      }
      );
  };
  
  selectDriver = obj => {
    this.setState({ selectedDriver: obj,selectedTruckSearch:null });
  };
 
selectedProject=obj=>{
  this.setState({selectedProject:obj  , errorMessage : ""})
}

assignProjects = () => {
  const { initialValues,selectedProject,selectedDriver } = this.state;
  this.setState({
    driver: selectedDriver,
    isCreateDrawerOpen: false,
    isCompany: false,
    isDriver: false,
    isProjectDrawerOpen:false,
    isTruck: false,
    visible: false,
    selectedTruckSearch:null,
    initialValues: {
      ...initialValues,
      project: selectedProject,
    },
    projectSearchText:"",
  });
  this.handleProjectSearch({ target: { value: "" } })
};
  assignDriver = () => {
    const { initialValues, selectedDriver } = this.state;
    this.setState({
      driver: selectedDriver,
      isCreateDrawerOpen: false,
      isCompany: false,
      isDriver: false,
      isTruck: false,
      visible: false,
      selectedTruckSearch:null,
      initialValues: {
        ...initialValues,
        driver: selectedDriver,
      },
    });
    this.handleDriverSearch({ target: { value: "" } })
  };


  selectTruck = obj => {
    this.setState({ selectedTruck: obj });
  };

  assignTruck = () => {
    const { initialValues, selectedTruck } = this.state;
    initialValues.truck = selectedTruck;
    if(selectedTruck.containers.length > 0){
      const containers = selectedTruck.containers.sort((a, b) => String( a.size) - String(b.size));
      selectedTruck.containers = containers
    }
    this.setState({
      truck: selectedTruck,
      isCreateDrawerOpen: false,
      isCompany: false,
      visible: false,
      isDriver: false,
      outboundWeight:this?.state?.storedOutboundWeight,
      saveAsDefaultOutboundWeight:false,
      order: { ...this.state.order, saveAsDefaultOutboundWeight: false },
      isTruck: false,
      initialValues: {
        ...initialValues,
        defaultOutboundWeight: selectedTruck.defaultOutboundWeight ? selectedTruck.defaultOutboundWeight : "",
      },
    });
    this.handleTruckSearch({ target: { value: "" } })
    if(selectedTruck.containers.length > 0){
      this.setState({ initialValues: {
        ...initialValues,
        defaultOutboundWeight: selectedTruck.containers[0].defaultWeight,
        containers:selectedTruck.containers.length > 0 ? selectedTruck.containers[0] : {},
        containerSize:selectedTruck.containers.length > 0 ? String(selectedTruck.containers[0]?.size) : "",
      }})
    }
  };

  fetchDrivers = async () => {
    this.setState({driversLoader : true })
    const { fetchDriversByCustomerId } = this.props;
    const { driverSearchText } = this.state;
    const {
      value: { data }
    } = await fetchDriversByCustomerId({
      customerId: this.state.customer._id,
      searchText: driverSearchText
    });
    this.setState({ drivers: data, driversLoader : false});
  };

  fetchTrucks = async () => {
    const { fetchTrucksByCustomerId } = this.props;
    const { truckSearchText } = this.state;
    const {
      value: { data }
    } = await fetchTrucksByCustomerId({
      customerId: this.state.customer._id,
      searchText: truckSearchText
    });
    const values = this.state.initialValues?.truck
    let macthData = {type:values?.type !== undefined ? values?.type : data?.[0]?.type, plateNumber: values?.plateNumber !== undefined ? values?.plateNumber: data?.[0]?.plateNumber, number: values?.number !== undefined ? values?.number: data?.[0]?.number  }
    const {truckHere,containers} = this.checkTrucks(macthData,data)
    if(truckHere.length > 0){
      this.setState({containersFromInput:containers})
    }
    this.setState({ trucks: data });
  };

  fetchProject=async()=>{
    this.setState({projectSpinning:true})
    const payload={
      id: this.state.customer?._id,
      searchText:this.state.projectSearchText,
     }
    const {
      value: {
        data
      }
    } = await this.props.fetchProjects(payload);
    this.setState({ projectsData:data,projectSpinning:false});
  }

  onClose = () => {
    this.setState({
      visible: false,
      rotation: 0,
      licenseImageUrl: "",
      isLicenseCamera: true,
      een_data: [],
      een_count: 0,
      // selected_een: null,
      companySearchText: "",
      projectSearchText: "",
      driverSearchText: "",
      truckSearchText: "",
      page: 1,
     }, () => {
        if(this.state?.is_een_lplates_drawer) {
          this.observer.disconnect();
          clearInterval(this.state.fetch_een_lplates_interval)
        }
    })
  };

  async setInitialState({ state: { firstName, lastName, licenseNumber, formdata, state } }) {
    const { driverInitialValues } = this.state;
    if (formdata !== "") {
      const licenseImageUrl = await this.convertBlobToBase64(formdata);
      this.setState({ licenseImageUrl:licenseImageUrl, isLicenseCamera: false });
    }
    this.setState({
      createDriverForm: true,
      createDriverSidebar: false,
      driverInitialValues: { ...driverInitialValues, firstName, lastName, licenseNumber , state}
    });
  }

  convertBlobToBase64(blob) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
        var base64data = reader.result;
        return resolve(base64data);
      };
    });
  }

  onTakePhoto = async (dataUri, field, field2) => {
    this.setState({ [field]: dataUri, [field2]: false });
  };

  handleChargeCardState = () => {
    this.setState({ isChargeCard: false });
  };

  async onCropImage(blob) {
    const licenseImageUrl = await this.convertBlobToBase64(blob);
    this.setState({ licenseImageUrl: licenseImageUrl });
  }
  submitDriverForm = async (values, { setErrors }) => {
    try {
      const {
        customer: { _id },
        licenseImageUrl,
        rotation,
      } = this.state;
      let url = "";
      if (licenseImageUrl) {
        const uploadImage = await this.props.uploadImage(
          this.submitImages(await this.compressImage(licenseImageUrl, 0.7 , rotation))
        );
        url = uploadImage?.value?.url?.length > 0
         ? uploadImage?.value?.url 
         : licenseImageUrl;
      }
      const payload = {
        ...values,
        customerId: _id,
        licenseImageUrl: url,
        rotation,
      };
      const {
        value: { status, message },
      } = await this.props.createDriver(payload);
      if (status) {
        this.setState({
          createDriverSidebar: false,
          isDriver: true,
          createDriverForm: false,
          licenseImageUrl: "",
          rotation: 0,
        });
        this.fetchDrivers();
      } else {
        setErrors({ state: message });
      }
    } catch (err) {
      setErrors({ state: "Upload driver license image" });
    }
  };

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    return formdata;
  }
 
  compressImage = async (imageUrl, quality, rotation) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; 
      img.src = imageUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        if (rotation !== 0) {
          canvas.width = img.width;
                canvas.height = img.height;
                ctx.fillStyle = 'white'; 
                ctx.fillRect(0, 0, canvas.width, canvas.height); 
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((rotation * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
  
        const compressedImageUrl = canvas.toDataURL("image/jpeg", quality);
        resolve(compressedImageUrl);
      };
  
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  dataURItoBlob = dataURI => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  setTruckTab = tab => {
    const { initialValues } = this.state;
    let values = {};
    const outboundWeight = this.state.usingDefault ? 0 : this.state.outboundWeight || this.state.order.defaultOutboundWeight;
    if (tab === 2) {
      this.setState({plateImageUrl: "",})
      const truckData = JSON.parse(localStorage.getItem("truckData"));
      values = {
        ...initialValues,
        number: _.get(truckData, "initialValues.number", ""),
        plateImageUrl: _.get(truckData, "plateImageUrl", ""),
        plateNumber: _.get(truckData, "initialValues.plateNumber", ""),
        truckType: _.get(truckData, "initialValues.truckType", "Box Truck"),
        defaultOutboundWeight: _.get(truckData, "initialValues.defaultOutboundWeight", ""),
        containerCheck:true,
        containers:{},
        truck:{}
      };
      this.fetchContainers()
      this.setState({truck:null, containersFromInput: []})
    } else {
      const { customer, driver, inboundWeightDate, inboundWeight, reading_type } = initialValues;
      values = {
        ...initialValues,
        customer,
        driver,
        inboundWeightDate,
        inboundWeight,
        reading_type,
        containers:{},
        ...this.state.selectedTruck
      };
      const truck = this.state.selectedTruck;
      if(truck){
        values.defaultOutboundWeight = this.state.usingDefault ? this.state.defaultOutboundWeight ?? "" : truck?.defaultOutboundWeight ?? ""
        values.containers = truck.containers
        values.containerSize = String(truck.containerSize) 
        values.truck = truck
        this.setState({truck})
      }
    }
    this.setState({ truckTab: tab, initialValues: values,containers:{} ,outboundWeight});
  };

  checkOutboundWeight = () => {
    if (this.state.outboundWeight <= 0) {
      this.setState({ errorMessageoutobound: 'Outbound weight should be greater than zero' });
      return false;
    }
    this.setState({ errorMessageoutobound: '', outboundWeight:this.state.outboundWeight });
    return true;
  };

  checkProjectValidation = () => {
    if ((this.state.selectedProject === undefined || Object.keys(this.state.selectedProject).length === 0) && this.state.customer.isProjectRequired) {
      this.setState({ errorMessageProject: 'Project Required' });
      return false;
    }    
    this.setState({ errorMessageProject: '' });
    return true;
  };
  
  fetchContainers = async () => {
    const { fetchContainers } = this.props;
    const {
      value: {
        data: { containers }
      }
    } = await fetchContainers();
    const allContainers = containers.map(v => ({...v,defaultWeight:""}))
    this.setState({ allContainers });
  };

  checkAlready = (size)=>{
    const containers = this.state.containersFromInput.find(c => String(c.size) === String(size)) 
    return containers ? true : false
  }

  setValues(e, values, setValues) {
    const {name , value} = e.target
    let macthData = { type:values?.type, plateNumber:values?.plateNumber, number:values?.number  }
    macthData = {...macthData,[name]:value}
    const {truckHere,containers} = this.checkTrucks(macthData,this.state.trucks)
    let initialValues = this.state.initialValues
    if(truckHere.length > 0){
      if(containers.some(c => String(c.size) === String(this.state.containers.size))){
        this.setState({containers:{}})
        values.defaultOutboundWeight = name !== "defaultOutboundWeight" && ""
        initialValues.defaultOutboundWeight = name !== "defaultOutboundWeight" && ""
      }
      this.setState({containersFromInput:containers})
    }else{
      this.setState({containersFromInput:[]})
    }
    this.setState({ initialValues: { ...initialValues, [e.target.name]: e.target.value } });
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  checkTrucks = (macthData,trucks) => {
    const truckHere = trucks?.filter(t => t.type === (macthData?.truckType?.length > 0 ? macthData?.truckType : this.state?.initialValues?.truckType)
       && t?.plateNumber === macthData?.plateNumber && t?.number === macthData?.number
       )
    let containers = []
    truckHere.map((v)=> {
      containers = [...containers,...v.containers]
      return null
    })
    return {truckHere,containers}
  }
  
  changeContainer = (e,setValues,values,truckHere)=>{
  if(e === ""){
    setValues({
      ...values,
      defaultOutboundWeight:"",
      containers:{},
      containerSize:""
    });
    this.setState({
      initialValues: { ...this.state.initialValues, defaultOutboundWeight:"", containers:{},containerSize:""},containers:{},outboundWeight:this.state.storedOutboundWeight,
      saveAsDefaultOutboundWeight:false,
      order: { ...this.state.order, saveAsDefaultOutboundWeight: false }
    });
  }else{
    const container = truckHere ? values.truck?.containers?.filter(c => String(c.size) === String(e)) : this.state.allContainers.filter(c => String(c.size) === String(e))
    setValues({
      ...values,
      defaultOutboundWeight:container[0]?.defaultWeight,
      containers:container,
      containerSize: String(container?.[0]?.size ?? "")
    });
    this.setState({
      initialValues: { ...this.state.initialValues, defaultOutboundWeight:container[0]?.defaultWeight,containers:container[0], containerSize:container?.[0]?.size ?? ""  },containers:container[0],
      outboundWeight:this.state.storedOutboundWeight,
      saveAsDefaultOutboundWeight:false,
      order: { ...this.state.order, saveAsDefaultOutboundWeight: false } ,// Set to false when container changes
      containerSize: String(container?.[0]?.size ?? "") 
    });
  }
  }

  assignPricingTier = async (pricingTierId) => {
    let DumpRatesData = {};
    let additionalItems = [];
    const {
      value: { data, status },
    } = await this.props.getPricingTierById(pricingTierId);
    if (status) {
     let pricingTier = data;
      const dumpRatesObject = data?.pricing?.dumpRates;
      const dumpRatesSaleTax = data?.salesTaxPricingTier?.dumpRates;
      const dumratesArray = Object.keys(data?.pricing?.dumpRates);
      dumratesArray.forEach((item) => {
        if (dumpRatesObject[item] > 0) {
          DumpRatesData[`${item}`] = dumpRatesObject[item];
        }
      });
      let Add = pricingTier?.pricing?.additionalItems;
      Add.forEach((item) => {
        if (item?.value > 0) {
          additionalItems.push(item);
        }
      });
      if (Number(pricingTier?.pricing?.capoutPrice) > 0) {
        this.setState({ isCapoutPrice: true });
      }else{
        this.setState({ isCapoutPrice: false });
      }
      this.setState({
        pricingTierDumpRates: this.sortPricingTier(dumpRatesObject),
        dumpRatesSaleTax: this.sortPricingTier(dumpRatesSaleTax),
        additionalItemsData: additionalItems,
        capoutPrice: Number(pricingTier?.pricing?.capoutPrice),
        minimumPrice: Number(pricingTier?.pricing?.minimumPrice),
        pricingTier: pricingTier?._id,
        pricingTierName: pricingTier?.pricing?.pricingTier,
        pricingTierId: pricingTierId,
      });
      return pricingTier;
    }
  }
  sortPricingTier = (data) => {
    // Convert the object into an array of [key, value] pairs
    const filteredDataPerTon = Object.entries(data).filter(([key, value]) => key.startsWith("pricePerTon") && value !== null);
    const sortedDataPerTon = Object.fromEntries(filteredDataPerTon)
    const filteredDataPerYard = Object.entries(data).filter(([key, value]) => key.startsWith("pricePerYard") && value !== null);
    const sortedDataPerYard = Object.fromEntries(filteredDataPerYard)
    const filteredData = Object.entries(data).filter(([key, value]) => !key.includes('pricePer') && value !== null);
    const sortedDataWithoutPer = Object.fromEntries(filteredData)
    const sortedDataTonYard = Object.assign({ ...sortedDataPerTon, ...sortedDataPerYard })
    const sortedData = Object.assign({ ...sortedDataTonYard, ...sortedDataWithoutPer })
    return sortedData;
  }

  fetch_een_lplates = async (update_page) => {
    this.setState({een_loading: true})
    const { pageSize, page, een_data } = this.state
    try {
      const {value} = await this.props.get_een_lplates_data({companyId: this.state.user?.companyId, page: update_page ? (page + 1) : page})
      if(value?.status){
        const unique_license_data = new Set(een_data?.map(item => item?.data?.event?.reading ))
        const filtered_data = value?.data?.filter(item => !unique_license_data?.has(item?.data?.event?.reading))
        const start_index = (page - 1) * pageSize
        const sliced_data = page > 1 ? [...filtered_data, ...een_data] : [...filtered_data, ...een_data]?.slice(start_index, start_index + pageSize)
        this.setState({
          een_data: update_page ? [...een_data, ...filtered_data] : sliced_data,
          page: update_page ? (page + 1) : page,
          een_count: value?.total
        })
      }
    } catch (error) {
      console.log(error, "<<error")
    }
    this.setState({ een_loading: false })
  }

  handle_inter_section = (entries) => {
    const {page, pageSize, een_count} = this.state
    if (entries[0]?.isIntersecting && this.state?.een_data?.length > 0 && (page * pageSize) < een_count) {
     this.fetch_een_lplates(true);
    }
  };

  handleDriverSearch = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      driverSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchDrivers();
      }, 200),
    });
  };

  handleProjectSearch = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      projectSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchProject();
      }, 200),
    });
  };

  handleTruckSearch = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      truckSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchTrucks();
      }, 200),
    });
  };

  handleCompanySearch = (event) => {
    this.setState({
      companySearchText: event.target.value
    }, () => this.fetchCustomers());
  };


  handleSelectPaymentMethod = (paymentMethodType, setSubmitting) => {
    const { selectedSplitPaymentMethods, splitPayments } = this.state;
    this.setState({ isError: false })
    const updatedPaymentMethods = selectedSplitPaymentMethods.includes(paymentMethodType)
      ? selectedSplitPaymentMethods.filter((method) => method !== paymentMethodType)
      : [...selectedSplitPaymentMethods, paymentMethodType];
    const updatedSplitPayments = splitPayments.map((payment) => {
      if (payment.paymentMethod === paymentMethodType) {
        payment = { ...payment, paymentAmount: null, isSelectedPayment: false };
      }
      if (updatedPaymentMethods.includes(payment.paymentMethod)) {
        payment = { ...payment, isSelectedPayment: true };
      }
      return payment;
    });
    if (updatedPaymentMethods.every((val) => (val === "cash" || val === "check"))) {
      setSubmitting({ completed: true, isPaymentLater: true, isPaymentDone: true })
    }
    if(paymentMethodType === "credit-memo"){
    this.setState({selectedCreditMemo:[],remainingCreditAmount:null})
    }
    this.setState({
      selectedSplitPaymentMethods: updatedPaymentMethods,
      splitPayments: updatedSplitPayments,
    }, () => { this.handleSelectCard({ target: { value: paymentMethodType } }) });
    return updatedPaymentMethods;
  };


  handleSelectPaymentAmount = (paymentMethodType, amount, totalWithOutFee) => {
    let remaningAmount = 0
    let availableAmount =  this.state.availableOrderAmount
    const { splitPayments } = this.state;
    const numericAmount = amount === "" || amount < 0 ? null : parseFloat(amount);
    let splitMethodPayments = splitPayments.map(payment => ({ ...payment }));
    for (let i = 0; i < splitMethodPayments.length; i++) {
      let payment = splitMethodPayments[i];
      if (payment.paymentMethod === paymentMethodType) {
        splitMethodPayments[i].paymentAmount = numericAmount
      }
      availableAmount = Number((Number(availableAmount) + Number(splitMethodPayments[i].paymentAmount)).toFixed(2))
    }
    if ((paymentMethodType === "credit-memo") && (Number(this.state.totalSelectedAmount) >= Number(amount)) && (Number(availableAmount) <= Number(totalWithOutFee))) {
      remaningAmount = Number((Number(totalWithOutFee) - Number(availableAmount)).toFixed(2))
      this.setState({
        splitPayments: splitMethodPayments,
        remainingCreditAmount: Number(this.state.totalSelectedAmount) - Number(amount),
        availableSplitAmount: availableAmount, 
        remaingSplitAmount: remaningAmount,
        errorMessage:""
        // isError: false,
      })
    } else if ((paymentMethodType !== "credit-memo") && (Number(availableAmount) <= Number(totalWithOutFee))) {
      remaningAmount = Number((Number(totalWithOutFee) - Number(availableAmount)).toFixed(2))
      this.setState({
        splitPayments: splitMethodPayments,
        availableSplitAmount: availableAmount,
        remaingSplitAmount: remaningAmount,
        // errorMessage:""
        // isError: false,
      })
    }
    // else{
    //   this.setState({
    //     isError: true,
    //     errorMessage: "Unable to pay this amount as it would exceed the total."
    //   });
    // }
    this.setState({ reRender: !this.state.reRender })
  }

  selectCreditMemo = async (creditMemoNumber) => {
    let totalSelectedAmount = null
    let customerCreditsMemos = [...this.state.customerCreditsMemos]
    let selectedCreditMemo = [...this.state.selectedCreditMemo]
    if (selectedCreditMemo.includes(creditMemoNumber)) {
      // console.log(totalSelectedAmount)
      // for(let creditMemo of customerCreditsMemos){
      //   if(creditMemoNumber === creditMemo.creditMemoNumber){
      //     totalSelectedAmount -= Number(creditMemo.receiveCreditAmount)
      //   }
      // }
      const index = selectedCreditMemo.indexOf(creditMemoNumber);
      selectedCreditMemo.splice(index, 1);
      // selectedCreditMemo.pop(creditMemoNumber)
    } else {
      selectedCreditMemo.push(creditMemoNumber)
    }
    for (let creditMemo of customerCreditsMemos) {
      if (selectedCreditMemo.includes(creditMemo.creditMemoNumber)) {
        totalSelectedAmount += Number(creditMemo.receiveCreditAmount)
      }
    }
    this.setState({ selectedCreditMemo: selectedCreditMemo, totalSelectedAmount: totalSelectedAmount, remainingCreditAmount: totalSelectedAmount })
  }

  handleCreditAmount = (payment) => {
    let selectedCreditMemo = _.clone(this.state.selectedCreditMemo)
    let customerCreditsMemos = _.clone(this.state.customerCreditsMemos)
    let totalCreditAmountForId = null
    let remaingamount = null
    let credit_ids = []
    for (let credit of customerCreditsMemos) {
      if (selectedCreditMemo.includes(credit.creditMemoNumber)) {
        totalCreditAmountForId += credit.receiveCreditAmount
        remaingamount = Number(payment.paymentAmount) - Number(totalCreditAmountForId)
        credit_ids.push(credit._id)
        if (remaingamount <= 0) {
          break;
        }
      }
    }
    return {
      paymentType: "credit-memo",
      amount: payment.paymentAmount,
      creditMemoIds: credit_ids
    }
  }

  handleSignCallback(data) {
    this.setState({ driverSignature: data?.signatureUrl });
  }

  async handleWeightToggle (e)  {
    if (e) {
      this.setState({ hideWieghtToggle: true });
    }else{
      this.setState({ hideWieghtToggle: false });
    }
  }


  handleCompletOrdeAndSplitPayment({values,formikProps,setSubmitting,submitType,order}){
     if(this.state.driverSignature === "" && (this.state.order?.driverSignature === "" || this.state.order?.driverSignature === undefined) && !this.state.isPaymentLater && this.props.user?.company?.signatureRequired && this.props.user?.company?.isSignaturePadIntegration) {
        this.setState({ signErrorMessage: "Driver signature required" })
        return false;
      }else{
        if (this.state.customer.paymentTerms !== "account" && !this.state.isPaymentLater) {
          if (submitType === "card") {
            if (this.SplitPaymentValidation("credit-card",values)) {
              this.chargeCustomer({ setSubmitting, values });
            }
          } else {
            if (submitType === "terminal" && this.SplitPaymentValidation("terminal",values)) {
              this.chargeOnDevice({ setSubmitting, values });
            } else {
              const selectedMethods = this.state.selectedSplitPaymentMethods;
              const creditMemoSelected = selectedMethods.includes("credit-memo");
              const cashSelected = selectedMethods.includes("cash");
              const checkSelected = selectedMethods.includes("check");

              if ((cashSelected || checkSelected || creditMemoSelected)) {
                // when user Choose Only credit memo
                if (!cashSelected && !checkSelected && creditMemoSelected && this.SplitPaymentValidation("credit-memo",values)) {
                  this.handleSubmit(values, formikProps, setSubmitting);
                }
                // when user choose  credit memo , cash and check
                if ((cashSelected || checkSelected) && creditMemoSelected &&
                  this.SplitPaymentValidation("credit-memo") &&
                  this.SplitPaymentValidation("cash-check")) {
                  this.handleSubmit(values, formikProps, setSubmitting);
                }
                // when user choose cash and check
                
                if (!creditMemoSelected && this.SplitPaymentValidation("cash-check",values)) {
                  this.handleSubmit(values, formikProps, setSubmitting);
                }
                //  else {
                //   if (!creditMemoSelected && this.SplitPaymentValidation("", values)) {
                //     this.handleSubmit(values, formikProps, setSubmitting);
                //   }
                // }
              }else{
              if(this.SplitPaymentValidation("",values)){
                this.handleSubmit(values, formikProps, setSubmitting);
              }
              }
            }
          }
        } else {
        this.handleSubmit(values, formikProps, setSubmitting);
      }
       }
  }

  handleCompleteOrderWithOutSplitpayment({values,formikProps,setSubmitting,submitType,order}){
    if(this.state.driverSignature === "" && (this.state.order?.driverSignature === "" || this.state.order?.driverSignature === undefined) && !this.state.isPaymentLater && this.props.user?.company?.signatureRequired && this.props.user?.company?.isSignaturePadIntegration) {
      this.setState({ signErrorMessage: "Driver signature required" })
      return false;
    }else{
      if(submitType === "credit-card"){
        this.chargeCustomer({ setSubmitting, values });
        return true
      } 
      if(submitType === "terminal"){
        this.chargeOnDevice({ setSubmitting, values });
        return true
      }
      if (submitType === "submit") {
        if ((this.state.customer?.paymentTerms === "account")) {
          this.handleSubmit(values, formikProps, setSubmitting);
          return true
        } else if ((this.state.selectedSplitPaymentMethods.length > 0 && this.state.selectedSplitPaymentMethods[0] === '')) {
          this.setState({ isError: true, errorMessage: "Please select atleast one payment type" })
        } else {
          this.handleSubmit(values, formikProps, setSubmitting);
          return true
        }
      }
    }
  }

  handleWithOutSplitPaymentMethod(paymentType,stripeFeeOnTotal){
    let SplitPayment = _.clone(this.state.splitPayments)
    let selectedSplitPaymentMethods = _.clone(this.state.selectedSplitPaymentMethods)
      selectedSplitPaymentMethods = [paymentType]
      SplitPayment.map((payment,index)=>{
        if(payment.paymentMethod === paymentType){
          if(payment.paymentMethod === paymentType && paymentType !== "credit-card" && paymentType !=="terminal"){
            SplitPayment[index].isSelectedPayment = true
            SplitPayment[index].paymentAmount = stripeFeeOnTotal.total
          }else{
            if(payment.paymentMethod === paymentType && paymentType ==="credit-card"){
              SplitPayment[index].isSelectedPayment = true
              SplitPayment[index].paymentAmount = stripeFeeOnTotal.total
              SplitPayment[index].handlingFee = stripeFeeOnTotal.fee
            }
            if(payment.paymentMethod === paymentType && paymentType === "terminal"){
              SplitPayment[index].isSelectedPayment = true
              SplitPayment[index].paymentAmount = stripeFeeOnTotal.total
              SplitPayment[index].handlingFee = stripeFeeOnTotal.fee
            }
          }
        }else{
          SplitPayment[index].isSelectedPayment = false
            SplitPayment[index].paymentAmount = null
        }
        return null
      })
      this.handlewithOutsplitValidation(paymentType)
      this.setState({splitPayments:SplitPayment,selectedSplitPaymentMethods:selectedSplitPaymentMethods,isError:false,errMessage:"" , responseMessageForTerminal: ""})
  }

  handlewithOutsplitValidation(paymentType){
    if(paymentType ==="credit-card" || paymentType === "terminal"){
      this.setState({withOutSplitPaymentValidation:true})
    }else{
      this.setState({withOutSplitPaymentValidation:false})
    }
  }

  handlePaymentOptions(stripeFeeOnTotalActualAmount){
    let isSplitPaymentOption = !this.state.isSplitPaymentOption
    this.setState({isSplitPaymentOption:isSplitPaymentOption})
    let splitPayment = this.state.splitPayments
    let selectedSplitPaymentMethods =this.state.selectedSplitPaymentMethods
    if(isSplitPaymentOption){
    splitPayment.map((payment,index)=>{
      if(payment.paymentMethod === this.state.customerData.paymentType){
        if(payment.paymentMethod === this.state.customerData.paymentType){
          selectedSplitPaymentMethods = [payment.paymentMethod]
          if(payment.paymentMethod === "credit-card" || payment.paymentMethod === "terminal"){
            splitPayment[index].isSelectedPayment=true
            splitPayment[index].paymentAmount=stripeFeeOnTotalActualAmount.total
            splitPayment[index].handlingFee=stripeFeeOnTotalActualAmount.fee
          }else{
            if(payment.paymentMethod === "cash"){
            splitPayment[index].isSelectedPayment=true
            splitPayment[index].paymentAmount= this.state.isRoundOffAmount ? Math.ceil(stripeFeeOnTotalActualAmount.total) : stripeFeeOnTotalActualAmount.total
            }else{
              splitPayment[index].isSelectedPayment=true
              splitPayment[index].paymentAmount=stripeFeeOnTotalActualAmount.total
            }
          }
        }
      }else{
        splitPayment[index].isSelectedPayment=false
        splitPayment[index].paymentAmount=null
      }
      return null
    })
    this.setState({splitPayments : splitPayment, selectedSplitPaymentMethods : selectedSplitPaymentMethods, availableSplitAmount :stripeFeeOnTotalActualAmount.total})
  }
  else{
   if(this.state.selectedSplitPaymentMethods.length === 0 ){
    selectedSplitPaymentMethods = [""]
    this.setState({splitPayments : splitPayment, selectedSplitPaymentMethods : selectedSplitPaymentMethods, availableSplitAmount :stripeFeeOnTotalActualAmount.total})
   }
  }
  }


  handleRadioChange = (event) => {
    this.setState({ selectedReadingType: event.target.value });
  };

  render() {
    const form = this.formRef.current
    if (this.state.additionalItems.length > 0) {
      form.errors = {}  
    }
    const {
      progressBar,
      initialValues,
      order,
      orderLogs,
      isChargeCardCustomer,
      buttonText,
      isCardModalOpen,
      isChargeCard,
      brand,
      last4,
      enableTerminalButton,
      connectionStatus,
      paymentProgress,
      responseMessageForTerminal,
      isError,
      errorMessage,
      emailProgress,
      emailBtnTxt,
      user,
      cityOfOrigin,
      additionalItems,
      showEditor,
      selectedAdditionalItem,
      devices,
      selectedDevice,
      fuelSurchargeTotal,
      loader,
      selectedReadingType,
      serviceLocation,
      customerRefrence,
      isFuelSurchardeFee,
      isEnvironmentalLoadFee,
      isSalesTax,
      saleTaxFeeAmount,
      isCompany,
      isDriver,
      isTruck,
      customers,
      customer,
      selectedCustomer,
      dueBalance,
      drivers,
      selectedDriver,
      trucks,
      selectedTruck,
      driverInitialValues,
      saleTaxPercentageForOrder,
      createDriverSidebar,
      createDriverForm,
      rotation,
      selected_een,
      truckTab,
      selectedTruckSearch,
      isCameraOnLoad,
      isCameraOn,
      plateImageUrl,
      loadImageUrl,
      selectedProject,
      itemsDisabled,
      customerCreditsMemos,
    } = this.state;
    if (selectedProject !== undefined && Object.keys(selectedProject).length !== 0) {
      this.setState({ errorMessageProject: "" });
    }
    let paymentDetail = order && order.paymetDetail && order.paymetDetail;
    let _brand = brand;
    let _last4 = last4;
    if (paymentDetail && paymentDetail.brand) {
      _brand = paymentDetail && paymentDetail.brand;
    }
    if (paymentDetail && paymentDetail.last4) {
      _last4 = paymentDetail && paymentDetail.last4;
    }
    const sustainabilityInfos =
      order && order.sustainabilityInfo && order.sustainabilityInfo.length > 0
        ? order.sustainabilityInfo
        : [];
    const { addCard } = this.props;
    const defaultOutboundWeight1 = (this.state.initialValues.defaultOutboundWeight || '').toString().replace(/,/g, '');
    this.setState({defaultOutboundWeight:defaultOutboundWeight1})
    const { saveAsDefaultOutboundWeight } = this.state.order;
    this.setState({saveAsDefaultOutboundWeight:saveAsDefaultOutboundWeight})
    return (
      <div className="layout-has-sidebar">
        <TopNavigation
          {...this.props}
          onClickToggleMenu={() =>
            this.setState({ toggleMenu: !this.state.toggleMenu })
          }
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />
       <Spin  id = "loader" spinning={loader} indicator={antIcon} size='large'
        wrapperClassName={`${loader ? "spin-parant" : ""}`}
       >
        <main className="dashboard-layout-content pendingorder__details-wrapper">
          <div className="custome__container">
            <div className="btn-back-header">
              <button className="btn btn-link btn-back">
                  <span onClick={() => {
                      
                    this.saveChanges(this.state.order._id);
                  }}>
                    <BackArrowIcon /> Back
                  </span>

              </button>
              </div>
            <div>
              <Formik
                innerRef={this.formRef}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={pendingOrderSchema1(additionalItems,this.state.showYardageValue,this.state.truckTab === 2)}
                onSubmit={({ submitType, ...values }, { setSubmitting, ...formikProps }) => {
                  if(this.state.isSplitPaymentOption){
                   this.handleCompletOrdeAndSplitPayment({values,formikProps,setSubmitting,submitType,order})
                  }else{
                    this.handleCompleteOrderWithOutSplitpayment({values,formikProps,setSubmitting,submitType,order})
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setValues,
                  setSubmitting,
                }) => {
                  const {
                    netWeight,
                    tonnage,
                    dumpRate,
                    total,
                    capoutPrice,
                    tonnageCap,
                    tonnageDifference,
                    isCapped,
                    totalActualAmount,
                    stripeFeeOnTotal,
                    stripeFeeOnTotalActualAmount,
                    environmentalLoadFeeAmount,
                      creditCard_Fee,
                      terminal_fee,
                  } = this.calculateTotalAmount({ values });
                
                 return (
                    <form onSubmit={handleSubmit} noValidate>
                      {/* <ErrorFocus /> */}
                      <div className="card ticket__section">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                    <div className="page-header-btn-grp">
                      <h5 className="card-title">
                        Ticket #:<span>{_.get(order, "ticketNumber", "")}</span>
                        </h5>              
                      <div className="d-flex align-items-center">
                        {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.deleteOrder === true)? (
                        <button
                          className="btn btn-sm btn-red btn-delete"
                          type="button"
                          disabled={progressBar}
                          onClick={() => this.setState({ isDeleteModal: true })}
                        >
                          <DeleteIcon />
                          {progressBar ? "Please wait..." : "Delete Order"}
                        </button>
                        ):null}
                        {order.completed &&
                        _.get(order, "driverSignature", "") === "" ? (
                          <button
                            className="btn btn-dark btn-sm email__receipt-btn"
                            onClick={this.sendEmailReceipt}
                          >
                            <EmailIcon />
                            &nbsp;&nbsp;&nbsp;
                            {emailProgress ? "Please wait..." : emailBtnTxt}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <h5 className="card-title mb-3">
                        Customer:<span> {this.state?.customer?.displayName}</span>
                      </h5>
                      {customer && dueBalance > 0 && customer?.paymentTerms === "account" ? (
              <div className="ticket-void-order-text mt-2">
                {customer?.displayName} has an overdue balance of ${numberFormat(dueBalance)}
              </div>
            ) : null}
                  </div>
                </div>
                <h6 className="reading__title">Type of Reading </h6>
                          <div className="reading-type-list">
                            <div className="reading-type-list-items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  value="Inbound material sale"
                                  checked={selectedReadingType === "Inbound material sale"}
                                  onChange={this.handleRadioChange}
                                />
                                <span className="checkmark"></span>
                                Inbound material sale
                              </label>
                            </div>
                            <div className="reading-type-list-items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  value="Outbound material sale"
                                  checked={selectedReadingType === "Outbound material sale"}
                                  onChange={this.handleRadioChange}
                                />
                                <span className="checkmark"></span>
                                Outbound material sale
                              </label>
                   </div>
                </div>
              </div>
              <div className="card-body">
              <div className="row">
                        <div className="col-md-4">
                          <div
                              className="reading__select-list"
                              onClick={() =>
                                this.setState({
                                  isCreateDrawerOpen: true,
                                  isCompany: true,
                                  isDriver: false,
                                  isProjectDrawerOpen:false,
                                  isTruck: false,
                                  createDriverSidebar: false,
                                  createDriverForm: false,
                                  is_een_lplates_drawer: false
                                })
                              }
                            >
                              <div className="reading__select-item" onClick={()=>this.showDrawer('company')}>
                                <h6 className="reading__select-title">Select Company </h6>
                                <p className="reading__select-sub-title">
                                  <input
                                    name="customer"
                                    readOnly
                                    placeholder="Select"
                                    className="reading__select-input"
                                    value={values.customer?.displayName}
                                    type="text"
                                  />
                                </p>
                              </div>
                              <ErrorMessage component="span" name="customer" className="invalid-feedback d-block" />
                            </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="reading__select-list"
                            onClick={() =>
                              this.setState({
                                isCreateDrawerOpen: true,
                                isCompany: false,
                                isDriver: false,
                                isProjectDrawerOpen:true,
                                isTruck: false,
                                createDriverSidebar: false,
                                createDriverForm: false,
                                is_een_lplates_drawer: false
                              })
                            }
                          >
                            <div className="reading__select-item" onClick={()=>this.showDrawer('project')}>
                              <h6 className="reading__select-title">Select Project  {customer?.isProjectRequired ? <span>*</span> : ""}</h6>
                              <p className="reading__select-sub-title">
                                <input
                                  name="projectName"
                                  readOnly
                                  placeholder="Select"
                                  className="reading__select-input"
                                  value={values.project?.projectName ?? "Select"}
                                  type="text"
                                />
                              </p>
                            </div>
                            <ErrorMessage component="span" name="projectName" className="invalid-feedback d-block" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="reading__select-list"
                            onClick={() =>
                              this.setState({
                                isCreateDrawerOpen: true,
                                isCompany: false,
                                isProjectDrawerOpen:false,
                                isDriver: true,
                                isTruck: false,
                                createDriverSidebar: false,
                                createDriverForm: false,
                                is_een_lplates_drawer: false
                              })
                            }
                          >
                            <div className="reading__select-item" onClick={()=>this.showDrawer('driver')}>
                              <h6 className="reading__select-title">Select Driver</h6>
                              <p className="reading__select-sub-title">
                                <input
                                  name="driver"
                                  readOnly
                                  placeholder="Select"
                                  className="reading__select-input"
                                  value={values.driver?.firstName || values.driver?.lastName
                                    ? values.driver?.firstName + " " + values.driver?.lastName
                                    : "Select"}
                                  type="text"
                                />
                              </p>
                            </div>
                            <ErrorMessage component="span" name="driver" className="invalid-feedback d-block" />
                          </div>
                        </div>
                        <div className="row col-md-12 mx-0 px-0">
                        <div className="col-md-12">
                          <div className="custome__tabs">
                            <ul className="custome__tabs-list">
                              <li className={truckTab === 1 ? "active" : ""} onClick={!itemsDisabled && this.setTruckTab.bind(this, 1)}>
                                Truck has been here
                              </li>
                              <li className={truckTab === 2 ? "active" : ""} onClick={!itemsDisabled && this.setTruckTab.bind(this, 2)}>
                                Truck has never been here
                              </li>
                            </ul>
                          </div>
                          {truckTab === 1 ? (
                            <div className="truck__stay-details active">
                              <div
                                className="reading__select-list mb-20"
                                onClick={() =>
                                  this.setState({
                                    isCreateDrawerOpen: true,
                                    isTruck: true,
                                    isCompany: false,
                                    isDriver: false,
                                    createDriverSidebar: false,
                                    isProjectDrawerOpen:false,
                                    createDriverForm: false,
                                    is_een_lplates_drawer: false
                                  })
                                }
                              >
                                <div className="reading__select-item" onClick={()=>this.showDrawer('truck')}>
                                  <h6 className="select__driver-title">
                                    Select Truck {customer && `from ${customer.displayName?.toLowerCase()}`}
                                  </h6>
                                  <p className="reading__select-sub-title">
                                    <input
                                      name="truck"
                                      readOnly
                                      placeholder="Select"
                                      className="reading__select-input"
                                      value={values?.truck ? values?.truck?.number || values?.truck?.plateNumber : selectedTruckSearch ? selectedTruckSearch?.number || selectedTruckSearch?.plateNumber:""}
                                      type="text"
                                    />
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                {values.truck?.containers?.length > 0 && <div className="col-md-6 top-margin-10">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      // required
                                      // value={values.type}
                                      onChange={(e)=> this.changeContainer(e.target.value,setValues,values,true)}
                                      // name="type"
                                      disabled={itemsDisabled}
                                    >
                                      {values.truck?.containers?.map((v,i)=> <option key={i} selected={String(v?.size) === String(values?.containerSize)} value={v.size}>{v.size}</option>)}
                                    </select>
                                    <label className="material-textfield-label">
                                      Containers
                                    </label>
                                  </div>
                                </div>}
                                <div className={`${values.truck?.containers?.length > 0 ? "col-md-6" : "col-md-12"} top-margin-10`}>
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"input"}
                                      type="text"
                                      className="form-control material-textfield-input"
                                      onValueChange={_values => {
                                        const { formattedValue, floatValue } = _values;
                                        const defaultOutboundWeight = formattedValue;
                                        setValues({
                                          ...values,
                                           defaultOutboundWeight,
                                          errorMessageoutobound:""
                                        });
                                        this.setState({
                                          initialValues: { ...this.state.initialValues, defaultOutboundWeight }
                                        });
                                        const {plateNumber,type,_id} = values?.truck ?? {}
                                        if(this.state.usingDefault && _id && plateNumber !== "" && type !== "" ){  
                                          this.setState({outboundWeight:floatValue, errorMessageoutobound:""})
                                        }
                                      }}
                                      value={values?.defaultOutboundWeight}
                                      required
                                      disabled={itemsDisabled}
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="defaultOutboundWeight"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">Outbound Truck Weight</label>
                                          </div>
                                        </div>
                                     </div>
                                  </div>
                          ) : (
                            <div className="truck__stay-details active">
                               <div className="row align-items-center justify-content-center">
                     
                        </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      name="plateNumber"
                                      type="text"
                                      className="form-control material-textfield-input"
                                      required
                                      value={values?.plateNumber ? values?.plateNumber : ""}
                                      onChange={e => this.setValues(e, values, setValues)}
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="plateNumber"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Truck License Plate #<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      required
                                      value={values.truckType}
                                      onChange={e => this.setValues(e, values, setValues)}
                                      name="truckType"
                                    >
                                  {
                                   truckTypes?.map((item)=>{
                                   return(
                                     <option value={item.value}>{item.name}</option>
                                   )
                                   })
                                  }
                                    </select>
                                    <label className="material-textfield-label">
                                      Truck Type<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      name="number"
                                      type="text"
                                      className="form-control material-textfield-input"
                                      required
                                      value={values.number ? values.number : ""}
                                      onChange={e => this.setValues(e, values, setValues)}
                                    />
                                    <ErrorMessage component="span" name="number" className="invalid-feedback d-block" />
                                    <label className="material-textfield-label">
                                      Truck Number
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className={!values?.containerCheck ? "mb-4":""}>
                        <label className={`${values.containerCheck ? "mb-3" :""} custom-checkbox custom-checkbox-line`}>
                              <input
                               checked={values?.containerCheck}
                               onChange={(e) => {
                                const {checked,name} = e.target
                                setValues({...values,[name]:checked})
                                this.setState({containerCheck:checked,requiredFields: checked ? [...this.state.requiredFields] : this.state.requiredFields.filter(v => v !== "defaultOutboundWeight" && v !== "containers"),initialValues:{...this.state.initialValues,containerCheck:checked}})
                                }}
                                name="containerCheck"
                                type="checkbox"
                                className="custom-checkbox--input"
                              />
                              <span className="checkmark"></span>
                              <span className="text-decoration-none">Add default container weight</span>
                            </label>
                      </div>
                              {values.containerCheck ?<div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      // required
                                      value={values.containers?.size}
                                      onChange={(e)=> this.changeContainer(e.target.value,setValues,values)}
                                      // name="type"
                                    >
                                    <option value="">Select Container</option>
                                      {this.state.allContainers?.map((v,i)=> {
                                        const disabled = this.checkAlready(v.size)
                                       return <option disabled={disabled} key={i} value={v.size}>{v.size}</option>})}
                                    </select>
                                    <label className="material-textfield-label">
                                      Containers<span>*</span>
                                    </label>
                                  <ErrorMessage
                                      component="span"
                                      name="containers"
                                      className="invalid-feedback d-block"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"input"}
                                      type="text"
                                      className="form-control material-textfield-input"
                                      onValueChange={_values => {
                                        const { formattedValue , floatValue } = _values;
                                        const defaultOutboundWeight = formattedValue;
                                        const container = this.state.containers;
                                        const storedOutboundWeight = this.state.storedOutboundWeight;
                                        if(container?.size) container.defaultWeight = floatValue;
                                        setValues({
                                          ...values,
                                          defaultOutboundWeight,
                                          errorMessageoutobound:"",
                                          containers:container,
                                        });
                                        this.setState({
                                          initialValues: { ...this.state.initialValues, defaultOutboundWeight },
                                          containers:container,
                                          saveAsDefaultOutboundWeight:false,
                                          order: { ...this.state.order, saveAsDefaultOutboundWeight: false },
                                          outboundWeight:storedOutboundWeight,
                                          errorMessageoutobound:""
                                        });
                                        if(this.state.usingDefault){
                                          this.setState({outboundWeight:floatValue})
                                        }
                                      }}
                                     
                                      value={values?.defaultOutboundWeight}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="defaultOutboundWeight"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Set Default Outbound Truck Weight<span>*</span>
                                    </label>
                                  </div>
                                </div>
                              </div>:""}
                              <div className="row">
                                <div className="col-md-6 top-margin-10">
                                     {!isCameraOn ? 
                                  ""
                                   : (
                                    <div className="truck__img">
                                      <Camera
                                        onTakePhoto={dataUri =>
                                          this.onTakePhoto(dataUri, "plateImageUrl", "isCameraOn")
                                        }
                                        imageType={IMAGE_TYPES.PNG}
                                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                                        isMaxResolution={true}
                                        isImageMirror={false}
                                        isSilentMode={false}
                                        isDisplayStartCameraError={true}
                                        isFullscreen={false}
                                    />
                                    </div>
                                  )}
                                  {!isCameraOn && (
                                    <button
                                      className="btn btn-lg w-100 take__picture-btn m-0"
                                      type="button"
                                      onClick={() => {
                                        this.setState({
                                          isCameraOn: true,
                                          plateImageUrl: ""
                                        });
                                      }}
                                      disabled={itemsDisabled}
                                    >
                                      <TakePictureIcon color="#20456D" />
                                      Take Picture of Vehicle
                                    </button>
                                  )} 
                                   </div>
                                <div className="col-md-6 top-margin-10">
                                  {!isCameraOnLoad ? 
                                  ""
                                   : (
                                    <div className="truck__img">
                                      <Camera
                                        onTakePhoto={dataUri =>
                                          this.onTakePhoto(dataUri, "loadImageUrl", "isCameraOnLoad")
                                        }
                                        imageType={IMAGE_TYPES.PNG}
                                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                                        isMaxResolution={true}
                                        isImageMirror={false}
                                        isSilentMode={false}
                                        isDisplayStartCameraError={true}
                                        isFullscreen={false}
                                      />
                                  </div>
                                  )}
                                  {!isCameraOnLoad && (
                                    <button
                                      className="btn btn-lg w-100 take__picture-btn m-0"
                                      type="button"
                                      onClick={() => {
                                        this.setState({
                                          isCameraOnLoad: true,
                                          loadImageUrl: ""
                                        });
                                      }}
                                      disabled={itemsDisabled}
                                    >
                                      <TakePictureIcon color="#20456D" />
                                      Take Picture of Load
                                    </button>
                                 )}
                                  
                               </div>
                             </div>
                              {(plateImageUrl !== "" || loadImageUrl !== "") && (
                                <div className="row  d-flex justify-content-start align-items-center">
                                  <div className="col-md-6">
                                    {plateImageUrl !== "" ? (
                                      <React.Fragment>
                                        <div className="confirm__license">
                                          <h3>Confirm Truck’s License Plate Picture.</h3>
                                          <p>Make sure this picture is clear!</p>
                                          <p>If it is not, click on the “Take Picture” button again.</p>
                                      </div>
                                        <div className="truck__img truck__img_pad numberplate">
                                          <img src={plateImageUrl} alt="" />
                                       </div>
                                      </React.Fragment>
                                   ) : (
                                     ""
                                   )}
                               </div>
                                  <div className="col-md-6">
                                    {loadImageUrl !== "" ? (
                                      <React.Fragment>
                                        <div className="confirm__license">
                                          <h3>Confirm Load Photo is clear.</h3>
                                          <p>Make sure this picture is clear!</p>
                                          <p>If it is not, click on the “Take Picture” button again.</p>
                                       </div>
                                        <div className="truck__img truck__img_pad numberplate">
                                          <img src={loadImageUrl} alt="" />
                                       </div>
                                      </React.Fragment>
                                   ) : (
                                     ""
                                   )}
                                 </div>
                               </div>
                              )}
                           </div>
                          )}
                        </div>
                      {truckTab === 1 && (
                        <div className="w-100">
                          <div className="col-md-12">
                            {!isCameraOnLoad ? (
                              <div className="truck__img">
                                {loadImageUrl !== "" ? <img src={loadImageUrl} alt="" /> : ""}
                             </div>
                            ) : (
                              <div className="truck__img">
                                <Camera
                                  onTakePhoto={dataUri => this.onTakePhoto(dataUri, "loadImageUrl", "isCameraOnLoad")}
                                  imageType={IMAGE_TYPES.PNG}
                                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                                  isMaxResolution={true}
                                  isImageMirror={false}
                                  isSilentMode={false}
                                  isDisplayStartCameraError={true}
                                  isFullscreen={false}
                                />
                             </div>
                            )}
                            <button
                              className={`btn btn-lg w-100 take__picture-btn ${loadImageUrl !== "" ? "":"m-0"}`}
                              type="button"
                              onClick={() =>
                                this.setState({
                                  isCameraOnLoad: true,
                                  plateImageUrl: ""
                                })
                              }
                              disabled={itemsDisabled}
                            >
                              <TakePictureIcon color="#20456D" />
                              Take Picture of Load
                            </button>
                           </div>
                       </div>
                      )}
                      </div>
                    </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card__body-inner">
                      <div className="card__left">
                        <ul className="card__list">
                        </ul>
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Scale Master</h6>
                              <p className="sub__title">{`${_.get(
                                order,
                                "user.firstName"
                              )} ${_.get(order, "user.lastName")}`}</p>
                           </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Company ID</h6>
                              <p className="sub__title">
                                {_.get(values, "customer.customerId", "")}
                              </p>
                           </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">License Plate #</h6>
                              <p className="sub__title">
                                {_.get(values, "truck.plateNumber")
                                  ? values?.truck?.plateNumber
                                  : "N/A"}
                              </p>
                           </div>
                          </li>
                        </ul>
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Customer Payment Terms</h6>
                              <p className="sub__title">
                                {_.toUpper(
                                  _.get(values, "customer.paymentTerms", "")
                                )}
                              </p>
                           </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Company Address</h6>
                              {values.customer && values.customer.address ? (
                                <p className="sub__title">
                                  {order &&
                                    values.customer &&
                                    values.customer.address}
                                  <br />
                                  {order &&
                                    values.customer &&
                                    values.customer.city}
                                  ,{" "}
                                  {order &&
                                    values.customer &&
                                    values.customer.state}{" "}
                                  {order &&
                                    values.customer &&
                                    values.customer.zip}
                                </p>
                              ) : (
                                <p>N/A</p>
                              )}
                           </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Driver Signature</h6>
                              {order.completed &&
                              _.get(order, "driverSignature", "") === "" ? (
                                <p className="text-red">Awaiting Signature</p>
                              ) : (
                                <div className="signature-thumb-preview-">
                                  <img
                                  style={{width: '100%',maxWidth: '200px'}}
                                    src={this.state.driverSignature!== "" ? this.state.driverSignature : _.get(order, "driverSignature", "")}
                                    alt=""
                                  />
                               </div>
                              )}
                           </div>
                          </li>
                        </ul>
                        {_.get(user, "company.cities", []).length !== 0 && (
                          <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Payment Method</h6>
                              <p className="sub__title">
                                {order.paymentType
                                  ? _.startCase(order.paymentType)
                                  : "N/A"}
                              </p>
                              {order.signatureUrl !== "" &&
                                <img src={`${order.signatureUrl}`} alt="signature"></img>
                              }
                           </div>
                          </li>
                            <li>
                              <div className="company__driver-title">
                                <div className="form-group material-textfield custom-select-50">
                                 <select
                                   className="form-control custom-select w-150 material-textfield-input pr-22"
                                    name="cityOfOrigin"
                                    onChange={(e) => {
                                        if (e.target.value === "Add New") {
                                          this.setState({ isCityOfOriginModalOpen: true, citiesOfOrigin: _.get(user, "company.cities", []) })
                                        } else {
                                          this.setState({
                                            cityOfOrigin: e.target.value,
                                          })
                                        }
                                      }
                                   }
                                   value={cityOfOrigin}
                                   disabled={itemsDisabled}
                                 >
                                    <option value="">Select</option>
                                    {_.get(user, "company.cities", []).map(
                                      (city) => {
                                       return (
                                          <option key={city} value={city}>
                                            {city}
                                         </option>
                                       );
                                     }
                                   )}
                                    <option value="Add New">Add New</option>
                                 </select>
                                 <label className="material-textfield-label">
                                    City of Origin{" "}
                                 </label>
                               </div>
                              </div>
                            </li>
                          </ul>
                        )}
                        {
                        this.state?.serviceAddress &&
                        <ul className="card__list_custom">
                        <li>
                        <div className="company__driver-title">
                          <div className="form-group material-textfield custom-select-50-">
                            <PlacesAutocomplete
                              className="form-control custom-input w-150 material-textfield-input"
                              type="text"
                              name="serviceLocation"
                              value={serviceLocation}
                              onChange={(e) =>this.setState({serviceLocation:e.target.value})}  
                              handleAddressChange={({ address }) => this.setState({ serviceLocation: address })}
                              setValue={address => this.setState({ serviceLocation: address })}
                              address={serviceLocation}
                              isSetToState={true}
                              label="Service Location"
                            />
                            </div>
                          </div>
                        </li>
                        </ul>
                        }
                         <ul className="card__list_custom">
                        <li>
                        <div className="company__driver-title">
                          <div className="form-group material-textfield custom-select-50-">
                            <input
                              name="customerRefrence"
                              onChange={(e) => this.setState({ customerRefrence: e.target.value})}
                              value={customerRefrence}
                              className="form-control custom-input- w-150- material-textfield-input-"
                              required
                            />
                            <label className={`material-textfield-label`}>
                            Customer Reference #
                            </label>
                          </div>
                        </div>
                        </li>
                        </ul>
                      </div>
                      <div className="card__right">
                        {_.get(values, "driver.licenseImageUrl", "") !== "" && (
                          <div className="card__thum-item">
                            <h6 className="thumb-preview-title">
                              Drivers License
                            </h6>
                            <div className="thumb-preview">
                              <img
                                src={_.get(values, "driver.licenseImageUrl", "")}
                                style={{
                                  transform: `rotate(${_.get(
                                    order,
                                    "driver.rotation",
                                    0
                                  )}deg)`,
                                }}
                                alt=""
                                onClick={() =>
                                  this.setState({
                                    images: [
                                      _.get(
                                        values,
                                        "driver.licenseImageUrl",
                                        ""
                                      ),
                                    ],
                                    isLightBoxOpen: true,
                                  })
                                }
                              />
                           </div>
                         </div>
                        )}
                        {_.get(values, "truck.plateImageUrl", "") !== "" ? (
                          <div className="card__thum-item">
                            <h6 className="thumb-preview-title">
                              License Plate
                            </h6>
                            <div className="thumb-preview">
                              <img
                                src={_.get(values, "truck.plateImageUrl", "")}
                                alt=""
                                onClick={() =>
                                  this.setState({
                                    images: [
                                      _.get(order, "truck.plateImageUrl", ""),
                                    ],
                                    isLightBoxOpen: true,
                                  })
                                }
                              />
                           </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {_.get(order, "loadImageUrl", "") !== "" ? (
                          <div className="card__thum-item">
                            <h6 className="thumb-preview-title">
                              Inbound Truck Photo
                            </h6>
                            <div
                              className="thumb-preview"
                              onClick={() =>
                                this.setState({
                                  images: [_.get(order, "loadImageUrl", "")],
                                  isLightBoxOpen: true,
                                })
                              }
                            >
                              <img
                                src={_.get(order, "loadImageUrl", "")}
                                alt=""
                              />
                           </div>
                         </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                      <div className="card">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <h5 className="card-title">Scale Readings</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="card__scale-list card__scale-list-edits">
                                <li>
                                  <span>
                                    Inbound Weight |{" "}
                                    {`${moment(
                                      _.get(
                                        order,
                                        "inboundWeightDate",
                                        moment()
                                      )
                                    )?.tz(this.state?.tz)?.format("MM/DD/YY")}
                          at
                                  ${moment(
                                    _.get(order, "inboundWeightDate", moment())
                                  )?.tz(this.state?.tz)?.format("hh:mm a")}`}
                                  </span>
                                  <span>
                                    {numberFormatLbs(
                                      _.get(order, "inboundWeight", "")
                                    )}{" "}
                                    lbs
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Outbound Weight |{" "}
                                    {`${moment(
                                      _.get(
                                        this.state,
                                        "inboundWeightDate",
                                        moment()
                                      )
                                    )?.tz(this.state?.tz)?.format("MM/DD/YY")}
                          at
                                    ${moment(
                                      _.get(
                                        this.state,
                                        "outboundWeightDate",
                                        moment()
                                      )
                                    )?.tz(this.state?.tz)?.format("hh:mm a")}`}
                                  </span>
                                  <span>
                                    {numberFormatLbs(
                                      _.get(this.state, "outboundWeight", "")
                                    )}{" "}
                                    lbs
                                  </span>
                                </li>
                                {
                                  (
                                    (this.state.truckTab === 1 && this.state.initialValues?.truck?.plateNumber) ||
                                    (this.state.truckTab === 2 && this.state.initialValues?.plateNumber)
                                  ) &&
                                  !this.state.usingDefault &&
                                  Number(this.state?.storedOutboundWeight) !== Number(this.state?.defaultOutboundWeight) && (
                                    <li className="first-child">
                                      <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                        Save as default truck outbound weight
                                        <input
                                          type="checkbox"
                                          className="custom-checkbox--input"
                                          checked={
                                            this.state.saveAsDefaultOutboundWeight ||
                                            saveAsDefaultOutboundWeight
                                          }
                                          onChange={this.handleCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </li>
                                 )
                                }
                                <li>
                                  <span>Net</span>
                                  <span>{numberFormatLbs(netWeight)} lbs</span>
                                </li>
                                <li>
                                  <span>Tonnage </span>
                                  <span>{netWeight / 2000} tons</span>
                                </li>
                                {user?.company?.sisterCompany ? null :<li>
                                  <span>Hide weight on order receipts </span>
                                  <span>
                                    <Switch
                                    checked={this.state.hideWieghtToggle}
                                    onChange={(e) => this.handleWeightToggle(e)}
                                    />
                                  </span>
                                </li>}
                              </ul>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group material-textfield">
                                <div className="disable__items">
                                  <h3 className="title">
                                    Pricing Tier
                                    <span className="red-text">*</span>
                                  </h3>
                                  <h4 className="sub-title">
                                    {this.state.pricingTierName
                                      ? this.state.pricingTierName
                                      : "N/A"}
                                  </h4>
                                </div>
                              </div>
                              <div className="form-group material-textfield">
                                <select
                                  value={this.state.dumpRateType}
                                  name="dumpRate"
                                  className="form-control custom-select w-150 material-textfield-input pr-22"
                                  onChange={(e) =>
                                    this.onChangeDumpRate(e, values, setValues)
                                  }
                                  // value={cityOfOrigin}
                                  disabled={itemsDisabled}
                                >
                                  <option value="">Select Dump Rate</option>
                                  {Object.keys(
                                    this.state.pricingTierDumpRates
                                  ).map((key) => {
                                    if (
                                      key !== "defaultOption" &&
                                      key !== "capoutPrice" &&
                                      key !== "minimumPrice" &&
                                      (this.state?.pricingTierDumpRates[key] ?? '').toString().length > 0
                                    ) {
                                      return (
                                        <option key={key} value={key}>
                                          {customStartCase(key)}
                                        </option>
                                      );
                                    }
                                    return null;
                                  })}
                                </select>
                                <ErrorMessage
                                  component="span"
                                  name="dumpRate"
                                  className="invalid-feedback d-block"
                                />
                                <label className="material-textfield-label">
                                  Dump Rate<span>*</span>
                                </label>
                              </div>
                              <div className="form-group material-textfield">
                                <NumberFormat
                                 decimalScale={2}
                                fixedDecimalScale={true}
                                  value={values.yardage}
                                  onChange={(e) => {
                                    const data = {...values}
                                    additionalItems.map((v)=>{
                                      data[v.name]=v.quantity
                                      return null;
                                    })
                                    setValues({...data, yardage: e?.target?.value});
                                    this.setState({
                                        initialValues: {
                                         ...this.state.initialValues,
                                          dumpRate: this.state?.dumpRateType,
                                          yardage: e?.target?.value,
                                          paymentType: _.get(data, "paymentType", ""),
                                        }
                                  })
                                  }
                                  }
                                  name="yardage"
                                  className="form-control material-textfield-input"
                                  required={this.state.showYardageValue}
                                  disabled={itemsDisabled}
                                />
                                {this.state.showYardageValue && <ErrorMessage
                                  component="span"
                                  name="yardage"
                                  className="invalid-feedback d-block"
                                />}
                                <label className={`material-textfield-label`}>
                                  Yardage{this.state.showYardageValue && <span>*</span>}
                                </label>
                              </div>
                              <div className="form-group material-textfield">
                                <select
                                  name="additionalItems"
                                  value={selectedAdditionalItem}
                                  className="form-control custom-select w-150 material-textfield-input pr-22"
                                  onChange={(e) =>
                                    this.onChangeAdditionalValue(e)
                                  }
                                  disabled={itemsDisabled}
                                >
                                  <option value="">Select</option>
                                  {this.state.additionalItemsData.map(
                                    ({ label, value }) => {
                                      if (value) {
                                        return (
                                          <option key={label} value={label}>
                                            {label}
                                          </option>
                                        );
                                      }
                                      return null;
                                     }
                                   )}
                                </select>
                                <label className="material-textfield-label">
                                  Fixed Items
                                  {/* <span>*</span> */}
                                </label>
                                {additionalItems.length !== 0 && (
                                  <h3 className="selected__item-count">{`${additionalItems.length} selected`}</h3>
                                )}
                             </div>
                             <ul className="city__list">
                               {additionalItems.length !== 0 && (
                                    <div className="title__list">
                                      <h3 className="city__list-title">Item</h3>
                                      <h3 className="city__list-title">
                                        Quantity
                                      </h3>
                                    </div>
                                  )}
                                  {additionalItems.map((item, i) => {
                                    return (
                                      <React.Fragment key={item.label}>
                                        <li>
                                          <div className="list__tags">
                                            {item.label}
                                          </div>
                                          <div className="list__quantity">
                                            <NumberFormat
                                              name={item.name}
                                              allowEmptyFormatting={true}
                                              thousandSeparator={true}
                                              displayType={"input"}
                                              type="text"
                                              onValueChange={({ floatValue }) =>
                                                this.onChangeAdditionalQuantity(
                                                  floatValue,
                                                  i,
                                                  values,
                                                  setValues,
                                                  item
                                                )
                                              }
                                              value={item.quantity}
                                              disabled={itemsDisabled}
                                            />
                                            <img
                                              className="img-fluid close__btn"
                                              src={deleteIcon}
                                              alt=""
                                              onClick={() =>
                                                !itemsDisabled && this.removeItem(item)
                                              }
                                            />
                                          </div>
                                        </li>
                                      </React.Fragment>
                                    );
                                  })}
                                </ul>
                                </div>
                            </div>
                          </div>
                        </div>
                      <div className="card">
                       <div className="card-header">
                         <div className="row align-items-center">
                           <div className="col-md-9 col-sm-9 col-xs-9 col-xl-9">
                             <h5 className="card-title">Notes</h5>
                           </div>
                            <div className="col-md-3 col-sm-3 col-xs-3 col-xl-3 text-right">
                              {!showEditor &&
                                (order && order.notes ? (
                                  <button
                                    type="button"
                                    className="btn btn-dark btn-sm"
                                    onClick={() => {
                                      const form = this.formRef.current
                                      if (this.state.additionalItems.length > 0) {
                                        form.errors = {}  
                                     }
                                      this.setState({
                                        showEditor: true,
                                        notes: order.notes,
                                      });
                                    }}
                                  >
                                    Edit Note
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-dark btn-sm"
                                    onClick={() =>{
                                      const form = this.formRef.current
                                      if (this.state.additionalItems.length > 0) {
                                        form.errors = {}  
                                     }
                                      this.setState({ showEditor: true })
                                    }
                                    }
                                  >
                                    Add Note
                                  </button>
                                ))}
                           </div>
                         </div>
                        </div>
                        {(showEditor || order.notes) && (
                          <div className="card-body">
                            <div className="row">
                              {order && order.notes ? (
                                !showEditor ? (
                                  <div className="col-md-12 notes">
                                    {_.get(order, "notes", "")}
                                  </div>
                               ) : (
                                <div className="notes__default col-md-12">
                                   <div className="notes__default-inner">
                                     <textarea
                                       name="notes"
                                       className="notes__default-input"
                                       onChange={this.handleChange.bind(this)}
                                       value={this.state.notes}
                                     ></textarea>
                                   </div>
                                   <button
                                     type="button"
                                     className="btn btn-dark btn-sm"
                                     onClick={this.saveNotes.bind(
                                       this,
                                       order._id
                                      )}
                                    >
                                     Save Note
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                   <button
                                     type="button"
                                      className="btn btn-gray btn-sm"
                                      onClick={() =>{
                                        const form = this.formRef.current
                                      if (this.state.additionalItems.length > 0) {
                                        form.errors = {}  
                                      }
                                        this.setState({ showEditor: false })
                                      }
                                      }
                                     >
                                     Cancel
                                   </button>
                                   </div>
                                )
                              ) : (
                                <div className="notes__default col-md-12">
                                  <div className="notes__default-inner">
                                    <textarea
                                      name="notes"
                                      className="notes__default-input"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.notes}
                                    ></textarea>
                                 </div>
                                  <button
                                    type="button"
                                    className="btn btn-dark btn-sm"
                                    onClick={this.saveNotes.bind(
                                      this,
                                      order._id
                                    )}
                                  >
                                    Save Note
                                  </button>
                                </div>
                             )}
                           </div>
                          </div>
                        )}
                      </div>
                      <div className="sustainability__information">
                        {sustainabilityInfos.map((sustainabilityInfo, i) => {
                          return (
                            <div className="card" key={i}>
                              <div className="card-header">
                                <div className="row align-items-center">
                                  <div className="col-md-7 col-sm-7 col-xs-7 col-xl-7 d-flex flex-unset align-items-center justify-content-between">
                                    <div>
                                      <h5 className="card-title">
                                        Sustainability Information
                                      </h5>
                                      <p className="card-title-sm">
                                        {sustainabilityInfo.address}
                                      </p>
                                   </div>
                                    
                                  </div>
                                  <div className="col-md-5 col-sm-5 col-xs-5 col-xl-5">
                                    <div className="float-right">
                                    <button
                                      onClick={() =>
                                        this.props.history.push({
                                          pathname: `/sustainability-information`,
                                          state: {
                                            orderId: order.ticketNumber,
                                            page: "pending-order-details",
                                            index: i,
                                            yardage: values.yardage,
                                            dumpRate: this.state?.dumpRateType,
                                            tonnage,
                                            additionalItems,
                                            notes: this.state.notes,
                                            cityOfOrigin,
                                            serviceLocation,
                                            customerRefrence,
                                            saleTaxPercentageForOrder,
                                            truck:values?.truck,
                                            project:values?.project,
                                            driver:values?.driver,
                                            customer:this.state.customerData,
                                            loadImageUrl,
                                            plateImageUrl,
                                            truckTab,
                                            truckInputs:{
                                              truckType:values?.truckType,
                                              number:values?.number,
                                              plateNumber:values?.plateNumber,
                                              defaultOutboundWeight:values?.defaultOutboundWeight,
                                              containers:values?.containers,
                                              containerCheck:values?.containerCheck
                                            },
                                            isFuelSurchardeFee,
                                            isSalesTax,
                                            containerSize:values?.containerSize,
                                          },
                                        })
                                      }
                                      className="btn btn-dark btn-sm update__information "
                                      disabled={itemsDisabled}
                                    >
                                      Update Sustainability Information
                                    </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-12">
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "totalpercentage"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Total % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo.totalpercentage
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Total % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo.weight_total
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.weight}
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "waste"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Waste % (Yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.waste}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Waste % (Tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[0].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Waste Weight (Tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[0].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "brick"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Brick % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.brick}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Brick % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[1].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Brick Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[1].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "dirt"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Dirt % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.dirt}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Dirt % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[2].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Dirt Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[2].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "concrete"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Concrete % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.concrete}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Concrete % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[3].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Concrete Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[3].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "cleanwood"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Wood % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.cleanwood}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Wood % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[4].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Wood Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[4].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "metal"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Metal % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.metal}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Metal % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[5].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Metal Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[5].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "paper_cardboard"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Paper/Cardboard % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo.paper_cardboard
                                              }
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Paper/Cardboard % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[6].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Paper/Cardboard Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[6].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "plastic"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Plastic % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.plastic}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Plastic % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[7].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Plastic Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[7].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "drywall"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Drywall % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.drywall}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Drywall % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[8].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Drywall Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[8].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "glass"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Glass % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.glass}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Glass % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[9].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Glass Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[9].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    {sustainabilityInfo.selectedFields.indexOf(
                                      "asphalt"
                                    ) !== -1 && (
                                      <ul className="card__list">
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Asphalt % (yards)
                                            </h6>
                                            <p className="sub__title">
                                              {sustainabilityInfo.asphalt}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Asphalt % (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {Math.round(
                                                sustainabilityInfo
                                                  .tonnagepercentage[10].value
                                              )}
                                            </p>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="company__driver-title">
                                            <h6 className="title">
                                              Asphalt Weight (tons)
                                            </h6>
                                            <p className="sub__title">
                                              {
                                                sustainabilityInfo
                                                  .tonnageweight[10].value
                                              }
                                            </p>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    <div className="recycling__title">
                                      <span>Recycling %</span>{" "}
                                      {Math.round(
                                        sustainabilityInfo.recyclingpercentage
                                      )}
                                      %
                                    </div>
                                    <div className="risidual__waste">
                                      <span>Waste %</span>{" "}
                                      {Math.round(
                                        sustainabilityInfo.residualpercentage
                                      )}
                                      %
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <button
                          className="btn btn-dark btn-lg w-100 btn-addlead"
                          type="button"
                          disabled={itemsDisabled}
                          onClick={() => {
                            this.props.history.push({
                              pathname: `/sustainability-information`,
                              state: {
                                orderId: order.ticketNumber,
                                tonnage: tonnage,
                                dumpRate: this.state?.dumpRateType,
                                page: "pending-order-details",
                                index: -1,
                                yardage: values.yardage,
                                additionalItems,
                                notes: this.state.notes,
                                cityOfOrigin,
                                serviceLocation,
                                customerRefrence,
                                saleTaxPercentageForOrder,
                                truck:values?.truck,
                                project:values?.project,
                                driver:values?.driver,
                                customer:this.state.customerData,
                                loadImageUrl,
                                plateImageUrl,
                                truckTab,
                                truckInputs:{
                                  truckType:values?.truckType,
                                  number:values?.number,
                                  plateNumber:values?.plateNumber,
                                  defaultOutboundWeight:values?.defaultOutboundWeight,
                                  containers:values?.containers,
                                  containerCheck:values?.containerCheck
                               },
                                isFuelSurchardeFee,
                                isSalesTax,
                                containerSize:values?.containerSize,
                              },
                            })
                          }
                          }
                        >
                          Add LEED/Diversion %
                        </button>
                      </div>
                      <div className="card payment__breakdown">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <h5 className="card-title">Payment Breakdown</h5>
                              </div>
                           </div>
                          </div>
                          <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="card__scale-list card__scale-list-edits">
                                <li>
                                  <span>Inbound Weight</span>
                                  <span>
                                    {numberFormatLbs(
                                      _.get(order, "inboundWeight", "")
                                    )}{" "}
                                    Ibs
                                  </span>
                                </li>
                                <li>
                                  <span>Outbound Weight </span>
                                  <span>
                                    {numberFormatLbs(
                                      _.get(this.state, "outboundWeight", "")
                                    )}{" "}
                                    Ibs
                                  </span>
                                </li>
                                <li>
                                  <span>Net Weight</span>
                                  <span>{numberFormatLbs(netWeight)} Ibs</span>
                                </li>
                                <li>
                                  <span>Tonnage </span>
                                  <span>{tonnage} tons </span>
                                </li>
                                {(!_.get(order, "customer.hidePrice", true) ||
                                  _.get(user, "role", false) !== "user") && (
                                  <React.Fragment>
                                    <li>
                                      <span>Dump Rate </span>
                                      <span>${numberFormat(dumpRate)}</span>
                                    </li>
                                    {additionalItems.filter(
                                      ({ quantity }) => quantity
                                    ).length !== 0 && (
                                      <>
                                        <hr />
                                        {additionalItems.map(
                                          ({ label, value, quantity }) => {
                                            if (quantity) {
                                              return (
                                                <li key={label}>
                                                  <span>
                                                    {label} ({quantity}) ($
                                                    {value}/unit)
                                                  </span>
                                                  <span>
                                                    $
                                                    {numberFormat(
                                                      value * quantity
                                                    )}
                                                  </span>
                                                </li>
                                              );
                                            }
                                            return null;
                                          }
                                       )}
                                      </>
                                    )}
                                    {_.get(
                                      user,
                                      "company.isEnvironmentalLoadFee",
                                      false
                                    ) && this.state.isEnvironmentalLoadFee && (
                                      <React.Fragment>
                                        <hr />
                                        <li>
                                          <span>Environmental Load Fee</span>
                                          <span>
                                            $
                                            {numberFormat(
                                              environmentalLoadFeeAmount
                                            )}
                                          </span>
                                        </li>
                                      </React.Fragment>
                                    )}
                                    {_.get(
                                      user,
                                      "company.isFuelSurchardeFee",
                                      false
                                    ) && _.get(
                                      this.state,
                                      "isFuelSurchardeFee",
                                      false
                                    ) && (
                                        <React.Fragment>
                                         <hr />
                                         <li>
                                            <span>Fuel Surcharge</span>
                                            <span>
                                              $
                                              {numberFormat(fuelSurchargeTotal)}
                                            </span>
                                         </li>
                                        </React.Fragment>
                                      )}
                                   {(user?.company?.isSalesTax ?? false) && (this.state?.isSalesTax ?? false) && (saleTaxFeeAmount > 0) && (
                                     <React.Fragment>
                                       <hr />
                                       <li>
                                         <span>Sales Tax</span>
                                         <span>
                                           ${numberFormat(saleTaxFeeAmount)}
                                         </span>
                                       </li>
                                     </React.Fragment>
                                   )}
                                    {this.state.isCapoutPrice && (
                                      // _.get(order, "customer.isCapoutPrice", false) && (
                                      <>
                                        <hr />
                                        <li>
                                          <span>Capped </span>
                                          <span>{isCapped} </span>
                                        </li>
                                        <li>
                                          <span>Per Ton Price </span>
                                          <span>
                                            ${numberFormat(dumpRate)}{" "}
                                          </span>
                                        </li>
                                        <li>
                                          <span>Tonnage Cap</span>
                                          <span>
                                            {numberFormatLbs(tonnageCap)} tons{" "}
                                          </span>
                                        </li>
                                        <li>
                                          <span>Capout Price</span>
                                          <span>
                                            ${numberFormat(capoutPrice)}{" "}
                                          </span>
                                        </li>
                                        <li>
                                          <span>Tonnage Difference</span>
                                          <span>
                                            {numberFormatLbs(tonnageDifference)}{" "}
                                            tons{" "}
                                          </span>
                                        </li>
                                      </>
                                   )}
                                    {this.renderTotalAmount({
                                      total,
                                      totalActualAmount,
                                      stripeFeeOnTotal,
                                      stripeFeeOnTotalActualAmount,
                                      isRoundOffAmount: ( order?.isRoundOffAmount || this.state?.isRoundOffAmount ) && this.state?.selectPaymentMethod === "cash" 
                                    })}
                                    {user?.company?.isFuelSurchardeFee &&  (<React.Fragment>
                                      <hr />
                                      <li>
                                        <div className="display-team-status">
                                          <span className="display-team-text">
                                            Add a Fuel Surcharge
                                          </span>
                                          <Switch
                                            name="isFuelSurchardeFee"
                                            checked={isFuelSurchardeFee}
                                            disabled={this.state.disabledFuelSwitch || order?.completed}
                                            onChange={() =>
                                              this.setState({ isFuelSurchardeFee: !isFuelSurchardeFee })
                                            }
                                          />
                                          <p
                                            className={
                                              isFuelSurchardeFee
                                                ? "on-of-text text-green"
                                                : "on-of-text text-red"
                                            }
                                          >
                                            {isFuelSurchardeFee ? "Yes" : "No"}
                                          </p>
                                        </div>
                                      </li>
                                    </React.Fragment>)}
                                    {user?.company?.isEnvironmentalLoadFee &&  (<React.Fragment>
                                      <hr />
                                      <li>
                                        <div className="display-team-status">
                                          <span className="display-team-text">
                                            Add Environmental Load Fee
                                          </span>
                                          <Switch
                                            name="isEnvironmentalLoadFee"
                                            checked={isEnvironmentalLoadFee}
                                            // disabled={this.state.disabledFuelSwitch}
                                            onChange={() =>
                                              this.setState({ isEnvironmentalLoadFee: !isEnvironmentalLoadFee })
                                            }
                                          />
                                          <p
                                            className={
                                              isEnvironmentalLoadFee
                                                ? "on-of-text text-green"
                                                : "on-of-text text-red"
                                            }
                                          >
                                            {isEnvironmentalLoadFee ? "Yes" : "No"}
                                          </p>
                                        </div>
                                      </li>
                                    </React.Fragment>)}
                                    {user?.company?.isSalesTax &&  (<React.Fragment>
                                      <hr />
                                      <li>
                                        <div className="display-team-status">
                                          <span className="display-team-text">
                                            Add Sales Tax
                                          </span>
                                          <Switch
                                            name="isSalesTax"
                                            checked={isSalesTax}
                                            onChange={() =>
                                              this.setState({ isSalesTax: !isSalesTax })
                                            }
                                          />
                                          <p
                                            className={
                                              isSalesTax
                                                ? "on-of-text text-green"
                                                : "on-of-text text-red"
                                            }
                                          >
                                            {isSalesTax ? "Yes" : "No"}
                                          </p>
                                        </div>
                                     </li>
                                     {this.state.isSalesTax &&
                                       <div className="col-md-6">
                                         <div className="form-group material-textfield">
                                           <NumberFormat
                                             allowEmptyFormatting={true}
                                             thousandSeparator={false}
                                             decimalScale={2}
                                             allowNegative={false}
                                             displayType={"input"}
                                             type="text"
                                             className="form-control material-textfield-input"
                                             onValueChange={_values => {
                                               const { formattedValue, floatValue, value } = _values;
                                               if (value === "") {
                                                 this.setState({ saleTaxPercentageForOrder: value, errisSalesTax: "" });
                                               } else if (floatValue <= 100) {
                                                 this.setState({ saleTaxPercentageForOrder: formattedValue, errisSalesTax: "" }, () => {
                                                   this.isSalesTax(floatValue);
                                                 });
                                               } else {
                                                 this.setState({ errisSalesTax: "Sales Tax cannot be more than 100%." });
                                               }
                                             }}
                                             value={this.state.saleTaxPercentageForOrder}
                                             name="saleTaxPercentageForOrder"
                                             required
                                           />
                                           <div className="invalid-feedback text-left">{this.state.errisSalesTax}</div>
                                           <label className="material-textfield-label">% of Sales Tax</label>
                                         </div>
                                       </div>
                                     }    
                                    </React.Fragment>)}
                                  </React.Fragment>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <h5 className="card-title">
                                Payment Information
                              </h5>
                           </div>
                         </div>
                        </div>
                        
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group material-textfield">
                                <div className="company__driver-title">
                                  <h6 className="title">Payment Terms</h6>
                                  <p className="sub__title">
                                    {_.toUpper(
                                      _.get(values, "customer.paymentTerms", "")
                                    )}
                                  </p>
                                </div>
                              </div>
                              {_.get(selectedCustomer, "paymentTerms", "") !== "account" && <>
                              <div className="col-lg-12 mb-4">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="payment-terms-wrapper">
                                      <label className="custom-checkbox custom-checkbox-line radio__checkbox payment-title">
                                        Split Payment
                                        <input
                                          type="checkbox"
                                          disabled={this.state.availableOrderAmount > 0 }
                                          className="custom-checkbox--input"
                                          checked={this.state.isSplitPaymentOption}
                                          onChange={() => {this.handlePaymentOptions(stripeFeeOnTotalActualAmount) }}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </> }
                              {!this.state.isSplitPaymentOption ?
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-lg-12">
                                <div className="row">
                                  {!order.isPaymentDone && !isChargeCardCustomer &&_.get(values, "customer.paymentTerms", "") !== "account" && (
                                  <div className={`col-md-${this.state.selectedSplitPaymentMethods.includes("terminal") ? "9": "12" }`} >  
                                   <div className="form-group material-textfield">
                                    <select
                                      value={this.state.selectedSplitPaymentMethods[0]}
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      onChange={(event)=>{this.handleWithOutSplitPaymentMethod(event.target.value,stripeFeeOnTotal)}}
                                    >
                                      <option value="">Select Method</option>
                                      <option value="cash">Cash</option>
                                      <option value="check">Check</option>
                                      <option value="credit-card">Credit Card On File</option>
                                      <option value="terminal">Credit Card On Terminal</option>
                                    </select>
                                    <label className="material-textfield-label">
                                    Payment Method
                                    </label>
                                  </div> 
                                    {this.state.selectedSplitPaymentMethods.includes("credit-card") &&
                                      (this.state.customerCreditCards.length === 0 && !this.state.paybliEnable ? (
                                        // (this.state.customerPayabliData?.StoredMethods.length === 0 ? (
                                        <p className="no_file-add-card">
                                          There is no card on file.{" "}
                                          <span
                                            onClick={() =>
                                              this.setState({
                                                isCardModalOpen: true,
                                                amount: total,
                                              },()=>{
                                                this.forceUpdate()
                                              })
                                            }
                                          >
                                            Add a card
                                          </span>{" "}
                                        </p>
                                      ) : this.state.customerPayabliData
                                          ?.StoredMethods.length === 0 &&
                                        order?.customer?.paymentmethods.length ===
                                          0 &&
                                        this.state.paybliEnable ? (
                                        // (this.state.customerPayabliData?.StoredMethods.length === 0 ? (
                                        <p className="no_file-add-card">
                                          There is no card on file.{" "}
                                          <span
                                            onClick={() =>
                                              this.setState({
                                                isCardModalOpen: true,
                                                amount: total,
                                              })
                                            }
                                          >
                                            Add a card
                                          </span>{" "}
                                        </p>
                                      ) : (
                                        <div className="row">
                                          {this.state.paybliEnable ? (
                                            <div className="col-md-9">
                                              <div className="form-group material-textfield">
                                                <select
                                                  className="form-control custom-select w-150 material-textfield-input pr-22"
                                                  required
                                                  onChange={(e) => {
                                                    const paymentMethod =
                                                      e.target.value.split("/");
                                                    this.setState({
                                                      cardId: paymentMethod[0],
                                                      payMethod: paymentMethod[1],
                                                      selectedCardData:
                                                        paymentMethod[0],
                                                    });
                                                  }}
                                                  // this.setState({selectedCardData:e.target.value})}}
                                                >
                                                  <option value={""} key={""}>
                                                    Select Credit Card
                                                  </option>
                                                  {this.state.customerPayabliData
                                                    ?.StoredMethods.length > 0 &&
                                                    this.state.customerPayabliData?.StoredMethods.map(
                                                      (item) => {
                                                        return (
                                                          <option
                                                            value={
                                                              item.IdPmethod +
                                                              "/payabli"
                                                            }
                                                            key={item.IdPmethod}
                                                          >
                                                            {item.Descriptor}{" "}
                                                            {item.MaskedAccount}
                                                            &nbsp;
                                                            {this.insertSlash(
                                                              item.ExpDate
                                                            )}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                </select>
                                                {/* <label className="material-textfield-label">Select Credit Card </label> */}
                                              </div>
                                              <p className="no_file-add-card">
                                                <span
                                                  onClick={() =>{
                                                    const form = this.formRef.current
                                                    if (this.state.additionalItems.length > 0) {
                                                      form.errors = {}  
                                                    }
                                                    this.setState({
                                                      isCardModalOpen: true,
                                                      amount: total,
                                                    })
                                                  }
                                                  }
                                                >
                                                  Add a new card
                                                </span>{" "}
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="col-md-9">
                                              <div className="form-group material-textfield">
                                                <select
                                                  className="form-control custom-select w-150 material-textfield-input pr-22"
                                                  required
                                                  onChange={(e) => {
                                                    const paymentMethod =
                                                      e.target.value.split("/");
                                                  
                                                    this.setState({
                                                      cardId: paymentMethod[0],
                                                      payMethod: paymentMethod[1],
                                                      selectedCardData:
                                                        paymentMethod[0],
                                                    });
                                                  }}
                                                >
                                                  <option value={""} key={""}>
                                                    Select Credit Card
                                                  </option>
                                                  {this.state.customerCreditCards.length > 0 && this.state.customerCreditCards.map(
                                                    (payment_method_details) => {
                                                      const {
                                                        _id = "",
                                                        details = {},
                                                      } = payment_method_details;
                                                      return (
                                                        <option
                                                          value={
                                                            _id + "/payengine"
                                                          }
                                                          key={_id}
                                                        >
                                                          {details.brand} ****
                                                          {details.last4digits}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                                {/* <label className="material-textfield-label">Select Credit Card </label> */}
                                              </div>
                                              <p className="no_file-add-card">
                                                <span
                                                  onClick={() =>
                                                    this.setState({
                                                      isCardModalOpen: true,
                                                      amount: total,
                                                    })
                                                  }
                                                >
                                                  Add a new card
                                                </span>{" "}
                                              </p>
                                            </div>
                                          )}
                                          <div className="col-md-3">
                                            <button
                                              type="button"
                                              disabled={isChargeCard ||( this.state.cardId === "" ? true :false) }
                                              onClick={() => {
                                                this.setAsyncState({
                                                  initialValues: {
                                                    ...this.state.initialValues,
                                                    isPaymentLater: false, isPaymentDone: true, completed: true
                                                  },
                                                })
                                                setValues({
                                                  ...values,
                                                  submitType: "credit-card",
                                                });
                                                handleSubmit();
                                              }}
                                              className="btn btn-dark btn-lg w-100 charge-card-btn"
                                            >
                                              {isChargeCard
                                                ? "Please wait..."
                                                : "Charge Card"}
                                            </button>
                                          </div>
                                        </div>
                                    ))}
                                  </div>
                                  )}
                                  {!order.isPaymentDone && this.state.selectedSplitPaymentMethods.includes("terminal") && !isChargeCardCustomer && (
                                    <div className="col-md-3 p-0">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          this.setAsyncState({
                                            initialValues: {
                                              ...this.state.initialValues,
                                              isPaymentLater: false, isPaymentDone: true, completed: true
                                            },
                                          })
                                          setValues({
                                            ...values,
                                            submitType: "terminal",
                                          });
                                          handleSubmit();
                                        }}
                                        className={
                                          enableTerminalButton === true
                                            ? "btn btn-dark btn-lg w-100 charge-card-btn"
                                            : "btn btn-dark btn-lg w-100 charge-card-btn-disabled"
                                        }
                                        disabled={
                                          !enableTerminalButton || paymentProgress
                                        }
                                      >
                                        {paymentProgress
                                          ? "Please wait..."
                                          : "Charge on Terminal"}
                                      </button>
                                    </div>
                                  )}
                                  </div>
                                  {!paymentProgress && !this.state.paybliEnable && (this.state.selectedSplitPaymentMethods.includes("terminal") && !this.state.isTerminalLoader) 
                                   ?  devices &&  devices.length > 0 &&
                                      devices.map((device) => {
                                        return (
                                          <div
                                            key={device.deviceId}
                                            className="col-md-6"
                                          >
                                            <div
                                              className={
                                                device.deviceId === selectedDevice
                                                  ? "device-card device-card-selected"
                                                  : "device-card"
                                              }
                                              onClick={() =>
                                                this.setState({
                                                  selectedDevice: device.deviceId,
                                                })
                                              }
                                            >
                                              <div className="device-nickname">
                                                Device Nickname: {device.deviceNickname}
                                              </div>
                                              <div className="device-details">
                                                <div className="device-serial-no">
                                                  <span className="serial-label">
                                                    Serial No:
                                                  </span>
                                                  <span className="serial-no">
                                                    {device.serialNumber}
                                                  </span>
                                                </div>
                                                <div className="device-model">
                                                  Model: {device.model}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }) 
                                   :
                                    <div className="col-lg-12 d-flex align-items-center  justify-content-center">
                                      <Spin spinning={ !this.state.paybliEnable && this.state.isTerminalLoader} indicator={antIcon} size='large'/>
                                    </div>
                                  }
                                  {!paymentProgress && this.state.paybliEnable && (this.state.selectedSplitPaymentMethods.includes("terminal") && !this.state.isTerminalLoader) 
                                    ? devices &&  devices.length > 0 &&
                                      devices.map((device) => {
                                        return (
                                          <div
                                            key={device.deviceId}
                                            className="col-md-6"
                                          >
                                            <div
                                              className={
                                                device.deviceId === selectedDevice
                                                  ? "device-card device-card-selected"
                                                  : "device-card"
                                              }
                                              onClick={() =>
                                                this.setState({
                                                  selectedDevice: device.deviceId,
                                                })
                                              }
                                            >
                                              <div className="device-nickname">
                                                Device Nickname: {device.deviceNickName}
                                              </div>
                                              <div className="device-details">
                                                <div className="device-serial-no">
                                                  <span className="serial-label">
                                                    Serial No:
                                                  </span>
                                                  <span className="serial-no">
                                                    {device.serialNumber}
                                                  </span>
                                                </div>
                                                <div className="device-model">
                                                  Model: {device.model}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }) 
                                    :
                                    <div className="col-lg-12 d-flex align-items-center  justify-content-center">
                                    <Spin spinning={  this.state.paybliEnable && this.state.isTerminalLoader} indicator={antIcon} size='large'/>
                                    </div>
                                  }
                                {
                                  this.props?.user?.company?.isSignaturePadIntegration && <>
                                    <SignaturePad orderId={order?._id} parentCallback={(data) => this.handleSignCallback(data)} />
                                    { this.state.signErrorMessage!=="" && this.state.driverSignature === "" && <div className="signature-error">{this.state.signErrorMessage}</div>}
                                  </>
                                }
                              {!order.isPaymentDone &&
                              this.state.selectedSplitPaymentMethods.includes("terminal") &&
                              (devices.length === 0 || enableTerminalButton) ? (
                                <div className="terminal__status">
                                  Terminal Status:{" "}
                                  <p
                                    className={
                                      enableTerminalButton
                                        ? "text-green"
                                        : "text-red"
                                    }
                                  >
                                    {connectionStatus}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                              {isChargeCardCustomer && (
                                <p className="text-center text-success">
                                  ${numberFormat(total)} successfully charged{" "}
                                  {_brand === ""
                                    ? ""
                                    : `on ${_brand} **** ${_last4}`}
                                </p>
                              )}
                              {isError && !isChargeCardCustomer && (
                                <p className="text-center text-red m-10">
                                  {errorMessage}
                                </p>
                              )}
                              {this.state.errorMessageoutobound && (
                                <div className="text-center text-red m-10">
                                  {this.state.errorMessageoutobound}
                                </div>
                              )}
                              {this.state.errorMessageProject && (
                                <div className="text-center text-red m-10">
                                  {this.state.errorMessageProject}
                                </div>
                              )}
                                  {(!order.completed && !order.isPaymentLater) &&
                                    <button
                                    className="btn btn-yellow btn-lg w-100 mb-3 " 
                                    disabled={ this.state.inProgressForCompleteOrder || this.state.isChargeTerminalProcess || this.state.isChargeCardProcessing || this.state.isChargeCardCustomer || this.state.errorMessageoutobound}
                                    onClick={() => {
                                    this.setState({
                                      isPaymentLater: true, isPaymentDone: false, completed: false
                                      }, () => {
                                        additionalItems.map((v)=>{
                                          values[v.name]=v.quantity
                                          return null;
                                        })
                                        setValues({...values,submitType:"submit"});
                                        handleSubmit();
                                      })
                                    }}>Collect Payment Later</button>
                                  }
                                   {order && !order.completed && (user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.completeOrder === true) ? (
                                    <button 
                                    className="btn btn-dark btn-lg w-100" 
                                    disabled={this.state.inProgressForCompleteOrder || this.state.isChargeTerminalProcess || this.state.isChargeCardProcessing || this.state.withOutSplitPaymentValidation || this.state.errorMessageoutobound}
                                    type="button" onClick={() => {
                                    this.setState({
                                      isPaymentLater: false, isPaymentDone: true, completed: true
                                      }, () => {
                                      additionalItems.map((v)=>{
                                        values[v.name]=v.quantity
                                        return null;
                                      })
                                      setValues({...values,submitType:"submit"});
                                      handleSubmit();
                                    })
                                    }}> {!this.state.inProgressForCompleteOrder ? "Complete Order" : "Order Processing ..."}</button>)
                                  : null }
                                  </div>
                                </div>
                              </div>
                              :
                              <>
                              {
                              // _.get(order, "customer.paymentTerms", "") !==  "account" &&
                               _.get(selectedCustomer, "paymentTerms", "") !== "account" &&
                                <div className="col-lg-12">
                                  <div className="payment-terms-wrapper mt-4">
                                    <div className="payment-terms-list-container">
                                      <ul className="payment-terms-list">
                                        {(this.state.totalcreditmemosAmount > 0) && (
                                          <div className="row payment-terms-list-items align-items-start">
                                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                              <div className="d-flex align-items-center w-100">
                                                <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                                  <input
                                                    type="checkbox"
                                                    className="custom-checkbox--input"
                                                    checked={this.state.selectedSplitPaymentMethods.includes("credit-memo")}
                                                    onChange={() => { this.handleSelectPaymentMethod("credit-memo", setSubmitting) }}
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                                <div ref={this.creditMemoRef} className="custom__checkbox-select w-100 mb-0">
                                                  <div   className={`form-group material-textfield ${ !this.state.selectedSplitPaymentMethods.includes("credit-memo") ? "disabled" : ""}`}>
                                                    <div onClick={() => { this.setState({ creditMemoEnabled: !this.state.creditMemoEnabled }) }} className="form-control custom-select w-150 material-textfield-input pr-22">
                                                      Select Credit Memo
                                                    </div>
                                                    <label className="material-textfield-label">
                                                      Credit Memo
                                                    </label>
                                                  </div>
                                                  {this.state.selectedSplitPaymentMethods.includes("credit-memo") &&
                                                    <>
                                                      <span className="btn btn-link custome-selct-add-new-card-btn">Available Credit:{numberFormat(Number((this.state.totalcreditmemosAmount).toFixed(2)))}</span>
                                                      <ul className={`common__check-dropdown-list ${this.state.creditMemoEnabled ? "close-dropdown" : ""}`}>
                                                        {customerCreditsMemos && customerCreditsMemos.length > 0 && customerCreditsMemos.map((creditMemo, index) =>
                                                          <>
                                                            <li className="common__check-dropdown-list-items">
                                                              <span className="d-flex -align-items-center filter__check">
                                                                <label className="custom-checkbox custom-checkbox-line">
                                                                  {creditMemo.creditMemoNumber} {`[$${numberFormat(Number(Number(creditMemo.receiveCreditAmount).toFixed(2)))}]`}
                                                                  <input
                                                                    key={index}
                                                                    type="checkbox"
                                                                    className="custom-checkbox--input"
                                                                    onClick={() => { this.selectCreditMemo(creditMemo.creditMemoNumber) }}
                                                                  />
                                                                  <span className="checkmark"></span>
                                                                </label>
                                                              </span>
                                                            </li>
                                                          </>
                                                        )}
                                                      </ul>
                                                    </>}
                                                </div>

                                              </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                              <div className="form-group material-textfield mb-0 w-100">
                                                <NumberFormat
                                                  allowEmptyFormatting={true}
                                                  prefix={'$'}
                                                  thousandSeparator={true}
                                                  disabled={!this.state.selectedSplitPaymentMethods.includes("credit-memo")}
                                                  displayType={'input'}
                                                  type='text'
                                                  decimalScale={2}
                                                  value={this.state.splitPayments[3].paymentAmount === null ? "" : this.state.splitPayments[3].paymentAmount}
                                                  className='form-control material-textfield-input'
                                                  onValueChange={(_values) => {
                                                    const { value } = _values;
                                                    this.handleSelectPaymentAmount("credit-memo", value, stripeFeeOnTotalActualAmount.total)
                                                  }}
                                                  // name='dumpRates.pricePerTonLight'
                                                  required
                                                />
                                                <label className="material-textfield-label">
                                                  Enter Amount
                                                </label>
                                                {this.state.selectedSplitPaymentMethods.includes("credit-memo") &&
                                                  <span className="btn btn-link custome-selct-add-new-card-btn-relative"> Remaining Credit Amount :${this.state.remainingCreditAmount === null ? 0 : numberFormat(this.state.remainingCreditAmount)}</span>
                                                }
                                              </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                              &nbsp;
                                            </div>
                                          </div>)}
                                        <div className="row payment-terms-list-items align-items-start">
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <div className="d-flex w-100 align-items-center">
                                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                                <input
                                                  type="checkbox"
                                                  className="custom-checkbox--input"
                                                  checked={this.state.selectedSplitPaymentMethods.includes("credit-card")}
                                                  onChange={() => { this.handleSelectPaymentMethod("credit-card", setSubmitting) }}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                              <div className="form-group material-textfield w-100 mb-0">
                                                {this.state.paybliEnable ? (
                                                  <select
                                                    className="form-control custom-select w-150 material-textfield-input pr-22-"
                                                    required
                                                    disabled={!this.state.selectedSplitPaymentMethods.includes("credit-card")}
                                                    onChange={(e) => {
                                                      const paymentMethod = e.target.value.split("/");
                                                      this.setState({
                                                        cardId: paymentMethod[0], payMethod: paymentMethod[1], selectedCardData: paymentMethod[0]
                                                      });
                                                    }}
                                                  // this.setState({selectedCardData:e.target.value})}}
                                                  >
                                                    <option value={""} key={""}> Select Credit Card</option>
                                                    {this.state.customerPayabliData?.StoredMethods.length > 0 &&
                                                      this.state.customerPayabliData?.StoredMethods.map(
                                                        (item) => {
                                                          return (
                                                            <option value={item.IdPmethod + "/payabli"} key={item.IdPmethod} >
                                                              {item.Descriptor}{" "} {item.MaskedAccount}&nbsp;{this.insertSlash(item.ExpDate)}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                ) : (
                                                  <select
                                                    className="form-control custom-select w-150 material-textfield-input- pr-22 mb-3"
                                                    required
                                                    disabled={  this.state.isChargeCardProcessing || !this.state.selectedSplitPaymentMethods.includes("credit-card")}
                                                    onChange={(e) => {
                                                      const paymentMethod = e.target.value.split("/");
                                                      this.setState({
                                                        cardId: paymentMethod[0],
                                                        payMethod: paymentMethod[1],
                                                        selectedCardData: paymentMethod[0],
                                                      });
                                                    }}
                                                  >
                                                    <option value={""} key={""}> Select Credit Card  </option>
                                                    {/* {_.get(order, "customer.paymentmethods", []).map((payment_method_details) => {
                                                      const { _id = "", details = {}, } = payment_method_details;
                                                      return (<option value={_id + "/payengine"} key={_id} > {details.brand} **** {details.last4digits} </option>);
                                                    }
                                                    )} */}
                                                        {this.state.customerCreditCards.map(payment_method_details => {
                                                          const { _id = "", details = {} } = payment_method_details;
                                                          return (
                                                            <option value={_id + "/payengine"} key={_id}>
                                                              {details?.brand} **** {details?.last4digits}
                                                            </option>
                                                          );
                                                        })}
                                                  </select>
                                                )
                                                }
                                                {/* <label className="material-textfield-label">
                                              Select Credit Card 
                                              </label> */}
                                                <span
                                                  disabled={!this.state.selectedSplitPaymentMethods.includes("credit-card")}
                                                  onClick={() => {
                                                    if (!this.state.paybliEnable) {
                                                      this.setState({ isCardModalOpen: true, amount: total }, () => { this.forceUpdate() })
                                                    } else {
                                                      this.setState({ isCardModalOpen: true, amount: total })
                                                    }
                                                  }}
                                                  className="btn btn-link add-new-card-btn">
                                                  Add New Card
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <div className="form-group material-textfield mb-0 w-100">
                                              <NumberFormat
                                                allowEmptyFormatting={true}
                                                prefix={'$'}
                                                thousandSeparator={true}
                                                disabled={ this.state.isChargeCardProcessing || !this.state.selectedSplitPaymentMethods.includes("credit-card")}
                                                displayType={'input'}
                                                type='text'
                                                decimalScale={2}
                                                value={this.state.splitPayments[0].paymentAmount === null ? "" : this.state.splitPayments[0].paymentAmount}
                                                className='form-control material-textfield-input'
                                                onValueChange={(_values) => {
                                                  const { value } = _values;
                                                  this.handleSelectPaymentAmount("credit-card", value, stripeFeeOnTotalActualAmount.total)
                                                }}
                                                // name='dumpRates.pricePerTonLight'
                                                required
                                              />
                                              <label className="material-textfield-label">
                                                Enter Amount
                                              </label>
                                              {(this.state.selectedSplitPaymentMethods.includes("credit-card") && _.get(user, "company.chargeStripeCardFees", false)) &&
                                                <div className="handing-fee-lable mt-2"><span>Handing Fee</span> : ${numberFormat(_.get(creditCard_Fee, "fee", false) ? creditCard_Fee?.fee : Number(0.00))}</div>}
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                            <button
                                              name="card"
                                              type="button"
                                              disabled={(isChargeCard || !this.state.selectedSplitPaymentMethods.includes("credit-card"))}
                                              onClick={() => {
                                                this.setAsyncState({
                                                  initialValues: {
                                                    ...this.state.initialValues,
                                                    isPaymentLater: false, isPaymentDone: true, completed: true
                                                  },
                                                })
                                                setValues({
                                                  ...values,
                                                  submitType: "card",
                                                });
                                                handleSubmit();
                                              }}
                                              className="btn btn-yellow charge-card-btn">{this.state.isChargeCardProcessing ? "Processing Payment ..." :"Charge Card"} </button>
                                          </div>
                                        </div>
                                        <div className="row payment-terms-list-items">
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                              <input
                                                type="checkbox"
                                                checked={this.state.selectedSplitPaymentMethods.includes("cash")}
                                                className="custom-checkbox--input"
                                                onChange={() => { this.handleSelectPaymentMethod("cash", setSubmitting) }}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <div>
                                              <h3 className="payment-title">Cash</h3>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <div className="form-group material-textfield w-100 mb-0">
                                              <NumberFormat
                                                allowEmptyFormatting={true}
                                                prefix={'$'}
                                                thousandSeparator={true}
                                                disabled={!this.state.selectedSplitPaymentMethods.includes("cash")}
                                                displayType={'input'}
                                                type='text'
                                                decimalScale={2}
                                                value={this.state.splitPayments[1].paymentAmount === null ? "" : this.state.splitPayments[1].paymentAmount}
                                                className='form-control material-textfield-input'
                                                onValueChange={(_values) => {
                                                  const { value } = _values;
                                                  this.handleSelectPaymentAmount("cash", value, stripeFeeOnTotalActualAmount.total)
                                                }}
                                                // name='dumpRates.pricePerTonLight'
                                                required
                                              />
                                              <label className="material-textfield-label">
                                                Enter Amount
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items mobile-none">
                                            &nbsp;
                                          </div>
                                        </div>
                                        <div className="row payment-terms-list-items">
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                              <input
                                                type="checkbox"
                                                checked={this.state.selectedSplitPaymentMethods.includes("check")}
                                                className="custom-checkbox--input"
                                                onChange={() => { this.handleSelectPaymentMethod("check", setSubmitting) }}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <div>
                                              <h3 className="payment-title">Check</h3>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <div className="form-group material-textfield w-100 mb-0">
                                              <NumberFormat
                                                allowEmptyFormatting={true}
                                                prefix={'$'}
                                                thousandSeparator={true}
                                                disabled={!this.state.selectedSplitPaymentMethods.includes("check")}
                                                displayType={'input'}
                                                type='text'
                                                decimalScale={2}
                                                value={this.state.splitPayments[2].paymentAmount === null ? "" : this.state.splitPayments[2].paymentAmount}
                                                className='form-control material-textfield-input'
                                                onValueChange={(_values) => {
                                                  const { value } = _values;
                                                  this.handleSelectPaymentAmount("check", value, stripeFeeOnTotalActualAmount.total)
                                                }}
                                                // name='dumpRates.pricePerTonLight'
                                                required
                                              />
                                              <label className="material-textfield-label">
                                                Enter Amount
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <div className="material-textfield w-100">
                                               <input
                                                 disabled={!this.state.selectedSplitPaymentMethods.includes("check")}
                                                 value={this.state.checkNumber}
                                                className="form-control number-form-control"
                                                onChange={(event)=>{this.setState({checkNumber:event.target.value})}}
                                                required
                                              />
                                              <label className="material-textfield-label">
                                                Check Number<span>*</span>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row payment-terms-list-items">
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                              <input
                                                type="checkbox"
                                                disabled={(paymentProgress || !enableTerminalButton)}
                                                className="custom-checkbox--input"
                                                checked={this.state.selectedSplitPaymentMethods.includes("terminal")}
                                                onChange={() => { this.handleSelectPaymentMethod("terminal", setSubmitting) }}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <div>
                                              <h3 className="payment-title mb-1">Terminal</h3>
                                              <p className="terminal-status">Terminal Status: <span className={devices.length > 0 || enableTerminalButton ? "text-green" : "text-red"}>{connectionStatus ? connectionStatus : "Disconnected"}</span></p>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            <div className="form-group material-textfield w-100 mb-0">
                                              <NumberFormat
                                                allowEmptyFormatting={true}
                                                prefix={'$'}
                                                thousandSeparator={true}
                                                disabled={ (paymentProgress || !enableTerminalButton) || !this.state.selectedSplitPaymentMethods.includes("terminal")}
                                                displayType={'input'}
                                                type='text'
                                                decimalScale={2}
                                                value={this.state.splitPayments[4].paymentAmount === null ? "" : this.state.splitPayments[4].paymentAmount}
                                                className='form-control material-textfield-input mb-3'
                                                onValueChange={(_values) => {
                                                  const { value } = _values;
                                                  this.handleSelectPaymentAmount("terminal", value, stripeFeeOnTotalActualAmount.total)
                                                }}
                                                // name='dumpRates.pricePerTonLight'
                                                required
                                              />
                                              <label className="material-textfield-label">
                                                Enter Amount
                                              </label>
                                              {(this.state.selectedSplitPaymentMethods.includes("terminal") && _.get(user, "company.chargeStripeCardFees", false) )&&
                                                <div className="handing-fee-lable"><span>Handing Fee</span> : ${numberFormat(_.get(terminal_fee, "fee", false) ? terminal_fee.fee : Number(0.00))}</div>
                                              }
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                            <button
                                              className="btn btn-yellow charge-card-btn"
                                              disabled={(paymentProgress || !enableTerminalButton) || !this.state.selectedSplitPaymentMethods.includes("terminal")}
                                              onClick={() => {
                                                this.setAsyncState({
                                                  initialValues: {
                                                    ...this.state.initialValues,
                                                    isPaymentLater: false, isPaymentDone: true, completed: true
                                                  },
                                                })
                                                setValues({
                                                  ...values,
                                                  submitType: "terminal",
                                                });
                                                handleSubmit();
                                              }}> {this.state.isChargeTerminalProcess ?"Processing Payment ..." :"Charge On Terminal"}</button>
                                          </div>
                                        </div>
                                        {this.state.selectedSplitPaymentMethods.includes("terminal") &&
                                          <div className="row">
                                            {!paymentProgress &&
                                              !this.state.paybliEnable &&
                                              devices && devices.length > 0 && devices.map((device) => {
                                                return (
                                                  <div key={device.deviceId} className="col-md-6" >
                                                    <div
                                                      className={device.deviceId === selectedDevice ? "device-card device-card-selected" : "device-card"}
                                                      onClick={() => this.setState({ selectedDevice: device.deviceId })}
                                                    >
                                                      <div className="device-nickname">
                                                        Device Nickname: {device.deviceNickname}
                                                      </div>
                                                      <div className="device-details">
                                                        <div className="device-serial-no">
                                                          <span className="serial-label">
                                                            Serial No:
                                                          </span>
                                                          <span className="serial-no">
                                                            {device.serialNumber}
                                                          </span>
                                                        </div>
                                                        <div className="device-model">
                                                          Model: {device.model}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })}

                                            {!paymentProgress &&
                                              this.state.paybliEnable &&
                                              devices &&
                                              devices.length > 0 &&
                                              devices.map((device) => {
                                                return (
                                                  <div className="col-md-6" key={device.deviceId} >
                                                    <div
                                                      className={device.deviceId === selectedDevice ? "device-card device-card-selected" : "device-card"}
                                                      onClick={() => this.setState({ selectedDevice: device.deviceId, })}
                                                    >
                                                      <div className="device-nickname">
                                                        Device Nickname: {device.deviceNickName}
                                                      </div>
                                                      <div className="device-details">
                                                        <div className="device-serial-no">
                                                          <span className="serial-label">
                                                            Serial No:
                                                          </span>
                                                          <span className="serial-no">
                                                            {device.serialNumber}
                                                          </span>
                                                        </div>
                                                        <div className="device-model">
                                                          Model: {device.model}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        }
                                        <div className="row payment-terms-list-items">
                                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items">
                                            &nbsp;
                                          </div>
                                          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items total-amount-title">
                                            
                                            {Number(stripeFeeOnTotal.amount) > 0 &&
                                              <h3 className="amount-title"> Order Amount: ${numberFormat(stripeFeeOnTotal.amount)}</h3>
                                            }
                                            <h3 className="amount-title"> Total Order Amount : {(
                                              <span>${numberFormat(stripeFeeOnTotal.total)}</span>
                                            )}
                                            </h3>
                                            <h3 className="amount-title"> Amount Paid: ${this.state.availableOrderAmount !== null ? numberFormat(this.state.availableOrderAmount) : 0}</h3>
                                            {( _.get(user, "company.chargeStripeCardFees", false) )&&
                                                <h3 className="amount-title"><span>Handing Fee: ${numberFormat(this.state.splitPaymentTotalHandlingFee)}</span></h3>
                                              }
                                          <h3 className="amount-title"> Amount Left to Charge: ${this.state.remaingSplitAmount}</h3>
                                            {(  !this.state.isChargeCardProcessing && (isChargeCardCustomer || responseMessageForTerminal !=="")) && (
                                              <p className="amount-title  text-success mt-2">Successfully charged {_brand === "" ? "" : `on ${_brand} **** ${_last4}`} </p>)}
                                              {(this.state.isChargeTerminalProcess || this.state.isChargeCardProcessing) && (
                                              <p className="amount-title  text-success mt-2">Please wait while the transaction is being processed.</p>)}
                                            <p className="text-center text-red m-10">
                                            {responseMessageForTerminal !== "" && (
                                             <>
                                            {responseMessageForTerminal}
                                             </>
                                             )}
                                           </p>
                                          { !this.state.isChargeCardProcessing && isError && (
                                              <p className="amount-title text-red m-10">
                                                {errorMessage}
                                              </p>
                                            )}
                                          {/* {this.state.remaingSplitAmount === null ? stripeFeeOnTotal.amount && stripeFeeOnTotal.amount > 0 ? numberFormat(stripeFeeOnTotal.amount) : numberFormat(stripeFeeOnTotal.total) : numberFormat(this.state.remaingSplitAmount === null ? 0.00 : this.state.remaingSplitAmount)} */}
                                          </div>
                                          <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 payment-terms-list-inner-items mobile-none">
                                            &nbsp;
                                          </div>
                                        </div>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              }
                              <div className="col-md-12">
                              {
                              this.props?.user?.company?.isSignaturePadIntegration && <>
                                <SignaturePad orderId={order?._id} parentCallback={(data) => this.handleSignCallback(data)} />
                                { this.state.signErrorMessage!=="" && this.state.driverSignature === "" && <div className="signature-error">{this.state.signErrorMessage}</div>}
                              </>
                             }
                                {(!order.completed && !order.isPaymentLater) &&
                                  <>
                                    <button
                                      type="button"
                                      // className="green"
                                      onClick={() => {
                                        this.setState({
                                          isPaymentLater: true,
                                          initialValues: {
                                            ...this.state.initialValues,
                                            //  yardage: parseInt(this.state.yardage),
                                            isPaymentLater: true, isPaymentDone: false, completed: false
                                          },
                                        }, () => {
                                          additionalItems.map((v) => {
                                            values[v.name] = v.quantity
                                            return null;
                                          })
                                          setValues({ ...values, type: "submit" });
                                          handleSubmit();
                                        })
                                      }}
                                      disabled={this.state.isChargeTerminalProcess || this.state.isChargeCardProcessing}
                                      className="btn btn-yellow btn-lg w-100"
                                    >
                                      Collect Payment Later
                                    </button>
                                    <br />
                                    <br />
                                  </>
                                 }
                                {order && !order.completed ? (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      this.setState({
                                        isPaymentLater: false, isPaymentDone: true, completed: true
                                      }, () => {
                                        additionalItems.map((v) => {
                                          values[v.name] = v.quantity
                                          return null;
                                        })
                                        setValues({ ...values, submitType: "submit" });
                                        handleSubmit();
                                      })
                                    }}
                                    disabled={this.state.inProgressForCompleteOrder || this.state.isChargeTerminalProcess || this.state.isChargeCardProcessing}
                                    // disabled={
                                    //   !isTerminalPaymentDone ? ((
                                    //     selectPaymentMethod === "credit-card" && !order.isPaymentDone &&
                                    //     (!isChargeCardCustomer || this.state.cardId === "")
                                    //   ) ||
                                    //     (
                                    //       selectPaymentMethod === "terminal" && !order.isPaymentDone &&
                                    //       (devices.length !== 0 || !enableTerminalButton)
                                    //     ) ||
                                    //     isSubmitting.completeOrder || this.state.submittingForm) : false
                                    // }
                                    className="btn btn-dark btn-lg w-100"
                                  >
                                    {!this.state.inProgressForCompleteOrder ? buttonText : "Order processing..."}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                              </> }
                              
                            </div>
                          </div>
                        </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                      <h5 className="card-title">Order Log</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="card__scale-list ordercard__list">
                        {orderLogs.map((olog) => {
                          return (
                            <li key={olog._id}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `${olog.log}`,
                                }}
                              ></span>
                              <span>{`${moment(olog.createdAt)?.tz(this.state?.tz)?.format(
                                "MM/DD/YY"
                              )} ${moment(olog.createdAt)?.tz(this.state?.tz)?.format(
                                "hh:mm a"
                              )}`}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </Spin>
        { this.state.isCityOfOriginModalOpen &&
          <CityOfOrigin
            companyId={this.state.user?.companyId}
            cities={this.state.citiesOfOrigin}
            isOpen={this.state.isCityOfOriginModalOpen}
            assignCityOfOrigin={(cityOfOrigin) => this.setState({cityOfOrigin})}
            closeModal={(cityOfOrigin) => this.setState({
              isCityOfOriginModalOpen: !this.state.isCityOfOriginModalOpen
            })}
            {...this.props}
          />
        }
        {isCardModalOpen && this.state.paybliEnable ? (
          <AddCardComponent
            paybliDetails={this.state.paybliDetails}
            customerData={this.state.customerData}
            serviceLocation={this.state.serviceLocation}
            customerRefrence={this.state.customerRefrence}
            assignCardData={this.assignCardData}
          />
        ) : isCardModalOpen ?
          <>
            (
            <PayEngineCardModal
              addCard={addCard}
              isCardModalOpen={isCardModalOpen}
              closeModal={() => this.setState({ isCardModalOpen: false })}
              fetchOrder={()=> this.fetchCustomerCreditCards(this.state.selectedCustomer._id)}

              customerId={order.customerId}
              serviceLocation={this.state.serviceLocation}
              customerRefrence={this.state.customerRefrence}
              fetchCustomerDetail={() =>
                console.log("card added success fully")
                //  this.fetchCustomerDetail(order.customer.customerId)
                }

            />
            ) </> : null}
        <LightBox
          isOpen={this.state.isLightBoxOpen}
          images={this.state.images}
          onClose={() => this.setState({ isLightBoxOpen: false })}
          className="adadad"
        />
        <DeleteOrderModal
          isDeleteModal={this.state.isDeleteModal}
          closeModal={() => {
            this.setState({ isDeleteModal: false })
          }}
          deleteOrder={this.deleteOrder}
          progressBar={progressBar}
          is_paid_order_message={order?.completed}
        />
        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className={"select__driver-drawer creat__order-drawer" + (this.state?.is_een_lplates_drawer ? " upload-paper-ticket-slide-out-drawer" : "")}
        >
          {isCompany ? (
            <div className="creat__order-drawer-inner">
              <div className="head-section-header d-flex align-items-center justify-content-between">
                <div className="head-section-title">Select Company</div>
                <div className="head-section-cta">
                  <div onClick={this.onClose} className="btn btn-link btn-close pr-0">
                    <CloseIcon />
                  </div>
                </div>
              </div>
              <div className="form-group material-textfield">
                <DebounceInput
                  type="text"
                  className="form-control"
                  onChange={this.handleCompanySearch}
                  value={this.state.companySearchText}
                  debounceTimeout={1000}
                  required
                />
                <label className="material-textfield-label">Search Company</label>
              </div>
              <ul className="select_driver-list box-shadow">
                {customers?.map(customer => {
                  return (
                    <li
                      className={
                        String(selectedCustomer && selectedCustomer._id) === String(customer._id) ? "active" : ""
                      }
                      key={customer._id}
                    >
                      <div className=" filter_list-inner d-flex align-items-center">
                        <p className="driver__name">{_.get(customer, "displayName", "")}</p>
                      </div>
                      <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                        <input
                          type="checkbox"
                          className="custom-checkbox--input"
                          onChange={this.selectCustomer.bind(this, customer)}
                          checked={
                            String(selectedCustomer && selectedCustomer._id) === String(customer._id) ? true : false
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  );
                })}
              </ul>
              <div className="mt-20 mb-20">
                {customers?.length > 0 ? (
                  <button
                    className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                    onClick={this.assignCustomer.bind(this)}
                  >
                    Assign Company
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {isDriver && (
            <div className="creat__order-drawer-inner">
              {
                <>
                  <div className="head-section-header d-flex align-items-center justify-content-between">
                    <div className="head-section-title">Select Driver</div>
                    <div className="head-section-cta">
                      {customer && (
                        <button
                          className="btn btn-dark btn-sm mr-3 smbtn"
                          onClick={() =>
                            this.setState({ isLicenseCamera: true, createDriverSidebar: true, isDriver: false })
                          }
                        >
                          Create Driver
                        </button>
                      )}
                      <div onClick={this.onClose} className="btn btn-link btn-close pr-0">
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control material-textfield-input"
                      onChange={this.handleDriverSearch}
                      value={this.state.driverSearchText}
                      required
                    />
                    <label className="material-textfield-label">Search Driver</label>
                  </div>
                  <ul className="select_driver-list box-shadow">
                   {this.state.driversLoader ? 
                      <Spin className="select_driver-list-spiner" indicator={antIcon} spinning={this.state.driversLoader}></Spin>
                      :<>
                    {drivers.map(d => {
                      return (
                        <li
                          className={String(selectedDriver && selectedDriver._id) === String(d._id) ? "active" : ""}
                          key={d._id}
                        >
                          <div className="filter_list-inner d-flex align-items-center">
                            <div className="thumb-preview">
                              <img
                                style={{ transform: `rotate(${d.rotation}deg)` }}
                                src={d.licenseImageUrl}
                                alt=""
                              />
                            </div>
                            <p className="driver__name">
                              {d.firstName} {d.lastName}
                            </p>
                          </div>
                          <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                              onChange={this.selectDriver.bind(this, d)}
                              checked={
                                String(selectedDriver && selectedDriver._id) === String(d._id) ? true : false
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                     </>
                      }
                  </ul>
                  <div className="mt-20 mb-20">
                    {drivers.length > 0 ? (
                      <button
                        className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                        onClick={this.assignDriver.bind(this)}
                      >
                        Assign Driver
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              }
            </div>
          )}
          {this.state.isProjectDrawerOpen &&
            <div className="creat__order-drawer-inner">
              {!this.state.createProjectSideBar ?
                <>
                  <div className="head-section-header d-flex align-items-center justify-content-between">
                    <div className="head-section-title">Select Project</div>
                    <div className="head-section-cta">
                      {customer && (
                        <button
                          className="btn btn-dark btn-sm smbtn"
                          onClick={() =>
                            this.setState({ isLicenseCamera: false, createProjectSideBar: true, createDriverSidebar: false, isDriver: false })
                          }
                        >
                          Create Project
                        </button>
                      )}
                      <div onClick={this.onClose} className="btn btn-link btn-close pr-0">
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control material-textfield-input"
                      onChange={this.handleProjectSearch}
                      value={this.state.projectSearchText}
                      required
                    />
                    <label className="material-textfield-label">Search Project</label>
                  </div>
                  <ul className="select_driver-list box-shadow">
                     {this.state.projectSpinning ? 
                    <Spin  className="select_driver-list-spiner" indicator={antIcon} spinning={this.state.projectSpinning}></Spin>
                      : <>{this.state.projectsData?.length > 0 && this.state.projectsData.map(d => {
                        return (
                          <>
                            {d.projectStatus === "active" &&
                              <li
                                className={String(this.state.selectedProject && this.state.selectedProject._id) === String(d._id) ? "active" : ""}
                                key={d._id}
                              >
                                <div className="filter_list-inner d-flex align-items-center">
                                  <p className="driver__name">
                                    {d.projectName}
                                  </p>
                                </div>
                                <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox--input"
                                    onChange={this.selectedProject.bind(this, d)}
                                    checked={
                                      String(this.state.selectedProject && this.state.selectedProject._id) === String(d._id) ? true : false
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                            }
                          </>
                        );
                      })}
                   </>
                    }
                  </ul>
                  <div className="mt-20 mb-20">
                    {this.state.projectsData?.length > 0 ? (
                      <button
                        className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                        onClick={this.assignProjects.bind(this)}
                      >
                        Assign Project
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </>
                :
                <>
                  <CreateProject
                    customerData={this.state.customer}
                    createProject={this.props.createProject}
                    onClose={() =>
                      this.setState({
                        isCreateDrawerOpen: false,
                        isTruck: false,
                        isCompany: false,
                        isDriver: false,
                        createDriverSidebar: false,
                        createDriverForm: false,
                        rotation: 0,
                        licenseImageUrl: "",
                        createProjectSideBar: false,
                        isLicenseCamera: false
                      }, () => {
                        this.fetchProject()
                      })}
                    {...this.props}
                  />
                </>
              }
            </div>
          }
          {createDriverForm && (
            <Formik
              enableReinitialize
              initialValues={driverInitialValues}
              validationSchema={driverSchema}
              onSubmit={(values, formikProps) => this.submitDriverForm(values, formikProps)}
            >
              {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate style={{ display: false ? "none" : "" }}>
                  <CreateDriver
                    {...this.props}
                    isSubmitting={isSubmitting}
                    values={values}
                    handleChange={handleChange}
                    onTakePhoto={dataUri => this.onTakePhoto(dataUri, "licenseImageUrl", "isLicenseCamera")}
                    licenseImageUrl={this.state.licenseImageUrl}
                    isLicenseCamera={this.state.isLicenseCamera}
                    onClose={() =>
                      this.setState({
                        isCreateDrawerOpen: true,
                        isTruck: false,
                        isCompany: false,
                        isDriver: false,
                        createDriverSidebar: true,
                        createDriverForm: false,
                        rotation: 0,
                        licenseImageUrl: "",
                        isLicenseCamera: true
                      })
                    }
                    rotation={rotation}
                    setRotation={() => this.setState({ rotation: rotation + 45 })}
                    isForEdit={false}
                    onCrop={img => this.onCropImage(img)}
                  />
                </form>
              )}
            </Formik>
          )}
          {createDriverSidebar && (
            <NewDriver
              onClose={() =>
                this.setState({
                  isCreateDrawerOpen: true,
                  isTruck: false,
                  isCompany: false,
                  isDriver: true,
                  createDriverSidebar: false,
                  rotation: 0
                })
              }
              setInitialState={({ state }) => this.setInitialState({ state })}
              user={user}
              driverDetails={this.props.driverDetails}

            />
          )}
          {isTruck ? (
            <div className="creat__order-drawer-inner">
              <div className="head-section-header d-flex align-items-center justify-content-between">
                <div className="head-section-title">Select Truck for {customer && customer.displayName}</div>
                <div className="head-section-cta">
                  <div onClick={this.onClose} className="btn btn-link btn-close pr-0">
                    <CloseIcon />
                  </div>
                </div>
              </div>
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control material-textfield-input"
                  onChange={this.handleTruckSearch}
                  value={this.state.truckSearchText}
                  required
                />
                <label className="material-textfield-label">Select Truck</label>
              </div>
              <ul className="select_driver-list select__truck-list box-shadow">
                {trucks.map(t => {
                  return (
                    <li
                      className={String(selectedTruck && selectedTruck._id) === String(t._id) ? "active" : ""}
                      key={t._id}
                    >
                      <div className="d-flex flex-column">
                        <div className="driver__name">
                          <span>License plate number:</span>
                          {t?.plateNumber}
                        </div>
                        <div className="driver__name">
                          <span>Truck Number:</span>
                          {t.number}
                        </div>
                      </div>
                      <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                        <input
                          type="checkbox"
                          className="custom-checkbox--input"
                          onChange={this.selectTruck.bind(this, t)}
                          checked={String(selectedTruck && selectedTruck._id) === String(t._id) ? true : false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  );
                })}
              </ul>
              <div className="mt-20 mb-20">
                {trucks.length > 0 ? (
                  <button
                    className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn "
                    onClick={this.assignTruck.bind(this)}
                  >
                    Assign Truck
                  </button>
                ) : (
                  ""
                )}
              </div>
              <br />
            </div>
          ) : (
            ""
          )}
          {this.state?.is_een_lplates_drawer ? (
            <>
              <div className="head-section-header d-flex align-items-center justify-content-between">
                <div className="head-section-title">Select Truck</div>
                <div className="head-section-cta">
                  <div onClick={this.onClose} className="btn btn-link btn-close pr-0">
                    <CloseIcon />
                  </div>
                </div>
              </div>
              <div className="upload-paper-list">
                {this.state?.een_data?.map((een) => {
                  return <div className="upload-paper-list-items"
                    key={een?._id}
                    onClick={() => {
                      this.setState({ selected_een: { ...een?.data?.event, license_plate: een?.data?.event?.reading, plate_image_url: een?.data?.image_urls?.cloud_images?.[1] } })
                    }}
                  >
                    <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                      <input
                        type="checkbox"
                        className="custom-checkbox--input"

                        checked={selected_een?.license_plate === een?.data?.event?.reading}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <div class="grid-container ml-4">
                      <div class="grid-item">
                        <ul className="thumbnail-list">
                          <li className="thumbnail-list-items">
                            <h1 className="thumbnail-title">Thumbnail</h1>
                            <div className="thumbnail-img">
                              <img src={een?.data?.image_urls?.cloud_images?.[0]} alt="Thumbanail" />
                            </div>
                          </li>
                          <li className="thumbnail-list-items">
                            <h1 className="thumbnail-title">Make</h1>
                            <h2 className="thumbnail-sub-title">{een?.data?.event?.make}</h2>
                          </li>
                          <li className="thumbnail-list-items">
                            <h1 className="thumbnail-title">Color</h1>
                            <h2 className="thumbnail-sub-title">{een?.data?.event?.colour}</h2>
                          </li>
                        </ul>
                      </div>
                      <div class="grid-item">
                        <ul className="thumbnail-list">
                          <li className="thumbnail-list-items">
                            <h1 className="thumbnail-title">License Plate Photo</h1>
                            <div className="thumbnail-img">
                              <img src={een?.data?.image_urls?.cloud_images?.[1]} alt="License plate" />
                            </div>
                          </li>
                          <li className="thumbnail-list-items">
                            <h1 className="thumbnail-title">License Plate Number</h1>
                            <h2 className="thumbnail-sub-title">{een?.data?.event?.reading}</h2>
                          </li>
                        </ul>
                      </div>
                      <div class="grid-item">
                        <ul className="thumbnail-list">
                          <li className="thumbnail-list-items">
                            <h1 className="thumbnail-title mb-2">Timestamp</h1>
                            <h2 className="thumbnail-sub-title">{moment(een?.createdAt)?.format("MMM D, YYYY hh:mma z")}</h2>
                          </li>
                          <li className="thumbnail-list-items">
                            <h1 className="thumbnail-title">Company</h1>
                            <h2 className="thumbnail-sub-title">Not detected</h2>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                })
                }
                <div className="text-center" ref={(element) => (this.bottomElement = element)}>
                  <Spin indicator={antIcon} spinning={this.state?.een_loading}></Spin>
                </div>
                <br />
              </div>
              <div className="container mt-2">
                <div className="row">
                  <div className="col-lg-12">
                    {this.state?.een_data?.length > 0 ? (
                      <button
                        className="btn btn-dark w-100"
                        onClick={this.fetch_truck_by_license_plate}
                      >
                        Select
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </Drawer>

      </div>
    );
  }
}
